import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  Container,
  Paper,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import MainAnimation from "@components/MainAnimation";
import showToast from "@components/Toast";
import feedbackService from "../../services/feedbackService";
import { ThumbDownAlt, ThumbUpAlt } from "@material-ui/icons";
import TCLoading from "../TCLoading";

function FeedbacksList() {
  const [idx, setIdx] = useState(0);
  const [feedbacks, setFeedbacks] = useState([]);
  const [feedbacksUtil, setFeedbacksUtil] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPut, setLoadingPut] = useState(false);
  const [user, theme] = useSelector((states) => [
    states.authReducer.user,
    states.authReducer.theme,
  ]);

  useEffect(() => {
    if (idx === 0) return getFeedbacks();
    getFeedbacksUtils();
  }, [idx]);

  function getFeedbacks() {
    setLoading(true);
    feedbackService
      .getFeedbacks()
      .then((rFeedbacks) => {
        setFeedbacks(rFeedbacks);
      })
      .catch((e) => showToast.error(e.message))
      .finally(() => setLoading(false));
  }
  function getFeedbacksUtils() {
    setLoading(true);
    feedbackService
      .getFeedbacksUtils()
      .then((fbs) => setFeedbacksUtil(fbs))
      .catch((e) => showToast.error(e?.message))
      .finally(() => setLoading(false));
  }
  function handlePutFeedback(pFeedbackId, pUtil) {
    setLoadingPut(true);
    return feedbackService
      .putFeedback({ _id: pFeedbackId, util: pUtil })
      .then((_) => {
        let copyFeedbacks = [...feedbacks];
        copyFeedbacks = copyFeedbacks.filter((obj) => obj._id !== pFeedbackId);
        setFeedbacks(copyFeedbacks);
      })
      .catch((e) => showToast.error(e.message))
      .finally(() => setLoadingPut(false));
  }

  function getTitle() {
    if (idx === 0) {
      return `Feedbacks: (${feedbacks?.length || 0})`;
    }
    return `Feedbacks Interessantes: (${feedbacksUtil?.length || 0})`;
  }
  const fbSeeing = idx === 0 ? feedbacks : feedbacksUtil;
  if (user?.type !== "A") {
    return <h2>Not admin</h2>;
  }
  return (
    <MainAnimation>
      <Container maxWidth="md" style={{ paddingTop: 20, display: "flex" }}>
        <Paper
          style={{
            width: "100%",
            padding: 24,
            minHeight: 300,
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 300,
              }}
            >
              <TCLoading size={40} />
            </div>
          ) : (
            <>
              <div style={{ display: "flex", columnGap: 12 }}>
                <Button variant="outlined" onClick={() => setIdx(0)}>
                  Novos
                </Button>
                <Button variant="outlined" onClick={() => setIdx(1)}>
                  Uteis
                </Button>
              </div>
              <Typography variant="h4" gutterBottom style={{ marginTop: 12 }}>
                {getTitle()}
              </Typography>
              {fbSeeing?.length ? (
                fbSeeing?.map((rFeedback) => (
                  <FeedbackCard
                    key={rFeedback._id}
                    feedback={rFeedback}
                    theme={theme}
                    handlePutFeedback={handlePutFeedback}
                    loadingPut={loadingPut}
                    isUtil={idx}
                  />
                ))
              ) : (
                <h2 style={{ marginTop: 90, textAlign: "center" }}>
                  Sem feedbacks
                </h2>
              )}
            </>
          )}
        </Paper>
      </Container>
    </MainAnimation>
  );
}

function FeedbackCard({
  feedback,
  theme,
  handlePutFeedback,
  loadingPut,
  isUtil,
}) {
  return (
    <Card
      style={{
        margin: 8,
        marginTop: 30,
        paddingBottom: 12,
        padding: 10,
        backgroundColor: theme == "DARK" ? grey[700] : grey[300],
        position: "relative",
      }}
    >
      <Typography variant="h6">
        {feedback.tipo} - {feedback.usuario?.empresa?.nomeFantasia} -{" "}
        {feedback.usuario?.name} - {feedback.usuario?.empresa?.tipo}
      </Typography>
      <Typography variant="subtitle2">{feedback.texto}</Typography>
      <div
        style={{
          position: "absolute",
          top: 15,
          right: 5,
          minWidth: 80,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isUtil ? null : loadingPut ? (
          <TCLoading style={{ marginTop: 12 }} />
        ) : (
          <>
            <IconButton onClick={() => handlePutFeedback(feedback?._id, 1)}>
              <ThumbUpAlt />
            </IconButton>
            <IconButton onClick={() => handlePutFeedback(feedback?._id, 0)}>
              <ThumbDownAlt />
            </IconButton>
          </>
        )}
      </div>
    </Card>
  );
}

export default FeedbacksList;
