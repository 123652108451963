import { Chip, InputLabel, makeStyles, MenuItem, TextField, Typography } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import React, { useEffect, useState } from "react";

import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { LEFT_FILTERS } from "@utils/data/leftFiltersData";
import OBJETO_CAMPOS from "../../utils/data/formFields";
import { getFromArrayOrObject } from "../ModalCadastrarOferta";
import { GREEN } from "../../utils/themes";
import checkIfPremium from "@utils/checkIfPremium";
import { useDispatch, useSelector } from "react-redux";
import { setModalPLanosOpen } from "@redux/authReducer";
import { setFiltros, resetFiltros } from "@redux/ofertaReducer";
import ofertaService from "@services/ofertaService";
import { setOfertas } from "@redux/ofertaReducer";
import showToast from "@components/Toast";
import TCButton from "@components/TCButton";
import PercentageInput from "@components/PercentageInput";
import { BotaoCadastrar } from "../../pages/Feed";
import { Autocomplete } from "@material-ui/lab";
import MUNICIPIOS from "../../utils/data/municipios";

export const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    width: "100%",
    justifyContent: `space-between`,
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: 'none'
    },
  }
}));

export default function MultiFilters({ theme }) {
  let dispatch = useDispatch();
  const classes = useStyles();

  const [user, filtros] = useSelector((states) => [
    states.authReducer.user,
    states.ofertaReducer.filtros,
  ]);

  function onAdd(filtro) {
    dispatch(setFiltros({ ...filtros, ...filtro }));
  }
  function onDelete(key) {
    let filtrosCopy = { ...filtros };
    delete filtrosCopy[key];
    dispatch(setFiltros(filtrosCopy));
  }
  useEffect(() => {
    filtrar();
  }, [filtros]);

  function filtrar() {
    if (!checkIfPremium(user)) {
      if (Object.keys(filtros)?.length > 1) {
        dispatch(setModalPLanosOpen(true));
      }
      dispatch(resetFiltros());
      return;
    }

    const xFiltros = { ...filtros };

    // const xMin = parseFloat(min);
    // const xMax = parseFloat(max);

    // if (min !== "" && max !== "") {
    //   if (xMin > xMax || isNaN(xMin) || isNaN(xMax)) {
    //     return setError(true);
    //   }

    //   xFiltros.preco = buildRange(xMin, xMax);
    // }

    //Because we use the same screen to edit
    for (let key in xFiltros) {
      if (!xFiltros[key]) delete xFiltros[key];
    }
    // setError(false);
    ofertaService
      .getOfertas(xFiltros)
      .then((rOfertas) => {
        dispatch(setOfertas(rOfertas));
      })
      .catch((e) => {
        showToast.error("Erro filtrando ofertas");
        console.log(e);
      });
  }
  return (
    <div
      className={classes.mainDiv}

    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 8,
          paddingBottom: 4,
        }}
      >
        {Object.keys(filtros)?.map((filtro) => (
          //ugly as fk
          < Chip
            key={`${OBJETO_CAMPOS[filtro].display} : ${filtros[filtro]}`}
            label={`${OBJETO_CAMPOS[filtro].display} : ${filtro === `modo`
              ? filtros[filtro] === `C`
                ? `Compra`
                : `Venda`
              : filtros[filtro]
              } ${filtro === `catacao` ? `%` : ``}`}
            onDelete={filtro === `modo` ? undefined : () => onDelete(filtro)}
            onClick={
              filtro === `modo`
                ? () => onAdd({ modo: filtros[filtro] === `C` ? "V" : "C" })
                : undefined
            }
            style={
              filtro === `modo`
                ? {
                  background: filtros[filtro] === `C` ? GREEN : orange[800],
                  color: "white",
                }
                : undefined
            }
          />
        ))}
        <PopupState variant="popover" popupId="demo-popup-popover">
          {(popupState) => (
            <div>
              <TCButton
                variant="outlined"
                color={`outlined`}
                {...bindTrigger(popupState)}
              >
                + Novo Filtro
              </TCButton>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box p={2}>
                  <FiltroSelection
                    filtros={filtros}
                    onAdd={(v) => {
                      popupState.close();
                      onAdd(v);
                    }}
                  />
                </Box>
              </Popover>
            </div>
          )}
        </PopupState>
      </div>
      <div style={{ display: 'flex', gap: 8 }}>

        <TCButton
          variant="outlined"
          color="outlined"
          onClick={() => dispatch(resetFiltros())}
        >
          Limpar Filtro
        </TCButton>
        <BotaoCadastrar elevation={24} />
      </div>

    </div>
  );
}

const defaultProps = {
  options: MUNICIPIOS,
  getOptionLabel: (option) => option.Nome,
};

function FiltroSelection({ filtros, onAdd }) {
  let [selectedFiltroKey, setSelectedFiltroKey] = useState();
  let [val, setVal] = useState("");
  return (
    <div style={{ display: "flex" }}>
      <div style={{ borderRight: `1px solid grey` }}>
        {Object.keys(LEFT_FILTERS)?.map((f) => {
          return (
            <MenuItem
              onClick={() => setSelectedFiltroKey(f)}
              selected={selectedFiltroKey === f}
            >
              {OBJETO_CAMPOS[f].display}
            </MenuItem>
          );
        })}
      </div>
      {selectedFiltroKey === `catacao` ? (
        <div style={{ padding: 12 }}>
          <Typography variant="caption">Catação %</Typography>
          <PercentageInput
            autoFocus
            setValue={(k, val) => {
              if (val) {
                setVal(val);
              }
            }}
          />
          <TCButton
            disabled={!val}
            onClick={() => onAdd({ [selectedFiltroKey]: val })}
          >
            Filtrar
          </TCButton>
        </div>
      ) : selectedFiltroKey === 'mr' ? (
        <div style={{ padding: 12, minWidth: 250 }}>
          <Autocomplete
            {...defaultProps}
            openOnFocus
            //          value={{ Nome: filtros[key] || "" }}
            onChange={(e, data) => {
              return onAdd({ mr: data.Nome });
            }}
            renderInput={(params) => <TextField {...params} autoFocus
              placeholder='Escolha um Municipio'
            />}
          />
        </div>
      ) : selectedFiltroKey ? (
        <div>
          {getFromArrayOrObject(
            OBJETO_CAMPOS[selectedFiltroKey].valores,
            undefined,
            (valor) => {
              onAdd({ [selectedFiltroKey]: valor });
            }
          )}
        </div>
      ) : null}
    </div>
  );
}
