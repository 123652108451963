import React, { useState } from "react";
import { IconButton, Typography, withStyles } from "@material-ui/core";
import OBJETO_CAMPOS from "../utils/data/formFields";
import { grey } from "@material-ui/core/colors";
import empresaService from "@services/empresaService";
import { Message, Phone } from "@material-ui/icons";
import TCButton from "./TCButton";
import { useSelector } from "react-redux";

export const FlexWrapDiv = ({ children, justifyContent }) => (
  <div
    style={{
      display: "flex",
      flexWrap: "wrap",
      justifyContent: justifyContent || "space-between",
      columnGap: 8,
    }}
  >
    {children}
  </div>
);

export const FlexColumnDiv = ({ children }) => (
  <div style={{ display: "flex", flexDirection: "column" }}>{children}</div>
);

export function Line({ isLeft, isRight }) {
  return (
    <div
      style={{
        width: "100%",
        marginLeft: isRight && 8,
        marginRight: isLeft && 8,
        marginBottom: 8,
        marginTop: 8,
        height: 2,
        backgroundColor: "grey",
      }}
    />
  );
}

// export function Descricao({ oferta, cotacao }) {
//   return (
//     <Section title="Descricao">
//       <FlexWrapDiv>
//         <FlexColumnDiv>
//           <StyledFieldValue field={"especie"} value={oferta.especie} />

//           <StyledFieldValue field={"bebidaBase"} value={oferta.bebidaBase} />

//           <StyledFieldValue field={"aspecto"} value={oferta.aspecto} />

//           <StyledFieldValue field={"teorUmidade"} value={oferta.teorUmidade} />
//         </FlexColumnDiv>
//         <FlexColumnDiv>
//           <StyledFieldValue field={"anoSafra"} value={oferta.anoSafra} />

//           <StyledFieldValue
//             field={"catacao"}
//             value={oferta.catacao ? `${oferta.catacao}%` : ""}
//           />

//           <StyledFieldValue field={"cor"} value={"VERDE"} />

//           <StyledFieldValue
//             field={"certificacao"}
//             value={oferta.certificacao}
//           />
//         </FlexColumnDiv>
//         <FlexColumnDiv>
//           <StyledFieldValue field={"fava"} value={FAVA[oferta?.fava]?.detail} />

//           <StyledFieldValue field={"tipoCafe"} value={oferta.tipoCafe} />

//           <StyledFieldValue field={"padrao"} value={oferta.padrao} />

//           <StyledFieldValue
//             field={"quantidadeSacos"}
//             value={oferta.quantidadeSacos}
//           />
//         </FlexColumnDiv>
//         <FlexColumnDiv>
//           <StyledFieldValue
//             field={"processamento"}
//             value={oferta.processamento}
//           />

//           <StyledFieldValue field={"defeito"} value={oferta.defeito} />

//           <StyledFieldValue field={"pontosSCA"} value={oferta.pontosSCA} />

//           <StyledP text={`R$ ${showPreco(oferta, cotacao)}`} />

//           <StyledP text={`NYC 234.10/ U$ 4.9033`} />
//         </FlexColumnDiv>
//       </FlexWrapDiv>
//     </Section>
//   );
// }

// export function NoLote({ oferta }) {
//   return (
//     <FlexWrapDiv>
//       <StyledFieldValue field={"numeroLote"} value={oferta.numeroLote} />

//       <StyledFieldValue field={"mr"} value={oferta.mr} />
//     </FlexWrapDiv>
//   );
// }

// export function TipoOferta({ oferta }) {
//   return (
//     <FlexWrapDiv>
//       <StyledFieldValue
//         field={"Oferta"}
//         value={oferta.modo === "C" ? "COMPRA" : "VENDA"}
//       />
//       <StyledFieldValue
//         field={"Tipo Oferta: "}
//         value={oferta.tp === "F" ? "FIRME" : "NOMINAL"}
//       />
//       <StyledFieldValue field={"Publicado as: "} value={oferta.publishedAt} />
//     </FlexWrapDiv>
//   );
// }

// export function FirstLine({ oferta, cotacao }) {
//   return (
//     <div style={{ display: "flex", width: "100%" }}>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           columnGap: 16,
//           width: "100%",
//         }}
//       >
//         <StyledP field="safra">{oferta.safra}</StyledP>
//         <StyledP field="fava">{FAVA[oferta?.fava]?.detail}</StyledP>
//         <StyledP field="processamento">
//           {PROCESSAMENTO[oferta?.processamento]?.detail}
//         </StyledP>
//         {oferta.catacao && (
//           <StyledP field="catacao">{oferta.catacao}% Catacao</StyledP>
//         )}
//         {oferta.tipoCafe && (
//           <StyledP field="tipoCafe">{oferta.tipoCafe}</StyledP>
//         )}
//         <StyledP field="preco">R$ {showPreco(oferta, cotacao)}</StyledP>
//       </div>
//     </div>
//   );
// }

export function Section({ children, title }) {
  return (
    <div style={{ marginTop: 8 }}>
      <div style={{ display: "flex", alignItems: "center", marginBottom: 12 }}>
        <Line isLeft />
        <StyledP head noMargin>
          {title}
        </StyledP>
        <Line isRight />
      </div>
      {children}
    </div>
  );
}

export function InfoEmpresa({ ofertaId, empresa, handleFastChatOpen }) {
  let [membros, setMembros] = useState();
  let [loading, setLoading] = useState(false);
  const [user] = useSelector((states) => [states.authReducer.user]);
  if (!empresa?.isAgreedShareData) {
    return null;
  }

  return (
    <Section title="Empresa">
      <div style={{ display: "flex", gap: 8 }}>
        <div style={{ display: "flex", gap: 12 }}>
          <StyledFieldValue field={"Empresa"} value={empresa.nomeFantasia} />
          <StyledFieldValue
            field={"Usuario"}
            value={empresa.fakeUsuarioContato || empresa.usuario?.name}
          />
          <StyledFieldValue
            field={"Telefones"}
            value={empresa?.telefone1}
            value2={empresa?.telefone2}
          />
        </div>
        {!empresa?.fakeUsuarioContato && !membros ? (
          <TCButton
            size="small"
            loading={loading}
            onClick={() => {
              setLoading(true);
              empresaService
                .getMembrosOferta(empresa?._id)
                .then((rMembros) => setMembros(rMembros))
                .catch((e) => console.log("e"))
                .finally(() => setLoading(false));
            }}
            variant="outlined"
            color="outlined"
            style={{ height: 34, marginLeft: 36 }}
          >
            <Phone fontSize="small"></Phone>
            <span style={{ fontSize: 12, marginLeft: 12 }}> Contatos</span>
          </TCButton>
        ) : !membros ? null : (
          <div style={{ marginLeft: 48, display: "flex", gap: 24 }}>
            <div>
              <StyledP head>Contatos</StyledP>
              {membros?.map((rMembro) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 12,
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <StyledFieldValue
                    field={rMembro.name}
                    value={rMembro.telefone1}
                  />
                  {rMembro._id !== user._id ? (
                    <IconButton
                      onClick={() => {
                        handleFastChatOpen(ofertaId, rMembro);
                      }}
                    >
                      <Message fontSize="small" style={{ cursor: "pointer" }} />
                    </IconButton>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        )}

        <div style={{ display: "flex", gap: 8 }}>
          {empresa?.estado && (
            <StyledFieldValue field={"Estado"} value={empresa?.estado} />
          )}
          {empresa?.municipio && (
            <StyledFieldValue field={"MUNICIPIO"} value={empresa?.municipio} />
          )}
        </div>
      </div>
    </Section>
  );
}

export function FotoOferta({ oferta, setIsImgOpen }) {
  if (!oferta?.img) {
    return null;
  }
  return (
    <Section title="Foto">
      <div style={{ display: "flex", justifyContent: "center", marginTop: 12 }}>
        <img
          src={oferta?.img}
          alt="oferta img"
          onClick={() => setIsImgOpen(true)}
          width={60}
          height={60}
          style={{
            marginRight: 10,
            borderRadius: 4,
            cursor: "pointer",
          }}
        />
      </div>
    </Section>
  );
}

export let StyledP = withStyles((theme) => ({
  styledP: {
    fontSize: 12,
    color: (props) =>
      props.head
        ? theme.palette.type === "dark"
          ? "white"
          : "black"
        : theme.palette.type === "dark"
        ? grey[600]
        : grey[600],
  },
}))(StyledPx);
function StyledPx({ classes, children, head }) {
  return (
    <Typography className={classes.styledP} head={head}>
      {children}
    </Typography>
  );
}

export function StyledFieldValue({ field, value, value2 }) {
  if (!value) return null;
  return (
    <div>
      <StyledP head>{OBJETO_CAMPOS[field]?.display || field}</StyledP>
      <StyledP field={field}>{value}</StyledP>
      <StyledP>{value2}</StyledP>
    </div>
  );
}
