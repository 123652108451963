import api from "@services/api";

function getPlanos() {
  return api.get("/cobranca/planos");
}

function getQrCode(planoId) {
  return api.post("/cobranca", { planoId });
}

const paymentService = {
  getPlanos,
  getQrCode,
};

export default paymentService;
