import api from "@services/api";

function getCotacao() {
  return api.get("/cotacao");
}

function getVencimentos() {
  return api.get("/cotacao/vencimento");
}

const cotacaoService = { getCotacao, getVencimentos };

export default cotacaoService;
