import React from "react";
import { Button, IconButton, makeStyles, Modal, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Clear } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    width: 350,
  },
}));

export default function YesNoModal({
  handleClose,
  showModal,
  title,
  description,
  onYes,
}) {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition--famodal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal}>
        <div className={classes.paper}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant='body1'>{title}</Typography>
            <IconButton style={{ marginRight: -15 }} onClick={handleClose}>
              <Clear fontSize="medium" />
            </IconButton>
          </div>
          <Typography variant='subtitle2'>{description}</Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 30,
              marginLeft: 140,
            }}
          >
            <Button variant="outlined" onClick={onYes}>
              Sim
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Nao
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
