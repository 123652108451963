import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import forumService from "@services/forumService";
import { Typography, Paper, Divider } from "@material-ui/core";
import PostList from "./PostList";
import { setMyPosts } from "../../redux/forumReducer";

function MyForum({ }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [theme, user, myPosts] = useSelector((states) => [
    states.authReducer.theme,
    states.authReducer.user,
    states.forumReducer.myPosts,
  ]);

  useEffect(() => {
    getMyPosts();
  }, []);

  function getMyPosts(pQtdMyPosts) {
    setLoading(true);
    const xArray = !pQtdMyPosts ? [] : myPosts;
    forumService
      .getMyPosts(pQtdMyPosts || 0)
      .then((rPosts) => {
        dispatch(setMyPosts([...xArray, ...rPosts]));
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }

  return (
    <Paper
      style={{
        width: "100%",
        minHeight: 800,
        padding: 20,
        marginBottom: 80,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Meus Posts
      </Typography>
      <Divider />
      <PostList
        theme={theme}
        user={user}
        getPosts={getMyPosts}
        posts={myPosts}
        loading={loading}
      />
    </Paper>
  );
}

export default MyForum;
