import { configDotenv } from "dotenv";
import mixpanel, { people } from "mixpanel-browser";
configDotenv();
let mixpanelToken = process.env.REACT_APP_MIX_PANEL_TOKEN;

let mixPanelInstance = {
  ...mixpanel,
  track: (e, p, o, c) => {
    if (mixpanelToken) {
      return mixpanel.track(e, p, o, c);
    }
  },
  identify: (id) => {
    if (mixpanelToken) {
      return mixpanel.identify(id);
    }
  },
  register: (user) => {
    if (mixpanelToken) {
      return mixpanel.register(user);
    }
  },
  init: () => {
    if (mixpanelToken) {
      return mixpanel.init(mixpanelToken, {
        debug: true,
        track_pageview: true,
        persistence: "localStorage",
      });
    }
  },
  reset: () => {
    if (mixpanelToken) {
      mixpanel.reset();
    }
  },
  people: mixpanel.people,
};

export function mixpanelIdentify(pUser) {
  if (!pUser) return;

  let xUser = { ...pUser };
  mixPanelInstance.identify(xUser?._id);
  mixPanelInstance.register({
    empresa: xUser.empresa?.nomeFantasia,
    empresaId: xUser.empresa?._id,
    name: xUser.name,
  });
  delete xUser?.authToken;
  xUser.empresaId = xUser.empresa?._id;
  xUser.empresa = xUser.empresa?.nomeFantasia;

  xUser["$email"] = xUser.email;
  xUser["$name"] = xUser.name;
  mixPanelInstance?.people?.set && mixPanelInstance?.people?.set(xUser);
}

export default mixPanelInstance;
