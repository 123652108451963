export const FILTRO_CRIADO = "Filtro Criado";
export const LISTA_OFERTAS_COM_FILTROS = "Lista Ofertas Com Filtros";

export const CRIAR_OFERTA = "Criar Oferta";
export const OFERTA_CRIADA = "Oferta Criada";
export const OFERTA_DELETADA = "Oferta Deletada";
export const OFERTA_EDITADA = "Oferta Editada";
export const OFERTA_PUBLICADA = "Oferta Publicada";
export const OFERTA_REMOVIDA = "Oferta Removida";
export const OFERTA_VISUALIZADA = 'Oferta Vizualizada';
export const OFERTA_CONGELADA = 'Oferta Congelada';
export const OFERTA_DESCONGELADA = 'Oferta Descongelada';


export const DIVULGACAO_CRIADA = "Divulgação Criada";
export const DIVULGACAO_COPIADA = "Divulgação Copiada";

export const CALCULADORA_ABERTA = 'Calculadora Aberta'
export const CALCULADORA_SALVA = 'Calculadora Salva'

export const CONVITE_ENVIADO = 'Convite Enviado'
export const CONVITE_PARA_EMPRESA_ENVIADO = 'Convite Para Empresa Enviado'

export const INTERESSE_ENVIADO = "Interesse Enviado"
export const F_INTERESSE_CRIADO = "F_Interesse Enviado"

export const ERROR_BOUNDARY = 'Error Boundary'