import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Badge,
  Container,
  IconButton,
  makeStyles,
  Tooltip,
  Paper,
} from "@material-ui/core";
import { ForumOutlined, Person, StarBorder } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import { DARK } from "@utils/themes";
import MainForum from "@components/Forum/MainForum";
import FavoriteForum from "../components/Forum/FavoriteForum";
import MyForum from "../components/Forum/MyForum";
import MainAnimation from "../components/MainAnimation";

const useStyles = makeStyles((theme) => ({
  icons: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  container: {
    paddingTop: 20,
    display: "flex",
    width:'100%',
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));
function Forum() {
  const classes = useStyles();
  const [idx, setIdx] = useState(0);

  const [theme, user, waitingPosts] = useSelector((states) => [
    states.authReducer.theme,
    states.authReducer.user,
    states.forumReducer.waitingPosts,
  ]);

  // useEffect(() => {
  //   // if (user?._id) {
  //   console.log("FORUMM", user);
  //   const x = `${baseUrl}/post/sse?sessionid=${xUuid}`;

  //   let eventSource = new EventSource(x);

  //   eventSource.onmessage = (e) => {
  //     //Parsing JSON cause it comes it string format

  //     const xJSON = JSON.parse(e.data);
  //     if (xJSON.newPost && xJSON.newPost?.usuario?._id !== user._id) {
  //       dispatch(setWaitingPosts([xJSON.newPost, ...waitingPosts]));
  //     }
  //     if (xJSON.newReply && xJSON.newReply?.usuario?._id !== user._id) {
  //       //Add replies to the list if user already fetched replies before
  //       if (replies[xJSON.newReply.post]) {
  //         dispatch(addReply(xJSON.newReply.post, xJSON.newReply));
  //       }
  //       //Add +1 to the message count on the post card
  //       dispatch(handleAddReplyCount(xJSON.newReply.post, xJSON.newReply));
  //     }
  //   };
  //   return () => eventSource.close();
  //   // }
  // }, [user, replies, waitingPosts]);

  let iconStyle = (pIdx) => ({
    color:
      theme === DARK
        ? idx === pIdx
          ? "white"
          : grey[700]
        : idx === pIdx
        ? grey[700]
        : grey[400],
    fontSize: 35,
  });

  return (
    <MainAnimation>
      <Container maxWidth="md" className={classes.container}>
        <div
          style={{
            borderRadius: 4,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          {user?._id ? (
            <div className={classes.icons}>
              <IconButton onClick={() => setIdx(0)}>
                <Tooltip title="Forum" placement="bottom">
                  <Badge badgeContent={waitingPosts?.length} color="primary">
                    <ForumOutlined style={iconStyle(0)} />
                  </Badge>
                </Tooltip>
              </IconButton>
              {/* <IconButton onClick={() => setIdx(1)}>
                <Tooltip title="Amigos" placement="bottom">
                  <Group style={iconStyle(1)} />
                </Tooltip>
              </IconButton> */}
              <IconButton onClick={() => setIdx(2)}>
                <Tooltip title="Favoritos" placement="bottom">
                  <StarBorder style={iconStyle(2)} />
                </Tooltip>
              </IconButton>
              <IconButton onClick={() => setIdx(3)}>
                <Tooltip title="Meus" placement="bottom">
                  <Person style={iconStyle(3)} />
                </Tooltip>
              </IconButton>
            </div>
          ) : (
            <></>
          )}
        </div>
        <Paper elevation={24} style={{width:'100%'}} >
          {idx === 0 ? (
            <MainForum waitingPosts={waitingPosts} />
          ) : // idx === 1 ? (
          //   <FriendsForum />
          // ) :
          idx === 2 ? (
            <FavoriteForum />
          ) : (
            <MyForum />
          )}
        </Paper>
      </Container>
    </MainAnimation>
  );
}

export default Forum;
