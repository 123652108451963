import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  Container,
  Paper,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";
import {
  Delete,
  ThumbDownAlt,
  ThumbUpAlt,
  Business,
  Phone,
} from "@material-ui/icons";
import YesNoModal from "@components/YesNoModal";
import { grey } from "@material-ui/core/colors";
import MainAnimation from "@components/MainAnimation";
import AddFakeCompany from "@components/AdminPanel/AddFakeCompany";
import adminService from "@services/adminService";
import showToast from "@components/Toast";
import TCLoading from "../TCLoading";

function FakeCompanies() {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAgreeing, setLoadingAgreeing] = useState(false);
  const [loadingDeleting, setLoadingDeleting] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [user, theme] = useSelector((states) => [
    states.authReducer.user,
    states.authReducer.theme,
  ]);

  useEffect(() => {
    setLoading(true);
    adminService
      .getFakeCompanies()
      .then((rCompanies) => setCompanies(rCompanies))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, []);

  async function handleAgree(pYesNo, pCompanyId) {
    setLoadingAgreeing(true);
    return adminService
      .agreeDataSharingFakeCompany(pYesNo, pCompanyId)
      .then((rCompany) => {
        let copyCompanies = [...companies];
        var foundIndex = copyCompanies.findIndex((x) => x._id == pCompanyId);
        copyCompanies[foundIndex] = rCompany;
        setCompanies(copyCompanies);
      })
      .catch((e) => showToast.error(e.message))
      .finally(() => {
        setLoadingAgreeing(false);
      });
  }

  function handleDelete(pCompanyId) {
    setLoadingDeleting(true);
    return adminService
      .deleteFakeCompany(pCompanyId)
      .then((r) => {
        let copyCompanies = [...companies];
        copyCompanies = copyCompanies.filter((obj) => obj._id !== pCompanyId);
        showToast.success("Empresa deletada");
        setCompanies(copyCompanies);
      })
      .catch((e) => showToast.error(e.message))
      .finally(() => {
        setLoadingDeleting(false);
      });
  }

  function addCompanyToList(pCompany) {
    const copyCompanies = [...companies];
    copyCompanies.push(pCompany);
    setCompanies(copyCompanies);
  }

  if (user?.type !== "A") {
    return <h2>Not admin</h2>;
  }
  return (
    <MainAnimation>
      <Container maxWidth="md" style={{ paddingTop: 20, display: "flex" }}>
        <Paper
          style={{
            width: "100%",
            padding: 24,
            minHeight: 300,
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 300,
              }}
            >
              <TCLoading size={40} />
            </div>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h4" gutterBottom>
                  Fake Empresas
                </Typography>
                {!isAdding && (
                  <Button variant="outlined" onClick={() => setIsAdding(true)}>
                    Nova empresa
                  </Button>
                )}
              </div>
              {isAdding ? (
                <AddFakeCompany
                  handleClose={() => setIsAdding(false)}
                  addCompanyToList={addCompanyToList}
                />
              ) : companies?.length ? (
                companies?.map((rCompany) => (
                  <FakeCompanyCard
                    key={rCompany._id}
                    company={rCompany}
                    theme={theme}
                    handleAgree={handleAgree}
                    loadingAgreeing={loadingAgreeing}
                    handleDelete={handleDelete}
                    loadingDeleting={loadingDeleting}
                  />
                ))
              ) : (
                <h2 style={{ marginTop: 90, textAlign: "center" }}>
                  Sem fake empresas
                </h2>
              )}
            </>
          )}
        </Paper>
      </Container>
    </MainAnimation>
  );
}

function FakeCompanyCard({
  company,
  handleAgree,
  theme,
  handleDelete,
  loadingAgreeing,
  loadingDeleting,
}) {
  const [isDeleting, setIsDeleting] = useState(false);
  return (
    <Card
      style={{
        margin: 8,
        marginTop: 30,
        paddingBottom: 12,
        padding: 10,
        backgroundColor: theme == "DARK" ? grey[700] : grey[300],
        position: "relative",
        transition: "all 0.2s ease-in-out",
        height: 80,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <div style={{ display: "flex" }}>
          <Business style={{ marginRight: 8 }} />
          <Typography variant="h6">{company.nomeFantasia}{" "}{company.municipio}</Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Phone style={{ marginRight: 8 }} />
          <Typography variant="subtitle1" style={{ marginRight: 12 }}>
            {company.fakeUsuarioContato}
          </Typography>
          <Typography variant="subtitle2"> {company.telefone1}</Typography>
        </div>
      </div>
      {loadingAgreeing ? (
        <TCLoading />
      ) : (
        <div>
          <Typography variant="subtitle2">Compartilhar dados?</Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={() => handleAgree("yes", company?._id)}
              style={{ color: company.isAgreedShareData === 1 && "green" }}
            >
              <ThumbUpAlt />
            </IconButton>

            <IconButton
              onClick={() => handleAgree("no", company?._id)}
              style={{ color: company.isAgreedShareData === 0 && "red" }}
            >
              <ThumbDownAlt />
            </IconButton>
          </div>
        </div>
      )}
      {loadingDeleting ? (
        <TCLoading style={{ marginRight: 18 }} />
      ) : (
        <IconButton onClick={() => setIsDeleting(company?._id)}>
          <Delete />
        </IconButton>
      )}
      <YesNoModal
        showModal={isDeleting}
        title={`Deseja deletar ${company.nomeFantasia}?`}
        onYes={() => handleDelete(company._id)}
        handleClose={() => setIsDeleting(false)}
      />
    </Card>
  );
}

export default FakeCompanies;
