import { green, orange, grey } from "@material-ui/core/colors";
import { isLocal } from "../appConfig";

export const MAIN = "MAIN";
export const DARK = "DARK";

export const GREEN = isLocal ? "#821F26" : "#4e5f47";
export const SUB_GREEN = isLocal ? "#cda5a8" : "#c2c4a4";
export const BLUE = `#0582ca`
export const ICE =  "#b4cffa"

green[600] = GREEN;

grey[750] = "#3b3b3b";

const themes = {
  [MAIN]: {
    palette: {
      primary: {
        main: GREEN,
        mainDark: green[700],
      },
      secondary: { main: orange[800], mainDark: orange[900] },
      background: {
        // paper: "#f2f2f2",
        default: "#f2f2f2",
      },
    },
    typography: {
      //   fontFamily: "QuickSand",
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 600,
      fontWeightBold: 700,
    },
  },
  [DARK]: {
    palette: {
      type: "dark",
      primary: {
        main: GREEN,
        mainDark: green[700],
      },
      secondary: { main: orange[800], mainDark: orange[900] },
    },
    typography: {
      //   fontFamily: "QuickSand",
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 600,
      fontWeightBold: 700,
    },
  },
};

export default themes;

// #e7e8d2 	(231,232,210)
// 	#c2c4a4 	(194,196,164)
// 	#7f8a6f 	(127,138,111)
// 	#4e5f47 	(78,95,71)
// 	#263b1f
