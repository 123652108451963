import React from "react";
import { toast } from "react-toastify";
import {
  Close,
  CheckCircleOutline,
  InfoOutlined,
  ReportProblemOutlined,
} from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import { DARK, MAIN } from "@utils/themes";
import store from "../redux/store";

function Toast(pMessage, pType, pToastId) {
  const theme = store.getState().authReducer.theme;

  return toast.dark(
    <div style={{ display: "flex", alignItems: "center" }}>
      <ToastIcon type={pType} />
      <Typography variant="body1">{pMessage}</Typography>
    </div>,
    {
      style: styles[theme][pType],
      progressStyle: { backgroundColor: styles[theme][pType].color },
      pauseOnFocusLoss: false,
      closeButton: <Close color={styles[theme][pType].color} />,
      toastId: pToastId,
      autoClose: 10000,
    }
  );
}

function ToastIcon({ type }) {
  if (type == "success") {
    return <CheckCircleOutline style={{ color: "#4caf50", marginRight: 10 }} />;
  }
  if (type == "info") {
    return <InfoOutlined style={{ color: "#2196f3", marginRight: 10 }} />;
  }
  if (type == "warning") {
    return (
      <ReportProblemOutlined style={{ color: "#ff9800", marginRight: 10 }} />
    );
  }
  if (type == "error") {
    return <InfoOutlined style={{ color: "#f44336", marginRight: 10 }} />;
  }
}

const showToast = {
  success: (pMessage, pToastId) => Toast(pMessage, "success", pToastId),
  error: (pMessage, pToastId) => Toast(pMessage, "error", pToastId),
  info: (pMessage, pToastId) => Toast(pMessage, "info", pToastId),
  warning: (pMessage, pToastId) => Toast(pMessage, "warning", pToastId),
};

export default showToast;

const styles = {
  [MAIN]: {
    success: {
      backgroundColor: "rgb(237, 247, 237)",
      color: "rgb(30, 70, 32)",
    },
    warning: {
      backgroundColor: "rgb(255, 244, 229)",
      color: "rgb(102, 60, 0)",
    },
    error: {
      backgroundColor: "rgb(253, 236, 234)",
      color: "rgb(97, 26, 21)",
    },
    info: {
      backgroundColor: "rgb(232, 244, 253)",
      color: "rgb(13, 60, 97)",
    },
  },

  [DARK]: {
    success: {
      backgroundColor: "rgb(7, 30 , 7)",
      color: "rgb(183, 223, 185)",
    },
    warning: {
      backgroundColor: "rgb(25, 15, 0)",
      color: "rgb(255, 213, 153)",
    },
    error: {
      backgroundColor: "rgb(24, 6, 5)",
      color: "rgb(250, 179, 174)",
    },
    info: {
      backgroundColor: "rgb(3, 14, 24)",
      color: "rgb(166, 213, 250)",
    },
  },
};
