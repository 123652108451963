import React, { useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import { getTokenLocalStorage } from "@utils/localstorage/tokenStorage";
import {
  setChats,
  setChatId,
  setUnseenMessages,
  addMessageToRedux,
} from "@redux/chatReducer";
import ChatContext from "../../context/ChatContext";
import { connectSocket, socketUrl } from "../../appConfig";
import {
  addEditFeedOferta,
  addMinhasOfertas,
  deleteOferta,
  setMinhasOfertas,
  setOfertas,
  updatedPublishedOferta,
} from "../../redux/ofertaReducer";
import { setCotacao, setVencimentos } from "../../redux/cotacaoReducer";
import {
  setIsConnectedInvestingSocket,
  setFirstLoading,
  setPlanos,
} from "../../redux/authReducer";
import {
  addReply,
  handleAddReplyCount,
  setWaitingPosts,
  setPosts,
  setReplies,
} from "../../redux/forumReducer";
import firstLoadService from "../../services/firstLoadService";
import { useHistory } from "react-router-dom";

let interval;
let timeoutReconnect;
export default function SocketListener() {
  const dispatch = useDispatch();

  const [user, filtros, replies, waitingPosts, cotacao] = useSelector(
    (states) => [
      states.authReducer.user,
      states.ofertaReducer.filtros,
      states.forumReducer.replies,
      states.forumReducer.waitingPosts,
      states.cotacaoReducer,
    ]
  );
  const { socket, setSocket } = useContext(ChatContext);
  const filtrosRef = useRef();
  const todayDateRef = useRef(pvGetDateToday());
  const waitingPostsRef = useRef();
  const repliesRef = useRef();
  const socketRef = useRef();
  const socketInvestingRef = useRef();
  const cotacaoRef = useRef();
  const history = useHistory();

  filtrosRef.current = filtros;
  waitingPostsRef.current = waitingPosts;
  repliesRef.current = replies;
  socketRef.current = socket;
  cotacaoRef.current = cotacao;

  let handleChangeOfDay = () => {
    //First Load if date of today changes! (for when the computer locks)
    let today = pvGetDateToday()
    let dayChanged = today !== todayDateRef.current;
    if(dayChanged){
      todayDateRef.current = today;
      firstLoad()
    }
  }
  useEffect(() => {
    if (user?._id) {
      firstLoad();
    }
    window.addEventListener('focus', handleChangeOfDay);

    return () => {
      dispatch(setChatId(""));
      window.removeEventListener('focus', handleChangeOfDay);
    };
  }, [user?._id]);

  useEffect(() => {
    if (user?._id) {
      let xSocket = pvCreateSocket(user);
      setSocket(xSocket);
      pvSocketOnReceivedMessage(xSocket);
      pvSocketOnNewOffer(xSocket, dispatch, user);
      if (connectSocket) {
        connectInvestingSocket();
      }
      xSocket.on("forumPost", (rMsgObj) => {
        pvSocketOnForumPost(
          rMsgObj,
          dispatch,
          user,
          waitingPostsRef.current,
          repliesRef.current
        );
      });

      xSocket.on("cobrancaPaga", () => {
        history.push("/feed?pago=1");
        window.location.reload();
      });
      xSocket.on("reconnect", function () {
        firstLoad();
      });
    }
    return () => {
      clearInterval(interval);
      setSocket(null);
      socketRef.current.disconnect();
      if (connectSocket) {
        socketInvestingRef.current.close();
        socketInvestingRef.current = null;
      }
    };
  }, [user?._id]);

  function connectInvestingSocket() {
    socketInvestingRef.current = new WebSocket(
      "wss:stream245.forexpros.com/echo/264/hvlwpgb1/websocket"
    );
    socketInvestingRef.current.onmessage = (e) =>
      socketInvestingOnMessage(e, socketInvestingRef.current);
    socketInvestingRef.current.onclose = (e) => {
      dispatch(setIsConnectedInvestingSocket(false));
      timeoutReconnect = setTimeout(() => {
        connectInvestingSocket();
      }, 6000);
    };
    socketInvestingRef.current.onopen = (e) => {
      dispatch(setIsConnectedInvestingSocket(true));
      if (timeoutReconnect) {
        clearInterval(timeoutReconnect);
      }
    };
  }
  function socketInvestingOnMessage(pEvent, pSocket) {
    if (pEvent.data === "o") {
      pvStartInvestingSocket(pSocket);
    }

    const cotacaoInvesting = pvGetCotacaoInvestingJSON(pEvent);

    if (cotacaoInvesting) {
      const cotacaoNova = pvBuildCotacaoNova(
        cotacaoInvesting,
        cotacaoRef.current
      );

      return dispatch(setCotacao(cotacaoNova));
    }
  }

  function firstLoad() {
    dispatch(setFirstLoading(true));
    return firstLoadService(user, filtrosRef.current)
      .then(
        ([
          rChats,
          rUnseenMessages,
          rOfertas,
          rMinhasOfertas,
          rCotacao,
          rPosts,
          rVencimentos,
          rPlanos,
        ]) => {
          dispatch(setChats(rChats));
          dispatch(setUnseenMessages(rUnseenMessages));
          dispatch(setOfertas(rOfertas));
          dispatch(setMinhasOfertas(rMinhasOfertas));
          dispatch(setCotacao(rCotacao));
          dispatch(setPosts(rPosts));
          dispatch(setVencimentos(rVencimentos));
          dispatch(setReplies({}));
          dispatch(setPlanos(rPlanos));
        }
      )
      .catch((e) => console.log("e", e))
      .finally(() => dispatch(setFirstLoading(false)));
  }
  return <div />;
}

function pvCreateSocket(pUser) {
  let xToken = getTokenLocalStorage() || `Bearer ${pUser.authToken}`;
  return io(socketUrl, {
    query: { token: xToken },
    reconnection: true,
    reconnectionDelay: 500,
    maxReconnectionAttempts: Infinity,
  });
}

function pvSocketOnReceivedMessage(pSocket) {
  return pSocket.on("receivedMessage", (messageObj) => {
    console.log("received message", messageObj);

    addMessageToRedux(messageObj, true);
  });
}

function pvSocketOnNewOffer(pSocket, pDispatch, pUser) {
  return pSocket.on("newOffer", (messageObj) => {
    const xJSON = messageObj;
    let xEmpresaId = pUser.empresa?._id || pUser.empresa;

    console.log("Recebeu mensagem", xJSON);
    if (xJSON.vencimentos) {
      return pDispatch(setVencimentos(xJSON.vencimentos));
    }
    if (xJSON.cotacao) {
      return;
      return pDispatch(setCotacao(xJSON.cotacao));
    }
    if (xJSON.delete) {
      return pDispatch(deleteOferta(xJSON.delete?._id));
    }
    if (xJSON.publish) {
      const xNewOferta = xJSON.publish;
      let xOfertaEmpresaId = xNewOferta.empresa?._id || xNewOferta.empresa;

      if (xEmpresaId === xOfertaEmpresaId) {
        pDispatch(addMinhasOfertas(xNewOferta));
      }
      return pDispatch(updatedPublishedOferta(pUser._id, xNewOferta));
    }
    if (xJSON.new || xJSON.edit) {
      console.log("XJSON", xJSON);
      const xNewOferta = xJSON.new || xJSON.edit;
      let xOfertaEmpresaId = xNewOferta.empresa?._id || xNewOferta.empresa;
      if (xEmpresaId === xOfertaEmpresaId) {
        pDispatch(addMinhasOfertas(xNewOferta));
      }
      return pDispatch(addEditFeedOferta(xNewOferta, pUser._id));
    }
    console.log("====> DEAD END");
  });
}

function pvSocketOnForumPost(
  pMsgObj,
  pDispatch,
  pUser,
  pWaitingPosts,
  pReplies
) {
  if (pMsgObj.newPost && pMsgObj.newPost?.usuario?._id !== pUser._id) {
    pDispatch(setWaitingPosts([pMsgObj.newPost, ...pWaitingPosts]));
  }
  if (pMsgObj.newReply && pMsgObj.newReply?.usuario?._id !== pUser._id) {
    //Add replies to the list if user already fetched replies before
    if (pReplies[pMsgObj.newReply.post]) {
      pDispatch(addReply(pMsgObj.newReply.post, pMsgObj.newReply));
    }
    //Add +1 to the message count on the post card
    pDispatch(handleAddReplyCount(pMsgObj.newReply.post, pMsgObj.newReply));
  }
}
const DOLLAR_ID = "2103";
const COFFEE_NY_ID = "8832";
const COFFEE_LONDON_ID = "8911";

const SUBSCRIBE_MESSAGE = JSON.stringify([
  `{\"_event\":\"bulk-subscribe\",\"tzID\":8,\"message\":\"pid-${COFFEE_NY_ID}:%%pid-${DOLLAR_ID}:%%pid-${COFFEE_LONDON_ID}:%%"}`,
]);
const UID_MESSAGE = '[{"_event":"UID","UID": 0}]';
const HEARTBEAT_MESSAGE = JSON.stringify([
  `{\"_event\":\"heartbeat\",\"data\":\"h\"}`,
]);

function pvStartInvestingSocket(pSocket) {
  pSocket.send(SUBSCRIBE_MESSAGE);
  pSocket.send(UID_MESSAGE);
  interval = setInterval(() => {
    pSocket.send(HEARTBEAT_MESSAGE);
  }, [3000]);
}

function pvGetCotacaoInvestingJSON(pEvent) {
  let str = [];
  for (let i = 0; i < pEvent.data.length; i++) {
    if (i !== 0) {
      str[i - 1] = pEvent.data[i];
    }
  }
  if (!str.length) {
    return null;
  }
  str.pop();
  str.shift();
  const jsonMessage = JSON.parse(JSON.parse(str.join("")))?.message;
  const j = jsonMessage?.split("::");
  if (!j) return null;
  return JSON.parse(j[1]);
}

function pvBuildCotacaoNova(pCotacaoInvesting, cotacao) {
  let cotacaoNova = { ...cotacao };

  consoletype(pCotacaoInvesting);
  // console.log(pCotacaoInvesting);
  if (pCotacaoInvesting.pid === COFFEE_NY_ID) {
    cotacaoNova.precoNY = parseFloat(pCotacaoInvesting.last);
    cotacaoNova.blinkPrecoNY = pCotacaoInvesting.last_dir;
  }
  if (pCotacaoInvesting.pid === DOLLAR_ID) {
    const xDollar = parseFloat(pCotacaoInvesting.last);
    cotacaoNova.dollar = xDollar;
    cotacaoNova.blinkDollar = pCotacaoInvesting.last_dir;
  }

  if (pCotacaoInvesting.pid === COFFEE_LONDON_ID) {
    let xLondresCorrigido = pCotacaoInvesting.last?.toString().replace(",", "");
    const xLondres = parseFloat(xLondresCorrigido);
    cotacaoNova.precoLondres = xLondres;
    cotacaoNova.blinkPrecoLondres = pCotacaoInvesting.last_dir;
  }

  cotacaoNova.updatedAt = new Date().toISOString();
  return cotacaoNova;
}

function consoletype(pCotacaoInvesting) {
  if (pCotacaoInvesting.pid == DOLLAR_ID) {
    return console.log("Recebeu DOLLAR");
  }
  if (pCotacaoInvesting.pid == COFFEE_LONDON_ID) {
    return console.log("Recebeu LONDON");
  }
  if (pCotacaoInvesting.pid == COFFEE_NY_ID) {
    return console.log("Recebeu NY");
  }
}

let pvGetDateToday = () => {
  var now = new Date();
  // Get the local offset in minutes and convert it to hours
  var localOffset = now.getTimezoneOffset() / 60;

  // Brazil's time zone offset is UTC-3, so calculate the difference
  var brazilOffset = -3; // Brazil time zone is UTC-3

  // Calculate the difference between the local time zone and Brazil's time zone
  var offsetDifference = brazilOffset - localOffset;

  // Adjust the current date to Brazil's time zone

  return new Date(now.getTime() + offsetDifference * 60 * 60 * 1000).toDateString();
};