import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { setupInterceptors } from "@services/api";

export default function InjectAxiosInterceptors({ logout }) {
  const history = useHistory();

  useEffect(() => {
    setupInterceptors(logout);
  }, [history]);

  // not rendering anything
  return null;
}
