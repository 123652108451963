import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import ofertaService from "@services/ofertaService";
import { MINHAS_OFERTAS_TABELA_HEADERS_APPEARING } from "@utils/data/tabelaHeaders";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { green } from "@material-ui/core/colors";
import Th from "../components/Th";
import MinhasOfertasTr, { MOActions } from "./MinhasOfertasTr";
import LoadingAndSemOfertas from "../components/LoadingSemOfertas";
import {
  ASPECTO,
  COR_DO_CAFE,
  PADRAO_DO_CAFE,
} from "../../../utils/data/formFields";
import BEBIDA_BASE from "../../../utils/data/bebidabase";
import showToast from "../../Toast";
import { setModalPLanosOpen } from "@redux/authReducer";
import checkIfPremium from "@utils/checkIfPremium";
import { deleteOferta, addMinhasOfertas } from "@redux/ofertaReducer";
import { Divider } from "@material-ui/core";
import QuickMessageModal from "../../QuickMessages/QuickMessageModal";

function MinhasOfertasTable({ openEditModal, openEditModalNew, idx }) {
  const [ofertas] = useSelector((states) => [states.ofertaReducer.ofertas]);
  const [selectedOfertaId, setSelectedOfertaId] = useState();
  if (!ofertas.data) {
    return <></>;
  }

  const xDisplay = idx == 0 ? { display: "none" } : {};
  return (
    <Paper style={{ marginTop: 8, marginBottom: 60, ...xDisplay }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TableContainer style={{ minHeight: 685 }}>
          <MOTable
            openEditModal={openEditModal}
            setShowQuickMessageModal={setSelectedOfertaId}
            openEditModalNew={openEditModalNew}
          />
        </TableContainer>
        <QuickMessageModal
          showModal={selectedOfertaId}
          selectedOfertaId={selectedOfertaId}
          handleClose={() => setSelectedOfertaId()}
          handleChange={() => setSelectedOfertaId()}
        />
      </div>
    </Paper>
  );
}

export default MinhasOfertasTable;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  icon: {
    cursor: "pointer",
  },
  iconGreen: {
    color: green[600],
  },
  tableHeader: {
    // padding: "2px 14px 2px 16px",
  },
});

function MOTable({
  openEditModal,
  openEditModalNew,
  setShowQuickMessageModal,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [user, cotacao, minhasOfertas] = useSelector((states) => [
    states.authReducer.user,
    states.cotacaoReducer,
    states.ofertaReducer.minhasOfertas,
  ]);
  const [loadingP, setLoadingP] = useState(false);
  const [loadingD, setLoadingD] = useState(false);
  const [loadingF, setLoadingF] = useState(false);

  const xToday = new Date().toDateString();
  const data = minhasOfertas;
  const xHasData = data ? Object.keys(data).length : false;

  function deletarOferta(ofertaId) {
    setLoadingD(ofertaId);
    return ofertaService
      .deleteOferta(ofertaId)
      .then(() => {
        showToast.success("Oferta deletada");
        dispatch(deleteOferta(ofertaId));
      })
      .catch((e) => {
        showToast.error("Erro deletando oferta");

        console.log(e);
      })
      .finally(() => setLoadingD(false));
  }

  function freezeOferta(ofertaId, preco, pShouldPublish) {
    if (!checkIfPremium(user)) {
      return dispatch(setModalPLanosOpen(true));
    }
    setLoadingF(ofertaId);
    return ofertaService
      .freezeOferta(ofertaId, preco, pShouldPublish)
      .then((rOferta) => {
        dispatch(addMinhasOfertas(rOferta));
      })
      .catch((e) => {
        console.log('e',e)
        showToast.error("Erro publicando oferta");

        console.log(e);
      })
      .finally(() => setLoadingF(false));
  }

  function publicarOferta(ofertaId, pShouldPublish) {
    if (!checkIfPremium(user)) {
      return dispatch(setModalPLanosOpen(true));
    }
    setLoadingP(ofertaId);
    return ofertaService
      .publishOferta(ofertaId, pShouldPublish)
      .then((rOferta) => {
        // showToast.success(
        //   `Oferta ${pShouldPublish ? "publicada no" : "removida do"} feed`
        // );
        dispatch(addMinhasOfertas(rOferta));
      })
      .catch((e) => {
        showToast.error("Erro Congelando oferta");

        console.log(e);
      })
      .finally(() => setLoadingP(false));
  }
  const xIsMobile = window.innerWidth < 1000;

  if (xIsMobile) {
    return (
      <MobileTable
        data={data}
        openEditModal={openEditModal}
        openEditModalNew={openEditModalNew}
        deletarOferta={deletarOferta}
        publicarOferta={publicarOferta}
        loadingD={loadingD}
        loadingP={loadingP}
        loadingF={loadingF}
        xToday={xToday}
        setShowQuickMessageModal={setShowQuickMessageModal}
      />
    );
  }
  return (
    <Table className={classes.table} size="small">
      <TableHead>
        <TableHeaders data={data} />
      </TableHead>
      <TableBody>
        {xHasData ? (
          Object.values(data).map((rOferta) => {
            const xIsPublished = pvIsPublished(rOferta, xToday);
            if (!rOferta._id) {
              return null;
            }
            return (
              <MinhasOfertasTr
                key={rOferta._id}
                openEditModal={openEditModal}
                openEditModalNew={openEditModalNew}
                xIsPublished={xIsPublished}
                oferta={rOferta}
                freezeOferta={freezeOferta}
                cotacao={cotacao}
                setShowQuickMessageModal={setShowQuickMessageModal}
                publicarOferta={publicarOferta}
                deletarOferta={deletarOferta}
                loadingP={loadingP}
                loadingF={loadingF}
                loadingD={loadingD}
              />
            );
          })
        ) : (
          <LoadingAndSemOfertas colspan={"30"} />
        )}
      </TableBody>
    </Table>
  );
}

const pvIsPublished = (pOferta, pToday) =>
  pOferta.publishedAt
    ? new Date(pOferta.publishedAt).toDateString() == pToday
    : false;

function TableHeaders() {
  return (
    <TableRow>
      <Th align="center">Modo</Th>
      <Th align="center">Ações</Th>
      {Object.keys(MINHAS_OFERTAS_TABELA_HEADERS_APPEARING).map((key) => {
        let displayTable = MINHAS_OFERTAS_TABELA_HEADERS_APPEARING[key].table;
        return (
          <Th
            shouldRender
            key={key}
            style={{ display: "flex" }}
            align={key === "acoes" ? "center" : "left"}
          >
            {displayTable}
          </Th>
        );
      })}
      <Th>Dif</Th>
      <Th>Empresa</Th>
      <Th>Criador</Th>
      <Th>Vencimento</Th>
      <Th>Atualização</Th>
      {/* <Th>Inclusão</Th> */}
    </TableRow>
  );
}

function MobileTable({
  data,
  openEditModal,
  openEditModalNew,
  deletarOferta,
  publicarOferta,
  loadingD,
  loadingP,
  loadingF,
  xToday,
  setShowQuickMessageModal,
}) {
  return Object.values(data)?.map((oferta) => {
    const xIsPublished = pvIsPublished(oferta, xToday);
    return (
      <>
        <MOfertaCard
          oferta={oferta}
          openEditModal={openEditModal}
          openEditModalNew={openEditModalNew}
          deletarOferta={deletarOferta}
          publicarOferta={publicarOferta}
          xIsPublished={xIsPublished}
          loadingD={loadingD}
          loadingP={loadingP}
          loadingF={loadingF}
          setShowQuickMessageModal={setShowQuickMessageModal}
        />
        <Divider />
      </>
    );
  });
}

function MOfertaCard({
  oferta,
  openEditModal,
  openEditModalNew,
  deletarOferta,
  publicarOferta,
  xIsPublished,
  loadingD,
  loadingP,
  loadingF,
  setShowQuickMessageModal,
}) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        padding: 12,

        justifyContent: "space-between",
      }}
    >
      <ColumnDiv>
        <span>{oferta.anoSafra} </span>
        <span>{oferta.especie}</span>
        <span>{oferta?.processamento}</span>
      </ColumnDiv>
      <ColumnDiv>
        <span>{oferta?.bebidaBase} </span>
        <span>{oferta?.fava} </span>
        <span>{ASPECTO[oferta?.aspecto]?.detail}</span>
      </ColumnDiv>
      <ColumnDiv>
        <span>{COR_DO_CAFE[oferta?.cor].detail} </span>
        <span>{PADRAO_DO_CAFE[oferta?.padrao].detail} </span>
      </ColumnDiv>
      <ColumnDiv center>
        <MOActions
          oferta={oferta}
          openEditModal={openEditModal}
          openEditModalNew={openEditModalNew}
          deletarOferta={deletarOferta}
          publicarOferta={publicarOferta}
          setShowQuickMessageModal={setShowQuickMessageModal}
          xIsPublished={xIsPublished}
          loadingD={loadingD}
          loadingP={loadingP}
          loadingF={loadingF}
        />
      </ColumnDiv>
    </div>
  );
}

function ColumnDiv({ children, center }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: center ? "center" : null,
      }}
    >
      {children}
    </div>
  );
}
