import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles({
  tableCellSizeSmall: {
    padding: "2px 2px 2px 2px",
    minWidth: 2,
  },
  tableCellRoot: {
    minWidth: 2,
    paddingLeft: "8px",
    fontSize: 12,

    // //Good for debbuging
    // backgroundColor: "green",
    // border: "1px solid black",
  },
});

export default function Td({ children, right, ...props }) {
  const classes = useStyles();

  return (
    <TableCell
      classes={{
        root: classes.tableCellRoot,
        sizeSmall: classes.tableCellSizeSmall,
      }}
      align={right ? "right" : "left"}
      {...props}
    >
      {children}
    </TableCell>
  );
}
