import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ofertaService from "@services/ofertaService";
import _ from "lodash";
import { setOfertas, setFreeOfertas } from "@redux/ofertaReducer";
import { TABELA_HEADERS_APPEARING } from "@utils/data/tabelaHeaders";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Add } from "@material-ui/icons";
import Th from "@components/Tables/components/Th";
import FeedTr from "./FeedTr";
import showToast from "../../Toast";
import LoadingAndSemOfertas from "../components/LoadingSemOfertas";
import OfertaDetails from "./OfertaDetails";
import FastChatModal from "./FastChatModal";
import checkIfPremium from "@utils/checkIfPremium";
import { setModalPLanosOpen } from "@redux/authReducer";
import { setFastChat } from "@redux/ofertaReducer";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tc: {
    minHeight: 685,
  },
});

function FeedTable({ filtros, isFree, idx }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openIdx, setOpenIdx] = useState(null);
  const [user, ofertas, freeOfertas, cotacao, fastChat] = useSelector(
    (states) => [
      states.authReducer.user,
      states.ofertaReducer.ofertas,
      states.ofertaReducer.freeOfertas,
      states.cotacaoReducer,
      states.ofertaReducer.fastChat,
    ]
  );
  const [idxOfertaFastChat, setIdxOfertaFastChat] = React.useState(null);
  function handleFastChatOpen(pOfertaId, pDestinoUserId) {
    console.log("pOfertaId, pDestinoUserId", pOfertaId, pDestinoUserId);
    if (!checkIfPremium(user)) {
      return dispatch(setModalPLanosOpen(true));
    }
    console.log("settingFAstChat");
    return dispatch(setFastChat(pOfertaId, pDestinoUserId));
  }

  useEffect(() => {
    if (isFree) {
      return ofertaService
        .getFreeOfertas("C")
        .then((rOfertas) => {
          dispatch(setFreeOfertas(rOfertas));
        })
        .catch((e) => {
          showToast.error("Erro recuperando ofertas");
          console.log(e);
        });
    }
  }, []);

  const xData = isFree ? freeOfertas?.data : ofertas?.data;
  const xHasData = xData ? Object.keys(xData).length : false;
  const xDisplay = idx == 1 ? { display: "none" } : {};

  return (
    <Paper style={{ marginBottom: 60, ...xDisplay }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TableContainer
          style={{
            minHeight: 685,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TableDesktop
            hasData={xHasData}
            data={xData}
            openIdx={openIdx}
            setOpenIdx={setOpenIdx}
            isFree={isFree}
            handleFastChatOpen={handleFastChatOpen}
          />
        </TableContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {ofertas.totalPages > ofertas.page ? (
            <IconButton
              onClick={() => {
                ofertaService
                  .getOfertas(filtros, ofertas)
                  .then((rOfertas) => {
                    console.log("rOfertas", rOfertas);
                    dispatch(setOfertas(rOfertas));
                  })
                  .catch((e) => {
                    showToast.error("Erro recuperando ofertas");
                    console.log(e);
                  });
              }}
            >
              <Add />
            </IconButton>
          ) : (
            <></>
          )}
        </div>
      </div>

      <FastChatModal
        oferta={
          fastChat.ofertaId && fastChat.destinoUsuario?._id
            ? xData[fastChat.ofertaId]
            : null
        }
        handleClose={() => dispatch(setFastChat("", ""))}
        cotacao={cotacao}
        user={user}
      />
    </Paper>
  );
}

export default FeedTable;

// function bblSort(arr) {
//   for (var i = 0; i < arr.length; i++) {
//     for (var j = 0; j < arr.length - i - 1; j++) {
//       if (arr[j].sortingKey > arr[j + 1].sortingKey) {
//         var temp = arr[j];
//         arr[j] = arr[j + 1];
//         arr[j + 1] = temp;
//       }
//     }
//   }
//   return arr;
// }

// function pvGetSortedData(pData) {
//   if (!pData || !pData) {
//     return [];
//   }

//   let xSortable = [];
//   for (let xId in pData) {
//     const xOferta = pData[xId];
//     let xSortingKey = pvBuildSortingKey(xOferta);

//     xSortable.push({ ...xOferta, sortingKey: xSortingKey });
//   }

//   return bblSort(xSortable);
// }

// function pvBuildSortingKey(pOferta) {
//   let xSortingKey = "";
//   if (pOferta.anoSafra) {
//     xSortingKey += ANO_SAFRA[pOferta.anoSafra].sortingKey;
//   }
//   if (pOferta.especie) {
//     xSortingKey += pOferta.especie.toLowerCase();
//   }
//   if (pOferta.processamento) {
//     xSortingKey += pOferta.processamento.toLowerCase();
//   }
//   if (pOferta.bebidaBase) {
//     xSortingKey += BEBIDA_BASE[pOferta.bebidaBase].sortingKey;
//   } else {
//     xSortingKey += "__";
//   }
//   if (PERCENTAGE_KEYS[pOferta.catacao]) {
//     xSortingKey += PERCENTAGE_KEYS[pOferta.catacao];
//   } else {
//     xSortingKey += "__";
//   }
//   return xSortingKey;
// }

function TableDesktop({
  hasData,
  data,
  openIdx,
  setOpenIdx,
  isFree,
  handleFastChatOpen,
}) {
  const classes = useStyles();
  const [user, cotacao] = useSelector((states) => [
    states.authReducer.user,
    states.cotacaoReducer,
  ]);

  if (!hasData) return <LoadingAndSemOfertas isFeed />;
  const xToday = new Date().toDateString();
  if (window.innerWidth < 1000) {
    return Object.values(data)?.map((rOferta, i) => (
      <OfertaDetails oferta={rOferta}
        handleFastChatOpen={handleFastChatOpen} />
    ));
  }
  return (
    <Table className={classes.table} size="small">
      <TableHead>
        <TableRow>
          <Th>Modo</Th>

          {Object.keys(TABELA_HEADERS_APPEARING).map((key) => (
            <Th key={key} align={key === "preco" ? "center" : undefined}>
              {TABELA_HEADERS_APPEARING[key].table}
            </Th>
          ))}
          {/* <Th>Vencimento</Th> */}
          <Th>Atualização</Th>
          {/* {!isFree && <Th>Contato</Th>} */}
        </TableRow>
      </TableHead>

      <TableBody>
        {hasData ? (
          <>
            {Object.values(data)?.map((rOferta, i) => {
              const xIsPublished = rOferta.publishedAt
                ? new Date(rOferta.publishedAt).toDateString() == xToday
                : false;

              return (
                <FeedTr
                  key={rOferta._id}
                  i={i}
                  xIsPublished={xIsPublished}
                  oferta={rOferta}
                  handleFastChatOpen={handleFastChatOpen}
                  cotacao={cotacao}
                  user={user}
                  openIdx={openIdx}
                  setOpenIdx={setOpenIdx}
                  isFree={isFree}
                />
              );
            })}
          </>
        ) : (
          <LoadingAndSemOfertas colspan={"26"} isFeed />
        )}
      </TableBody>
    </Table>
  );
}
