import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  Group,
  PersonAdd,
  Delete,
  HourglassEmpty,
  Check,
} from "@material-ui/icons";
import empresaService from "@services/empresaService";
import YesNoModal from "../YesNoModal";
import InviteUserToCompanyModal from "./InviteUserToCompanyModal";
import showToast from "../Toast";
import { setModalPLanosOpen } from "../../redux/authReducer";
import { useDispatch, useSelector } from "react-redux";
import TCLoading from "../TCLoading";
import { green, orange } from "@material-ui/core/colors";
import checkIfPremium from "@utils/checkIfPremium";

export default function EditarMembros({ empresa }) {
  let dispatch = useDispatch();
  const [lista, setLista] = useState({});
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState({});
  const [showYesNoModal, setShowYesNoModal] = useState(false);

  const [user] = useSelector((states) => [states.authReducer.user]);

  useEffect(() => {
    empresaService
      .getMembros(empresa?._id)
      .then((rLista) => {
        setLista(rLista);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, [empresa]);

  function onSuccessInviteToCompany(pConvite) {
    const copyLista = { ...lista };
    copyLista.convites.push(pConvite);
    setLista(copyLista);
  }

  function handleDeleteConvite(pConviteId) {
    setLoading(true);
    empresaService
      .deleteConvite(pConviteId)
      .then(() => {
        const copyLista = { ...lista };
        copyLista.convites = copyLista.convites.filter(
          (obj) => obj._id !== pConviteId
        );
        setLista(copyLista);
      })
      .catch((e) => showToast.error(e.message))
      .finally(() => setLoading(false));
  }

  function handleDeleteMember(pUserId) {
    setShowYesNoModal(false);
    empresaService
      .deleteCompanyMember(empresa?._id, pUserId)
      .then((rUser) => {
        const copyLista = { ...lista };
        copyLista.membros = copyLista.membros.filter(
          (obj) => obj._id !== rUser._id
        );
        setLista(copyLista);
        showToast.success("Usuario removido da empresa");
      })
      .catch((e) => {
        showToast.error(e.message);
      });
  }

  function handleMembroAdd() {
    if (!checkIfPremium(user)) {
      return dispatch(setModalPLanosOpen(true));
    }
    
    return setIsModalOpen(true);
  }
  function getQtd() {
    if (user?.empresa?.qtdUsuarios && lista?.membros?.length) {
      return `${lista?.membros?.length + lista?.convites?.length}/${
        user?.empresa?.qtdUsuarios
      }`;
    }
  }
  return (
    <Box
      style={{
        marginTop: 60,
        maxWidth: 300,
      }}
    >
      <YesNoModal
        showModal={showYesNoModal}
        title={`Deseja deletar ${deleteUser.name}?`}
        onYes={() => handleDeleteMember(deleteUser._id)}
        handleClose={() => setShowYesNoModal(false)}
      />
      {empresa?.isAdmin && user?.empresa?.qtdUsuarios ? (
        <Typography variant="caption">{`Seu plano permite ${
          user?.empresa?.qtdUsuarios > 1 ? "até" : "somente"
        } ${user?.empresa?.qtdUsuarios} membro${
          user?.empresa?.qtdUsuarios > 1 ? "s" : ""
        }`}</Typography>
      ) : null}
      <Box
        style={{
          display: "flex",
          marginTop: 10,
          width: 300,
          position: "relative",
        }}
      >
        <Group fontSize="large" />

        <Typography variant="h5" style={{ marginLeft: 20 }}>
          {getQtd()} Membros
        </Typography>
        {empresa?.isAdmin ? (
          <div style={{ position: "absolute", top: -8, right: 0 }}>
            <IconButton onClick={handleMembroAdd}>
              <PersonAdd />
            </IconButton>
          </div>
        ) : null}
      </Box>
      <Box style={{ marginTop: 20 }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TCLoading size={20} />
          </div>
        ) : (
          <>
            {lista?.membros?.map((rMembro) => (
              <EachMembro
                criadorId={empresa?.criadorId}
                membro={rMembro}
                isDeletable={empresa?.isAdmin}
                onDelete={() => {
                  setShowYesNoModal(true);
                  setDeleteUser(rMembro);
                }}
              />
            ))}
            <div>
              {lista?.convites?.map((rConvite) => (
                <EachMembro
                  membro={rConvite}
                  isDeletable={empresa?.isAdmin}
                  key={rConvite._id}
                  onDelete={() => handleDeleteConvite(rConvite._id)}
                />
              ))}
            </div>
          </>
        )}
        <InviteUserToCompanyModal
          handleClose={() => setIsModalOpen(false)}
          showModal={isModalOpen}
          onSuccess={onSuccessInviteToCompany}
          empresaId={empresa?._id}
          nomeFantasia={empresa?.nomeFantasia}
        />
      </Box>
    </Box>
  );
}

function EachMembro({ isDeletable, membro, onDelete }) {
  const [loading, setLoading] = useState(false);

  async function deleteWithLoading(pId) {
    setLoading(true);
    await onDelete(pId);
    setLoading(false);
  }

  function getStatus(membro) {
    if (membro?.confirmed) {
      return (
        <Tooltip title="Usuario Confirmado">
          <Check style={{ color: green[700] }} />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Convite Enviado">
        <HourglassEmpty style={{ color: orange[700] }} />
      </Tooltip>
    );
  }
  return (
    <div>
      <Box
        style={{
          display: "flex",
          height: 40,
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: 10,
        }}
      >
        <Typography variant="body2">{`•  ${
          membro.name || membro.emailConvidado
        }`}</Typography>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: 12, marginTop: 6 }}>
            {getStatus(membro)}
          </div>

          {loading ? (
            <TCLoading size={20} />
          ) : isDeletable ? (
            <Tooltip title="Deletar">
              <Delete
                style={{ cursor: "pointer" }}
                onClick={() => deleteWithLoading(membro?._id)}
              />
            </Tooltip>
          ) : null}
        </div>
      </Box>
      <Divider />
    </div>
  );
}
