import React, { useState, useEffect } from "react";
import { HashRouter, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "@pages/Login";
import Feed from "@pages/Feed";
import Pricing from "@pages/Pricing";
import Chat from "@pages/Chat";
import Forum from "@pages/Forum";
import Profile from "@pages/Profile";
import Company from "@pages/Company";
import RegisterCompany from "@pages/RegisterCompany";
import AdminPanel from "@pages/AdminPanel";
import RegisterWithInvite from "@pages/RegisterWithInvite";
import ConfirmAccount from "@pages/ConfirmAccount";
import WaitingCompanyConfirm from "@pages/WaitingCompanyConfirm";
import ServerUnavailable from "@pages/ServerUnavailable";
import PasswordReset from "@pages/PasswordReset";
import Feedback from "@pages/Feedback";
import QuickMessagesConfig from "@pages/QuickMessagesConfig";
import Payment from "@pages/Payment";
import { getTokenLocalStorage } from "@utils/localstorage/tokenStorage";
import Header from "@components/Header";
import InjectAxiosInterceptors from "@components/InjectAxiosInterceptors";
import ChatContext from "./context/ChatContext";
import { setAuthInfo } from "./redux/authReducer";
import authService from "@services/authService";
import showToast from "./components/Toast";
import MainAnimation from "@components/MainAnimation";
import { clearLocalStorage } from "@utils/localstorage/tokenStorage";
import { clearAllReducers } from "./redux/store";
// import ModalPlanos from "./components/ModalPlanos";
import CadastrarOferta from "./pages/CadastrarOferta";
import TCLoading from "./components/TCLoading";
import QuickMessagesTemplates from "./pages/QuickMessagesTemplates";
import { mixpanelIdentify } from "@utils/mixpanel/mixpanel.js";
import mixpanel from "@utils/mixpanel/mixpanel.js";
import { useHistory } from "react-router-dom";

const isAuthenticated = () => {
  return getTokenLocalStorage();
};

function Routes() {
  const [loading, setLoading] = useState(true);
  const [apiOn, setApiOn] = useState(true);
  const dispatch = useDispatch();

  const [user, firstLoading] = useSelector((states) => [
    states.authReducer.user,
    states.authReducer.firstLoading,
  ]);
  let history = useHistory();

  useEffect(() => {
    if (apiOn) {
      getAuthFromToken();
    }
  }, [apiOn]);

  function retry() {
    console.log("calling Retry", apiOn);
    setApiOn(true);
  }

  function getAuthFromToken() {
    setLoading(true);
    authService
      .getAuthFromToken()
      .then((rAuthInfo) => {
        if (rAuthInfo) {
          dispatch(setAuthInfo(rAuthInfo.usuario));
          if (rAuthInfo.usuario?._id) {
            mixpanelIdentify(rAuthInfo?.usuario);
          }
          setApiOn(true);
        }
      })
      .catch((e) => {
        if(e.status === 503){
          setApiOn(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function logout(pMessage, pCode) {
    clearLocalStorage();
    dispatch(clearAllReducers());
    dispatch(setAuthInfo({}));
    mixpanel.reset();
    history.push("/login");

    if (pMessage) {
      showToast.error(pMessage, pCode);
    }
  }
  return (
    <>
      <InjectAxiosInterceptors logout={logout} />
      <Header logout={logout} />
      {/* <ModalPlanos /> */}
      {!apiOn ? (
        <ServerUnavailable retry={retry} loading={loading} />
      ) : loading || firstLoading ? (
        <div
          style={{
            display: "flex",
            height: 600,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TCLoading size={40} />
        </div>
      ) : (
        <>
          <Route path="/" render={(props) => pvRedirectIfAuth(user)} exact />
          <Route path="/login" component={Login} />
          {/* <Route path="/register" component={Register} /> */}
          <Route path="/register/:token" component={RegisterWithInvite} />
          <Route path="/pricing" component={Pricing} />
          <Route
            path="/confirm_account/:confirmToken"
            component={ConfirmAccount}
          />
          <Route path="/forgot_password/:token" component={PasswordReset} />

          <Route path="/register_company" component={RegisterCompany} />
          <PrivateRoute path="/chat" component={Chat} user={user} />
          <PrivateRoute path="/forum" component={Forum} user={user} />
          <PrivateRoute path="/payment" component={Payment} user={user} />
          <PrivateRoute path="/feed/" component={Feed} user={user} />
          <PrivateRoute
            path="/quick_messages_templates"
            component={QuickMessagesTemplates}
            user={user}
          />
          <PrivateRoute
            path="/quick_messages_config"
            component={QuickMessagesConfig}
            user={user}
          />

          <PrivateRoute path="/feedback/" component={Feedback} user={user} />
          <PrivateRoute
            path="/cadastrar_oferta/"
            component={CadastrarOferta}
            user={user}
          />

          <PrivateRoute
            path="/profile/:userId"
            component={Profile}
            user={user}
          />
          <PrivateRoute
            path="/company/:companyId"
            component={Company}
            user={user}
          />
          <PrivateRoute
            path="/admin_panel"
            component={AdminPanel}
            user={user}
          />
        </>
      )}
      <Route
        path="/waiting_confirm"
        component={WaitingCompanyConfirm}
        user={user}
      />
    </>
  );
}

function PrivateRoute({ component: Component, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => pvRenderRoute(props, Component, user)}
    />
  );
}

function pvRenderRoute(props, Component, user) {
  if (!isAuthenticated()) {
    return <Redirect to={{ pathName: "/", state: { from: props.location } }} />;
  }

  if (!user?._id) {
    //just wait for the user info to get in
    return <></>;
  }
  if (user?.type !== "A" && !user?.empresa) {
    // if (user.empresa === null) {
    //   showToast.info("Sua empresa foi recusada/deletada");
    // }
    showToast.info("Cadastre uma empresa para continuar");
    return <Redirect to="/register_company" />;
  }
  if (user?.type !== "A" && !user?.empresa?.confirmed) {
    return <Redirect to="/waiting_confirm" />;
  }
  return (
    <MainAnimation>
      <Component {...props} />
    </MainAnimation>
  );
}

function pvRedirectIfAuth(user) {
  if (!user?._id) {
    return <Redirect to="/login" />;
  }
  return <Redirect to="/feed" />;
}

let RoutesWrapped = () => {
  const [socket, setSocket] = useState();

  return (
    <ChatContext.Provider value={{ socket, setSocket }}>
      <HashRouter>
        <Routes />
      </HashRouter>
    </ChatContext.Provider>
  );
};

export default RoutesWrapped;
