
export default function buildOfertaCab(pOferta, cotacao) {
  return `${pOferta.modo == "C" ? "COMPRA" : "VENDA"} de ${
    pOferta.especie ? pOferta.especie : ``
  } ${pOferta.fava ? pOferta.fava : ``} ${
    pOferta.processamento ? pOferta.processamento : ``
  } ${pOferta.tipoCafe ? `Tipo ${pOferta.tipoCafe}` : ""} ${
    pOferta.bebidaBase ? `bebida ${pOferta.bebidaBase}` : ``
  } ${
    pOferta.catacao ? `com ${pOferta.catacao}% de catação` : ""
  } 
  `;

}

