import axios from "axios";
import chatService from "@services/chatService";
import ofertaService from "@services/ofertaService";
import cotacaoService from "@services/cotacaoService";
import forumService from "@services/forumService";
import paymentService from "@services/paymentService";

function firstLoadService(pUser, pFiltros) {
  return axios.all([
    chatService.getChats(),
    chatService.getUnseenMessages(),
    ofertaService.getOfertas(pFiltros),
    ofertaService.getMinhasOfertas(pUser),
    cotacaoService.getCotacao(),
    forumService.getPosts(),
    cotacaoService.getVencimentos(),
    paymentService.getPlanos().catch(e=> console.log('e',e))
  ]);
}

export default firstLoadService;
