import { MentionsInput, Mention } from "react-mentions";
import { useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  CssBaseline,
  IconButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { DARK, GREEN, SUB_GREEN } from "../../utils/themes";
import TCButton from "../TCButton";
import OBJETO_CAMPOS from "../../utils/data/formFields";
import { useSelector } from "react-redux";
import { grey } from "@material-ui/core/colors";
import { Clear } from "@material-ui/icons";
import { useEffect } from "react";
import axios from "axios";
import QuickMessageCopy from "@components/QuickMessages/QuickMessageCopy";
import formatDate from "@utils/formatDate";
const neverMatchingRegex = /($a)/;
let ADICIONAL = {
  id: "preco",
  display: "Preço",
};
let data = Object.values(OBJETO_CAMPOS).map((obj) => ({ ...obj, id: obj.key }));
data.push(ADICIONAL);

let campos = Object.values(OBJETO_CAMPOS).filter(
  (x) => x.tipoDeInput !== "PORCENTAGEM"
);
let SHOW_ATTRIBUTES_VALUES = campos
  .filter((campo) => campo.quickMessageShowColumn)
  .map((campo) => campo.key);

let GROUP_BY_ATTRIBUTES_VALUES = campos
  .filter((campo) => campo?.quickMessagegroupBy)
  .map((campo) => campo.key);

let MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  MenuListProps: {
    style: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
  },
};

let firstMessage = `${formatDate(new Date())} \n\n Ofertas:`;

let secondMessage = `Esses valores podem sofrer alterações devido às flutuações nos mercados de bolsas e câmbio. Recomenda-se confirmar esses preços antes de firmar qualquer contrato.`;

function QuickMessageInput({ onAdd, mensagemRapida, onClose }) {
  const [nome, setNome] = useState(mensagemRapida?.nome || "");
  const [state, setState] = useState(mensagemRapida?.value || firstMessage);
  const [stateFooter, setStateFooter] = useState(
    mensagemRapida?.valueFooter || secondMessage
  );
  const [attributesGroupBy, setattributesGroupBy] = useState(
    mensagemRapida.attributesGroupBy || []
  );
  const [attributesToShow, setAttributesToShow] = useState(
    mensagemRapida.attributesToShow || []
  );
  const [minhasOfertas] = useSelector((states) => [
    states.ofertaReducer.minhasOfertas,
  ]);
  const [emojis, setEmojis] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://gist.githubusercontent.com/oliveratgithub/0bf11a9aff0d6da7b46f1490f86a71eb/raw/d8e4b78cfe66862cf3809443c1dba017f37b61db/emojis.json"
      )
      .then((response) => {
        setEmojis(response.data.emojis);
      })
      .catch((e) => setEmojis([]));
  }, []);

  const queryEmojis = (query, callback) => {
    if (query.length === 0) return;

    const matches = emojis
      .filter((emoji) => {
        return emoji.name.indexOf(query.toLowerCase()) > -1;
      })
      .slice(0, 10);
    return matches.map(({ emoji }) => ({ id: emoji }));
  };

  const [theme] = useSelector((states) => [states.authReducer.theme]);
  const defaultStyle = {
    control: {
      backgroundColor: theme === DARK ? grey[850] : "#f2f2f2",
      fontSize: 14,
      fontWeight: "normal",
    },

    "&multiLine": {
      control: {
        minHeight: 63,
      },
      highlighter: {
        padding: 9,
        border: "1px solid transparent",
      },
      input: {
        padding: 9,
        border: "1px solid white",
        borderRadius: 2,
        color: theme !== DARK ? grey[900] : "#f2f2f2",
        whiteSpace: "pre-wrap",
      },
    },

    "&singleLine": {
      display: "inline-block",
      width: 180,

      highlighter: {
        padding: 1,
        border: "2px inset transparent",
      },
      input: {
        padding: 1,
        border: "2px inset",
      },
    },

    suggestions: {
      list: {
        backgroundColor: "white",
        color: "black",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 14,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: GREEN,
          color: "white",
        },
      },
    },
  };

  const onChange = (e, newValue, newPlainTextValue, mentions) => {
    return setState(e.target.value);
  };

  const onChangeFooter = (e, newValue, newPlainTextValue, mentions) => {
    return setStateFooter(e.target.value);
  };

  return (
    <Paper
      style={{
        width: "100%",
        padding: 20,
        margin: "30px auto",
        position: "relative",
        whiteSpace: "pre-wrap",
      }}
    >
      <TextField
        value={nome}
        onChange={(e) => setNome(e.target.value)}
        placeholder="Nome da Divulgação"
        style={{ marginBottom: 24 }}
        autoFocus
      ></TextField>
      <IconButton
        style={{ position: "absolute", top: 8, right: 8 }}
        onClick={onClose}
      >
        <Clear />
      </IconButton>
      <Typography variant="subtitle2" style={{ marginTop: 24 }}>
        Cabeçalho:
      </Typography>

      <MentionsInput
        value={state}
        onChange={onChange}
        style={defaultStyle}
        placeholder={`Digite uma mensagem introdutoria \n`}
      >
        <Mention
          trigger=":"
          markup="__id__"
          regex={neverMatchingRegex}
          data={queryEmojis}
        />
      </MentionsInput>

      <Typography variant="subtitle1" style={{ marginTop: 24 }}>
        Configurações Lista de Ofertas:
      </Typography>
      <div style={{backgroundColor:theme === 'DARK'?grey[800]:grey[200]}}>
        <Typography variant="subtitle2" style={{ marginTop: 24 }}>
          Agrupar por
        </Typography>
        <Select
          value={attributesGroupBy}
          style={{ width: `100%` }}
          multiple
          onChange={(e) => setattributesGroupBy(e.target.value)}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={OBJETO_CAMPOS[value].display}
                  style={{ zIndex: 500 }}
                />
              ))}
            </Box>
          )}
          MenuProps={MENU_PROPS}
        >
          {GROUP_BY_ATTRIBUTES_VALUES?.map((value) => (
            <MenuItem value={value} style={{ width: 200 }}>
              <Checkbox checked={attributesGroupBy?.indexOf(value) > -1} />
              <ListItemText primary={OBJETO_CAMPOS[value].display} />
            </MenuItem>
          ))}
        </Select>

        <Typography variant="subtitle2" style={{ marginTop: 24 }}>
          Mostrar Colunas
        </Typography>
        <Select
          value={attributesToShow}
          MenuProps={MENU_PROPS}
          multiple
          style={{ width: `100%` }}
          onChange={(e) => setAttributesToShow(e.target.value)}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => {
                return (
                  <Chip
                    key={value}
                    label={OBJETO_CAMPOS[value].display}
                    style={{ zIndex: 500 }}
                  />
                );
              })}
            </Box>
          )}
        >
          {SHOW_ATTRIBUTES_VALUES?.map((value) => (
            <MenuItem value={value} style={{ width: 200 }}>
              <Checkbox checked={attributesToShow?.indexOf(value) > -1} />
              <ListItemText primary={OBJETO_CAMPOS[value].display} />
            </MenuItem>
          ))}
        </Select>
      </div>
      <Typography variant="subtitle2" style={{ marginTop: 24 }}>
        Rodapé:
      </Typography>

      <MentionsInput
        value={stateFooter}
        onChange={onChangeFooter}
        style={defaultStyle}
        placeholder={`Digite um Rodapé \n`}
      >
        <Mention
          trigger=":"
          markup="__id__"
          regex={neverMatchingRegex}
          data={queryEmojis}
        />
      </MentionsInput>
      <Typography
        variant="h6"
        align="center"
        style={{ marginTop: 24, marginBottom: 24 }}
      >
        Prévia:{" "}
      </Typography>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <QuickMessageCopy
          ofertas={minhasOfertas}
          quickMessage={{
            nome: nome,
            value: state,
            valueFooter: stateFooter,
            attributesToShow,
            attributesGroupBy,
          }}
        />
      </div>
      <CssBaseline />
      <div style={{ display: "flex", justifyContent: "center", marginTop: 12 }}>
        <TCButton
          onClick={() =>
            onAdd({
              ...mensagemRapida,
              nome,
              value: state,
              valueFooter: stateFooter,
              attributesGroupBy,
              attributesToShow,
            })
          }
        >
          Salvar
        </TCButton>
      </div>
    </Paper>
  );
}

export default QuickMessageInput;
