import { DARK } from "@utils/themes";

const INITIAL_STATE = {
  user: {},
  theme: DARK,
  newCompaniesCount: 0,
  firstLoading: false,
  modalPlanosOpen: false,
  planos: {},
  isConnectedInvestingSocket: false,
};

const SET_PLANOS = "AUTH_REDUCER/SET_PLANOS";
const SET_AUTH_INFO = "AUTH_REDUCER/SET_AUTH_INFO";
const SET_DIFS = "AUTH_REDUCER/SET_DIFS";
const SET_AUTH_INFO_COMPANY = "AUTH_REDUCER/SET_AUTH_INFO_COMPANY";
const SET_MODAL_PLANOS_OPEN = "AUTH_REDUCER/SET_MODAL_PLANOS_OPEN";
const SET_THEME = "AUTH_REDUCER/SET_THEME";
const SET_NEW_COMPANIES_COUNT = "AUTH_REDUCER/SET_NEW_COMPANIES_COUNT";
const REDUCE_NEW_COMPANIES_COUNT = "AUTH_REDUCER/REDUCE_NEW_COMPANIES_COUNT";
const SET_FIRST_LOADING = "AUTH_REDUCER/SET_FIRST_LOADING";
const SET_IS_CONNECTED_INVESTING_SOCKET =
  "AUTH_REDUCER/SET_IS_CONNECTED_INVESTING_SOCKET";

export function setPlanos(pPlanos) {
  return { type: SET_PLANOS, payload: pPlanos || {} };
}

export function setDifs(pDifs) {
  return { type: SET_DIFS, payload: pDifs };
}

export function setAuthInfo(pAuthInfo) {
  return { type: SET_AUTH_INFO, payload: pAuthInfo };
}
export function setAuthInfoCompany(pAuthInfoCompany) {
  return { type: SET_AUTH_INFO_COMPANY, payload: pAuthInfoCompany };
}
export function setModalPLanosOpen(payload) {
  return { type: SET_MODAL_PLANOS_OPEN, payload };
}
export function setTheme(pTheme) {
  return { type: SET_THEME, payload: pTheme };
}

export function setNewCompaniesCount(pCount) {
  return { type: SET_NEW_COMPANIES_COUNT, payload: pCount };
}
export function reduceNewCompaniesCount() {
  return { type: REDUCE_NEW_COMPANIES_COUNT };
}
export function setFirstLoading(payload) {
  return { type: SET_FIRST_LOADING, payload };
}

export function setIsConnectedInvestingSocket(payload) {
  return {
    type: SET_IS_CONNECTED_INVESTING_SOCKET,
    payload,
  };
}
export default function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_PLANOS:
      return { ...state, planos: action.payload };
    case SET_AUTH_INFO:
      return { ...state, user: action.payload };
    case SET_AUTH_INFO_COMPANY:
      return {
        ...state,
        user: {
          ...state.user,
          empresa: action.payload,
        },
      };
    case SET_THEME:
      return { ...state, theme: action.payload };
    case SET_NEW_COMPANIES_COUNT:
      return { ...state, newCompaniesCount: action.payload };

    case REDUCE_NEW_COMPANIES_COUNT:
      return {
        ...state,
        newCompaniesCount: state.newCompaniesCount - 1,
      };
    case SET_FIRST_LOADING:
      return {
        ...state,
        firstLoading: action.payload,
      };
    case SET_MODAL_PLANOS_OPEN:
      return {
        ...state,
        modalPlanosOpen: action.payload,
      };
    case SET_IS_CONNECTED_INVESTING_SOCKET:
      return {
        ...state,
        isConnectedInvestingSocket: action.payload,
      };
    case SET_DIFS: {
      return {
        ...state,
        user: { ...state.user, difs: action.payload },
      };
    }
    default:
      return state;
  }
}
