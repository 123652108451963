import React, { useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { MINHAS_OFERTAS_TABELA_HEADERS_APPEARING } from "@utils/data/tabelaHeaders";
import TableRow from "@material-ui/core/TableRow";
import {
  Cached,
  DeleteOutlineRounded,
  Clear,
  AcUnit,
  Check,
  Edit,
} from "@material-ui/icons";
import CVBadge from "../components/CVBadge";
import Td from "../components/Td";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { green, orange } from "@material-ui/core/colors";
import EditableNumberTd from "../components/EditableNumberTd";
import showPreco from "../../../utils/showPreco";
import TCLoading from "../../TCLoading";
import { getAtualizacao } from "../FeedTable/FeedTr";
import { ICE } from "../../../utils/themes";

const useStyles = makeStyles({
  icon: {
    cursor: "pointer",
  },
  icons: {
    fontSize: 18,
    cursor: "pointer",
  },
});

export const tdStyle = {
  display: "block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
export default function MinhasOfertasTr({
  oferta,
  openEditModalNew,
  cotacao,
  publicarOferta,
  deletarOferta,
  xIsPublished,
  loadingD,
  loadingP,
  loadingF,
  freezeOferta,
}) {
  const [theme, user] = useSelector((states) => [
    states.authReducer.theme,
    states.authReducer.user,
  ]);

  let isDinamico = !oferta.congelada;

  return (
    <TableRow>
      <Td>
        <CVBadge modo={oferta[`modo`]} />
      </Td>
      <Td component="th" scope="row">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <MOActions
            oferta={oferta}
            cotacao={cotacao}
            openEditModalNew={openEditModalNew}
            deletarOferta={deletarOferta}
            publicarOferta={publicarOferta}
            xIsPublished={xIsPublished}
            loadingD={loadingD}
            loadingP={loadingP}
            loadingF={loadingF}
            freezeOferta={freezeOferta}
          />
        </div>
      </Td>
      {Object.keys(MINHAS_OFERTAS_TABELA_HEADERS_APPEARING).map((key) => {
        if (
          MINHAS_OFERTAS_TABELA_HEADERS_APPEARING[key].tipoDeInput == "populate"
        ) {
          if (oferta[key] && MINHAS_OFERTAS_TABELA_HEADERS_APPEARING[key]) {
            return (
              <Td key={key}>
                {oferta[key][MINHAS_OFERTAS_TABELA_HEADERS_APPEARING[key].key]}
              </Td>
            );
          }
        }

        if (
          MINHAS_OFERTAS_TABELA_HEADERS_APPEARING[key].tipoDeInput == "PRECO"
        ) {
          return (
            <EditableNumberTd
              key={key}
              number={showPreco(oferta, cotacao)}
              oferta={oferta}
              theme={theme}
              cotacao={cotacao}
              isEditable={!oferta.diferencial}
              isCongelado={!isDinamico}
              isDinamico={isDinamico}
              isPreco
            />
          );
        }

        return (
          <Td key={key}>
            <div style={tdStyle}>
              {oferta[key] === 0
                ? "X"
                : oferta[key] === -1
                ? "UP"
                : oferta[key]
                ? `${oferta[key]}${
                    MINHAS_OFERTAS_TABELA_HEADERS_APPEARING[key].tipoDeInput ===
                    "PORCENTAGEM"
                      ? "%"
                      : ""
                  }`
                : ""}
            </div>
          </Td>
        );
      })}

      {oferta.diferencial ? (
        <EditableNumberTd
          theme={theme}
          oferta={oferta}
          number={oferta.diferencial}
          cotacao={cotacao}
          isEditable
          isCongelado={!isDinamico}
          isDinamico={isDinamico}
        />
      ) : (
        <Td></Td>
      )}
      <Td>
        {oferta["empresa"]?.nomeFantasia || user["empresa"]?.nomeFantasia || ""}
      </Td>
      <Td>{oferta["usuario"]?.name || user.name || ""}</Td>
      <Td>{cotacao?.vencimentos[oferta["vencimento"]]?.contractSymbol}</Td>
      <Td>{getAtualizacao(oferta, cotacao, isDinamico)}</Td>

      {/* <Td>{formatDate(oferta["createdAt"])}</Td> */}
    </TableRow>
  );
}

export function MOActions({
  oferta,
  cotacao,
  openEditModal,
  openEditModalNew,
  deletarOferta,
  publicarOferta,
  xIsPublished,
  loadingD,
  loadingP,
  loadingF,
  freezeOferta,
}) {
  const classes = useStyles();
  let [isDeleting, setIsDeleting] = useState(false);
  let isCongelado = oferta?.congelada;

  if (isDeleting) {
    return (
      <div style={{ display: "flex", gap: 8, width: 90 }}>
        <Typography variant="cation">Deletar?</Typography>
        <div
          style={{ display: "flex", justifyContent: "space-between", gap: 4 }}
        >
          <Check
            onClick={() => deletarOferta(oferta?._id)}
            className={classes.icons}
          />

          <Clear
            className={classes.icons}
            onClick={() => setIsDeleting(false)}
          />
        </div>
      </div>
    );
  }
  return (
    <>
      {/* <Tooltip title="Editar">
        <Edit
          className={classes.icon}
          fontSize="small"
          onClick={() => {
            openEditModal(oferta._id);
          }}
        />
      </Tooltip> */}
      <div style={{ display: "flex", gap: 4, width: 90 }}>
        {/* <Tooltip title="Gerar Mensagem">
            <WhatsApp
              className={classes.icon}
              style={{ marginRight:8 }}
              fontSize="small"
              onClick={() => {
                setShowQuickMessageModal(oferta._id);
              }}
            />
          </Tooltip> */}
        <Tooltip title="Editar">
          <Edit
            className={classes.icon}
            fontSize="small"
            onClick={() => {
              openEditModalNew(oferta._id);
            }}
          />
        </Tooltip>

        {loadingD === oferta._id ? (
          <TCLoading className={classes.icon} />
        ) : (
          <Tooltip title="Deletar">
            <DeleteOutlineRounded
              className={classes.icon}
              color="error"
              fontSize="small"
              onClick={() => {
                setIsDeleting(true);
              }}
            />
          </Tooltip>
        )}
        {loadingP === oferta._id ? (
          <TCLoading />
        ) : (
          <Tooltip
            title={xIsPublished ? "Remover do feed" : "Publicar no feed"}
          >
            {xIsPublished ? (
              <Clear
                className={classes.icon}
                fontSize="small"
                onClick={() => {
                  publicarOferta(oferta._id, !xIsPublished);
                }}
                style={{ color: "red" }}
              />
            ) : (
              <Cached
                className={classes.icon}
                fontSize="small"
                onClick={() => {
                  publicarOferta(oferta._id, !xIsPublished);
                }}
                style={{ color: green[700] }}
              />
            )}
          </Tooltip>
        )}
        <div style={{ width: 24 }}>
          {!oferta.diferencial ? null : loadingF === oferta._id ? (
            <TCLoading />
          ) : (
            <Tooltip title={isCongelado ? "Descongelar" : "Congelar"}>
              <AcUnit
                className={classes.icon}
                fontSize="small"
                onClick={() => {
                  freezeOferta(
                    oferta._id,
                    showPreco(oferta, cotacao),
                    !isCongelado
                  );
                }}
                style={{ color: isCongelado ? orange[800] : undefined }}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </>
  );
}
