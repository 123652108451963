import { StyledP } from "./DetailOffer";
import React from "react";

export default function TypographyLineBreak({ texto, white, ...props }) {
  // Split the input text into lines
  const lines = texto?.split(/\r?\n/).reduce((acc, line, index) => {
    const trimmedLine = line.trim();

    // Add the line to the accumulator
    acc.push(<span key={`${index}-line`}>{line}</span>);

    // If the current line is empty, add a break for each empty line
    if (trimmedLine === "") {
      acc.push(<br key={`${index}-break`} />);
    } else {
      // For non-empty lines, add a single break
      acc.push(<br key={`${index}-break`} />);
    }

    return acc;
  }, []);

  return (
    <StyledP {...props} head={white}>
      {lines}
    </StyledP>
  );
}
