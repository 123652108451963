export const setThemeLocalStorage = (pTheme) =>
  localStorage.setItem("theme", pTheme);

export const getThemeLocalStorage = () => {
  const xTheme = localStorage.getItem("theme");
  if (!xTheme) {
    return undefined;
  }
  return xTheme;
};
