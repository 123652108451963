import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { addMinhasOfertas } from "@redux/ofertaReducer";
import ofertaService from "@services/ofertaService";
import OBJETO_CAMPOS from "@utils/data/formFields";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  makeStyles,
  TextField,
  Typography,
  Modal,
  IconButton,
  Button,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import adminService from "../services/adminService";
import {
  calcularDiferencial,
  calcularDiferencialLondres,
  calcularPrecoArabicaReal,
  calcularPrecoRobustaReal,
} from "../utils/cotacao";
import showToast from "./Toast";
import ImageUploader from "./ImageUploader";
import MUNICIPIOS from "../utils/data/municipios";

const useStyles = (pTheme) =>
  makeStyles((theme) => {
    return {
      formControl: {
        margin: 2,
        minWidth: 180,
        maxWidth: 200,
      },
      inputLabel: {
        fontSize: 14,
      },
      inpt: {
        maxWidth: 210,
        minWidth: 190,
        margin: "2px 0px",
      },
      inptHide: {
        display: "none",
      },
      modal: {
        top: 62,
        left: 80,
        right: 80,
        bottom: 62,
        padding: 30,
        backgroundColor: pTheme !== "DARK" ? "white" : grey[800],
        position: "absolute",
        borderRadius: 4,
        [theme.breakpoints.down("md")]: {
          top: 12,
          left: 12,
          right: 12,
          bottom: 12,
          padding: 10,
          maxHeight: "200%",
          overflowY: "scroll",
        },
      },
      insideModal: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flexWrap: "wrap",
        [theme.breakpoints.down("md")]: {
          // maxHeight: 600,

          flexWrap: "nowrap",
          alignItems: "center",
        },
      },
      lastClass: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      },
    };
  });

const requiredFields = [
  "especie",
  "processamento",
  "anoSafra",
  "bebidaBase",
  "fava",
  "aspecto",
  "cor",
  "padrao",
];

function getSchema(pIsPreco) {
  return yup.object().shape({
    especie: yup.string().required(),
    processamento: yup.string().required(),
    anoSafra: yup.string().required(),
    bebidaBase: yup.string().required(),
    fava: yup.string().required(),
    aspecto: yup.string().required(),
    cor: yup.string().required(),
    padrao: yup.string().required(),
    vencimento: pIsPreco ? undefined : yup.string().required(),
  });
}

export default function ModalCadastrarOferta({ showModal, handleClose }) {
  if (!showModal) {
    return <></>;
  }
  return <ModalCad handleClose={handleClose} showModal={showModal} />;
}

function ModalCad({ showModal, handleClose }) {
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const [isPreco, setIsPreco] = useState(true);
  const [errorPreco, setErrorPreco] = useState(false);
  const [errorDif, setErrorDif] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const [preco, setPreco] = useState("");
  const [dif, setDif] = useState("");
  const [user, theme, minhasOfertas, selectedOfertaId, cotacao] = useSelector(
    (states) => [
      states.authReducer.user,
      states.authReducer.theme,
      states.ofertaReducer.minhasOfertas,
      states.ofertaReducer.selectedOfertaId,
      states.cotacaoReducer,
    ]
  );
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const classes = useStyles(theme)();

  const defaultValues = selectedOfertaId
    ? {
        ...minhasOfertas[selectedOfertaId],
        vencimento: minhasOfertas[selectedOfertaId]?.vencimento,
      }
    : { modo: "C" };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(getSchema(isPreco)) });

  const isAdmin = user.type === "A";
  const isDif = !isPreco;

  useEffect(() => {
    if (selectedOfertaId) {
      const xPreco = minhasOfertas[selectedOfertaId].preco;
      if (xPreco) {
        setIsPreco(true);
        setPreco(xPreco);
      }
      const xDif = minhasOfertas[selectedOfertaId].diferencial;
      if (xDif) {
        setIsPreco(false);
        setDif(xDif);
      }
    }

    if (isAdmin) {
      adminService
        .getFakeCompanies()
        .then((rCompanies) => setCompanies(rCompanies))
        .catch((e) => console.log("e", e));
    }
  }, []);

  function renderInput() {
    return Object.keys(OBJETO_CAMPOS).map((key) => {
      const currentInput = OBJETO_CAMPOS[key];
      if (currentInput.tipoDeInput == "INPUT") {
        return (
          <TextField
            key={key}
            // variant="outlined"
            className={[classes.inpt, classes.inputLabel]}
            size="small"
            label={currentInput.display}
            InputLabelProps={{ className: classes.inputLabel }}
            error={errors[key]}
            {...register(key)}
          />
        );
      }
      const xIsPercentage = currentInput.tipoDeInput == "PORCENTAGEM";
      if (currentInput.tipoDeInput == "AUTOCOMPLETE") {
        return (
          <Controller
            name={key}
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...defaultProps}
                className={classes.inpt}
                defaultValue={{ Nome: watch(key) }}
                onChange={(e, data) => {
                  return field.onChange(data?.Nome || "");
                }}
                renderInput={(params) => (
                  <TextField {...params} label={currentInput.display} />
                )}
              />
            )}
          />
        );
      }
      if (currentInput.tipoDeInput == "SELECT" || xIsPercentage) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FormControl className={classes.formControl} error={errors[key]}>
              <InputLabel className={classes.inputLabel}>
                {currentInput.display}
              </InputLabel>

              <Select {...register(key)} value={watch(key)}>
                {key !== "modo" ? (
                  <MenuItem
                    value={""}
                  >{`Sem ${currentInput.display}`}</MenuItem>
                ) : (
                  <div />
                )}
                {xIsPercentage ? <MenuItem value={0}>X</MenuItem> : <div />}
                {xIsPercentage ? <MenuItem value={-1}>UP</MenuItem> : <div />}
                {getFromArrayOrObject(
                  currentInput.valores,
                  currentInput.tipoDeInput == "PORCENTAGEM"
                )}
              </Select>
            </FormControl>
            {requiredFields.includes(key) ? (
              <Tooltip title="Campo obrigatorio">
                <Typography
                  variant="h4"
                  style={{ color: errors[key] ? "#f44336" : "" }}
                >
                  *
                </Typography>
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
        );
      }

      return <p>input otrop</p>;
    });
  }

  function addOrEditOferta(pOferta) {
    if (selectedOfertaId) {
      return ofertaService.editOferta(pOferta).then((rOferta) => {
        dispatch(addMinhasOfertas(rOferta));
        handleClose();
        return rOferta;
      });
    }

    if (isAdmin) {
      return adminService.addOferta(pOferta).then((rOferta) => {
        dispatch(addMinhasOfertas(rOferta));
        return rOferta;
      });
    }
    return ofertaService.addOferta(pOferta).then((rOferta) => {
      dispatch(addMinhasOfertas(rOferta));

      return rOferta;
    });
  }

  function submitOffer(pOferta) {
    if (isPreco) {
      //   if (preco) {
      //     return setErrorPreco(true);
      //   }
      pOferta.preco = preco;
    } else {
      if (!dif) {
        return setErrorDif(true);
      } else {
        setErrorDif(false);
      }
      pOferta.diferencial = dif;
    }

    let xOferta = {};

    //Save only the ones with actual values
    Object.keys(pOferta).map((rKey) => {
      if (rKey === "preco" && !xOferta[rKey]) {
        xOferta[rKey] = null;
      }
      if (rKey === "diferencial" && !xOferta[rKey]) {
        xOferta[rKey] = null;
      }
      if (rKey === "vencimento" && !xOferta[rKey]) {
        xOferta[rKey] = null;
      }

      if (pOferta[rKey] !== undefined && pOferta[rKey] !== null)
        xOferta[rKey] = pOferta[rKey];
    });
    setLoading(true);
    return addOrEditOferta(xOferta)
      .then((rOferta) => {
        showToast.success(
          `Oferta ${selectedOfertaId ? "editada" : "cadastrada"}`
        );
        if (preview) {
          return uploadFile(selectedFile, rOferta).then(() => {
            return rOferta;
          });
        }
        return rOferta;
      })
      .catch((e) => {
        showToast.error(
          `Erro ${selectedOfertaId ? "editando" : "cadastrando"} oferta`
        );
      })
      .finally(() => setLoading(false));
  }

  function handleCheckbox(pType) {
    if (pType === "PRECO") {
      setIsPreco(true);
      setDif("");
      setErrorDif(false);
      setValue("diferencial", "");
      setValue("vencimento", null);
      return;
    }
    setIsPreco(false);
    setErrorPreco(false);
    setPreco("");
    setValue("preco", "");
  }

  function uploadFile(pFile, oferta = null) {
    const formData = new FormData();
    formData.append("file", pFile);
    setLoadingPhoto(true);

    return ofertaService
      .uploadOfertaImg(oferta ? oferta._id : selectedOfertaId, formData)
      .then((rOferta) => {
        dispatch(addMinhasOfertas(rOferta));
        setValue("img", rOferta.img);
      })
      .catch((err) => showToast.error(err.message))
      .finally(() => setLoadingPhoto(false));
  }

  function handleUpload(e) {
    uploadFile(e.target.files[0]);
  }

  function handleDelete() {
    setLoadingPhoto(true);
    ofertaService
      .deleteImg(selectedOfertaId)
      .finally(() => {
        setValue("img", null);
      })
      .catch((e) => {
        console.log(e);
        showToast.error(e.message);
      })
      .finally(() => setLoadingPhoto(false));
  }

  useEffect(() => {
    // create the preview
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  const handlePrepareUpload = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  const xVencimento = watch("vencimento");
  const xEspecie = watch("especie");

  function renderVencimentos(especie) {
    if (!especie) {
      return (
        <InputLabel
          className={classes.inputLabel}
        >{`Escolha uma especie para selecionar um vencimento`}</InputLabel>
      );
    }

    return (
      <FormControl
        error={errors["vencimento"]}
        className={classes.formControl}
        style={{ marginTop: 0 }}
      >
        <InputLabel className={classes.inputLabel}>{`Vencimento ${
          especie === "Arabica" ? "KC" : "RC"
        }`}</InputLabel>
        <Select {...register("vencimento")} value={xVencimento}>
          {Object.values(cotacao.vencimentos).map((rVenc) => {
            if (especie === "Arabica" && rVenc.preSymbol === "KC")
              return (
                <MenuItem value={rVenc._id}>{rVenc.contractSymbol}</MenuItem>
              );

            if (especie === "R" && rVenc.preSymbol === "RM") {
              return (
                <MenuItem value={rVenc._id}>{rVenc.contractSymbol}</MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
    );
  }

  const defaultProps = {
    options: MUNICIPIOS,
    getOptionLabel: (option) => option.Nome,
  };

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modal}>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">{`${
            selectedOfertaId ? "Editar" : "Adicionar"
          } oferta`}</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        {loading ? (
          <p>Loading</p>
        ) : (
          <form
            className={classes.insideModal}
            onSubmit={handleSubmit(submitOffer)}
          >
            <>
              {companies.length ? (
                <FormControl
                  className={classes.formControl}
                  error={errors["vencimentosKC"]}
                >
                  <InputLabel className={classes.inputLabel}>
                    Empresa
                  </InputLabel>
                  <Select value={watch("empresa")} {...register("empresa")}>
                    {companies.map((rCompany) => (
                      <MenuItem value={rCompany._id}>
                        {rCompany.nomeFantasia}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}

              {isAdmin && companies?.length === 0 && (
                <p>Sem empresas falsas,crie uma</p>
              )}

              {renderInput()}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ paddingTop: 12, display: "flex" }}>
                  <ResumoCotacao>
                    <Typography variant="body1">Dollar</Typography>
                    <Typography variant="body2">{cotacao.dollar}</Typography>
                  </ResumoCotacao>
                  <ResumoCotacao>
                    <Typography variant="body1">NY</Typography>
                    <Typography variant="body2">{cotacao.precoNY}</Typography>
                  </ResumoCotacao>
                  <ResumoCotacao>
                    <Typography variant="body1">LD</Typography>
                    <Typography variant="body2">
                      {cotacao.precoLondres}
                    </Typography>
                  </ResumoCotacao>
                </div>
                <Typography variant="body1" style={{ marginTop: 20 }}>
                  Informe preco ou diferencial:
                </Typography>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={isPreco}
                      onClick={(e) => handleCheckbox("PRECO")}
                    />

                    <Typography variant="inherit">Preco</Typography>
                    <TextField
                      variant="outlined"
                      className={isDif ? classes.inptHide : classes.inpt}
                      placeholder="Preco em R$"
                      value={preco}
                      error={errorPreco}
                      onChange={(e) => {
                        setValue("preco", e.target.value);
                        setValue("diferencial", "");
                        setPreco(e.target.value);
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={isDif}
                      onClick={() => handleCheckbox("DIF")}
                    />
                    <Typography variant="inherit" style={{ marginRight: 12 }}>
                      Diferencial
                    </Typography>
                    {isDif && renderVencimentos(xEspecie)}
                  </div>
                  <TextField
                    variant="outlined"
                    className={isPreco ? classes.inptHide : classes.inpt}
                    placeholder={`Diferencial em ${
                      xEspecie === "Arabica" ? "cts/lb" : "US$/MT"
                    }`}
                    error={errorDif}
                    onChange={(e) => {
                      setValue("diferencial", e.target.value);
                      setValue("preco", "");
                      setDif(e.target.value);
                    }}
                    value={dif}
                  />

                  {isPreco && (
                    <ResumePreco
                      preco={preco}
                      cotacao={cotacao}
                      especie={xEspecie}
                    />
                  )}

                  {isDif && (
                    <ResumeDiferencial
                      dif={dif}
                      cotacao={cotacao}
                      vencimento={cotacao.vencimentos?.[xVencimento]}
                      especie={xEspecie}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <TextField
                    variant="outlined"
                    multiline
                    style={{ marginBottom: 12, marginRight: 12, flex: 1 }}
                    placeholder="Observacoes"
                    minRows={4}
                    maxRows={6}
                    {...register("obs")}
                    value={watch("obs")}
                  />
                  <ImageUploader
                    src={selectedOfertaId ? watch("img") : preview}
                    handleUpload={
                      selectedOfertaId ? handleUpload : handlePrepareUpload
                    }
                    loading={loadingPhoto}
                    handleDelete={
                      selectedOfertaId ? handleDelete : () => setPreview(null)
                    }
                  />
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  color={watch("modo") === "C" ? "primary" : "secondary"}
                >
                  {selectedOfertaId ? "Editar " : "Cadastrar"}oferta
                </Button>
              </div>
            </>
          </form>
        )}
      </div>
    </Modal>
  );
}

function ResumoCotacao({ children }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        columnGap: 8,
        marginRight: 36,
      }}
    >
      {children}
    </div>
  );
}
// function getCurrentPrice(price, especie, pPriceLondres = 0) {
//   if (especie !== "R" && especie !== "A") return;
//   if (especie === "A") {
//     return `CafeNY com o diferencial AA cts/lb: ${price}`;
//   }
//   if (pPriceLondres) {
//     return `Cafe Londres com o diferencial RR US$/MT: ${pPriceLondres}`;
//   }
//   return `Cafe Londres com o diferencial RR US$/MT: ${price}`;
// }
function ResumePreco({ preco, cotacao, especie }) {
  if (!especie || !preco) return null;
  const xIsArabica = especie === "Arabica";
  return (
    <Typography variant="body1">{`Com o preco de R$ ${preco} e o dollar de ${
      cotacao.dollar
    } temos um diferencial de ${
      xIsArabica
        ? calcularDiferencial(preco, cotacao.precoNY, cotacao.dollar)
        : calcularDiferencialLondres(
            preco,
            cotacao.precoLondres,
            cotacao.dollar
          )
    } ${xIsArabica ? "cts/lb" : "US$/MT"}`}</Typography>
  );
}

function ResumeDiferencial({ dif, cotacao, vencimento, especie }) {
  if (!especie || !dif) return null;
  if (vencimento) {
    return (
      <Typography variant="body1">
        NAO IMPLEMENTADO COM VENCIMENTO KC/RC
      </Typography>
    );
  }
  const xIsArabica = especie === "Arabica";
  if (xIsArabica) {
    return (
      <div style={{ maxWidth: 400 }}>
        <Typography variant="body1">{`Com um diferencial de ${dif} cts/lb e dollar de R$ ${
          cotacao.dollar
        }, 1 saco de 60kg de ARABICA é igual a R$ ${calcularPrecoArabicaReal(
          dif,
          cotacao.precoNY,
          cotacao.dollar
        )}`}</Typography>
      </div>
    );
  }
  return (
    <div style={{ maxWidth: 400 }}>
      <Typography variant="body1">{`Com um diferencial de ${dif} US$/MT e dollar de R$ ${
        cotacao.dollar
      },1 saco de 60kg de ROBUSTA é igual a R$ ${calcularPrecoRobustaReal(
        dif,
        cotacao.precoLondres,
        cotacao.dollar
      )}`}</Typography>
    </div>
  );
}
// function ResumePrecoDif({ isPreco, preco, dif, cotacao, vencimento, especie }) {
//   return (
//     <div style={{ marginTop: 30 }}>
//       {vencimento ? (
//         <>
//           <Typography variant="body1" gutterBottom>
//             {getCurrentPrice(vencimento.lastPrice, especie)}
//           </Typography>
//           <Conclusion
//             isPreco={isPreco}
//             preco={preco}
//             dif={dif}
//             precoNY={vencimento.lastPrice}
//             dollar={cotacao.dollar}
//             especie={especie}
//           />
//         </>
//       ) : (
//         <>
//           <Typography variant="body1" gutterBottom>
//             {getCurrentPrice(cotacao.precoNY, especie, cotacao.precoLondres)}
//           </Typography>
//           <Conclusion
//             isPreco={isPreco}
//             preco={preco}
//             dif={dif}
//             precoNY={cotacao.precoNY}
//             dollar={cotacao.dollar}
//             especie={especie}
//           />
//         </>
//       )}
//     </div>
//   );
// }

// function Conclusion({ isPreco, preco, dif, precoNY, dollar, especie }) {
//   if (isPreco && preco) {
//     return (
//       <Typography variant="body1">
//         {`O seu diferencial é ${calcularDiferencial(preco, precoNY, dollar)}`}
//       </Typography>
//     );
//   }
//   if (dif) {
//     return (
//       <Typography variant="body1">
//         {`O seu preco é R$:${calcularPrecoReal(dif, precoNY, dollar, especie)}`}
//       </Typography>
//     );
//   }
//   return <div style={{ height: 24 }} />;
// }
export function getFromArrayOrObject(pArrayOrObject, isPercentage, onAdd) {
  if (_.isArray(pArrayOrObject)) {
    return pArrayOrObject.map((valor) => (
      <MenuItem
        value={valor}
        onClick={() => (onAdd ? onAdd(valor) : undefined)}
      >
        {valor}
        {isPercentage ? "%" : ""}
      </MenuItem>
    ));
  }

  return !pArrayOrObject
    ? []
    : Object.values(pArrayOrObject).map((rCampo) => (
        <MenuItem
          value={rCampo.value}
          onClick={() => (onAdd ? onAdd(rCampo.value) : undefined)}
        >
          {rCampo.label}
        </MenuItem>
      ));
}
