import React from "react";
import { Typography } from "@material-ui/core";
import MainAnimation from "../components/MainAnimation";
import { SignalCellularOff } from "@material-ui/icons";
import TCButton from "../components/TCButton";

export default function ServerUnavailable({ retry, loading }) {
  return (
    <MainAnimation>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 600,
          flexDirection: "column",
        }}
      >
        <SignalCellularOff style={{ fontSize: 50 }} />
        <Typography variant="h5" style={{ marginTop: 24, textAlign: "center" }}>
          Falha de Conexao com o Servidor
        </Typography>

        <Typography
          variant="h6"
          style={{ marginBottom: 24, textAlign: "center" }}
        >
          Tente novamente mais tarde.
        </Typography>
        <TCButton onClick={() => retry()} loading={loading}>
          Tentar Novamente
        </TCButton>
      </div>
    </MainAnimation>
  );
}
