import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core";
import { GREEN } from "../../../utils/themes";

const useStyles = (small) =>
  makeStyles({
    tableHeader: {
      paddingLeft: "8px",
      height:  32,
      fontWeight: "bold",
      width: small ? 50 : null,
      fontSize: 15,
      color: "#fff",
      backgroundColor: GREEN,
    },
  });

export default function Th({ small, size, children, align, padding }) {
  const classes = useStyles(small)();

  return (
    <TableCell
      padding={padding ? undefined : "none"}
      classes={{
        root: classes.tableHeader,
      }}
      align={align || "left"}
      size={size}
      variant="head"
    >
      {children}
    </TableCell>
  );
}
