import React, { memo, useEffect, useRef, useState } from "react";
import { TABELA_HEADERS_APPEARING } from "@utils/data/tabelaHeaders";
import formatDate, { isToday } from "@utils/formatDate";
import TableRow from "@material-ui/core/TableRow";
import Td from "@components/Tables/components/Td";
import CVBadge from "../components/CVBadge";
import { tdStyle } from "../MinhasOfertasTables/MinhasOfertasTr";
import OfertaDetails from "./OfertaDetails";
import showPreco from "../../../utils/showPreco";
import { AcUnit, BarChart, Message, MonetizationOn } from "@material-ui/icons";
import mixpanel from "@utils/mixpanel/mixpanel.js";
import { OFERTA_VISUALIZADA } from "../../../utils/mixpanel/mixpanelEvents";
import { EmpresaAvatar } from "@components/Header.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BLUE } from "@utils/themes";
import { useStyles, BLINK_TIME, CenterDiv } from "../../Feed/CotacaoInfo";
import { Tooltip } from "@material-ui/core";
import { green, yellow } from "@material-ui/core/colors";
import { ICE } from "../../../utils/themes";

function FeedTr({
  oferta,
  i,
  openIdx,
  setOpenIdx,
  user,
  cotacao,
  isFree,
  xIsPublished,
  handleFastChatOpen,
}) {
  const xIsOpen = i === openIdx;
  const isFromUser = user?._id == oferta?.usuario?._id;
  const isFromCompany = user?.empresa?._id == oferta?.empresa?._id;
  let isDinamico = !oferta.congelada && oferta.diferencial;
  let history = useHistory();

  function mixpanelTrack() {
    if (!isFromCompany) {
      mixpanel.track(OFERTA_VISUALIZADA, {
        oferta: oferta._id,
        empresaContratante: oferta?.empresa?.nomeFantasia,
        usuarioContratante: oferta?.usuario?.name,
      });
    }
  }
  if (!xIsPublished) {
    return null
  }
  return (
    <>
      <TableRow
        onClick={() => {
          mixpanelTrack();
          setOpenIdx(xIsOpen ? null : i);
        }}
        hover
        style={{
          cursor: "pointer",
        }}
      >
        <Td>
          <CVBadge modo={oferta[`modo`]} />
        </Td>
        {Object.keys(TABELA_HEADERS_APPEARING).map((key) => {
          const xIsPopulate =
            oferta[key] &&
            TABELA_HEADERS_APPEARING[key].tipoDeInput == "populate";
          if (xIsPopulate) {
            return (
              <Td key={key}>
                <div style={tdStyle}>
                  {oferta[key][TABELA_HEADERS_APPEARING[key].key]}
                </div>
              </Td>
            );
          }
          if (TABELA_HEADERS_APPEARING[key].tipoDeInput == "PRECO") {
            if (!oferta.diferencial) {
              return (
                <Td key={key} align="center" style={{ paddingRight: 8 }}>
                  <div style={precoStyle}>
                    {getPrecoStatus("fixo")}
                    R$ {showPreco(oferta, cotacao)}
                  </div>
                </Td>
              );
            }

            if (isDinamico) {
              const xPreco = showPreco(oferta, cotacao);

              return (
                <Td key={key}>
                  <BlinkingPreco preco={xPreco} cotacao={cotacao} />
                </Td>
              );
            }
            if (oferta.congelada && oferta.diferencial) {
              return (
                <Td key={key}>
                  <div style={precoStyle}>
                    {getPrecoStatus("congelado")}
                    R$ {showPreco(oferta, cotacao)}
                  </div>
                </Td>
              );
            }
          }

          return (
            <Td>
              <div style={tdStyle}>
                {oferta[key] === 0
                  ? "X"
                  : oferta[key] === -1
                    ? "UP"
                    : oferta[key]
                      ? `${oferta[key]}${TABELA_HEADERS_APPEARING[key].tipoDeInput ===
                        "PORCENTAGEM"
                        ? "%"
                        : ""
                      }`
                      : ""}
              </div>
            </Td>
          );
        })}

        {/* <Td>{cotacao.vencimentos?.[oferta["vencimento"]]?.contractSymbol}</Td> */}

        <Td align="center">{getAtualizacao(oferta, cotacao, isDinamico)}</Td>
        {/* <Td onClick={(e) => e.stopPropagation()}>
          {isFree ? null : isFromCompany ? (
            <EmpresaAvatar history={history} size={16} user={user} />
          ) : isFromUser ? (
            <div
              style={{
                width: 16,
                height: 16,
                borderRadius: 10,
                background: BLUE,
              }}
            ></div>
          ) : (
            <Message
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleFastChatOpen(oferta?._id, oferta?.usuario);
              }}
            />
          )}
        </Td> */}
      </TableRow>

      {xIsOpen ? (
        <OfertaDetails
          oferta={oferta}
          cotacao={cotacao}
          handleFastChatOpen={handleFastChatOpen}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default memo(FeedTr);

export function BlinkingPreco({ preco }) {
  const classes = useStyles();
  const [isBlinking, setIsBlinking] = useState();
  const lastPreco = useRef();

  useEffect(() => {
    if (lastPreco.current !== preco) {
      if (Number(lastPreco.current) === Number(preco)) {
        //Do nothing
      } else if (Number(preco) > Number(lastPreco.current)) {
        blink("greenBg");
      } else if (Number(preco) < Number(lastPreco.current)) {
        blink("redBg");
      }
      lastPreco.current = preco;
    }
  }, [preco]);
  function blink(bg) {
    setIsBlinking(bg);
    setTimeout(() => {
      setIsBlinking(false);
    }, BLINK_TIME);
  }

  return (
    <CenterDiv isBlinking={isBlinking} style={precoStyle}>
      {getPrecoStatus("dinamico")}
      <span variant="body2" className={classes.text}>
        R$ {preco}
      </span>
    </CenterDiv>
  );
}

let precoStyle = {
  display: "flex",
  alignItems: "center",
  // justifyContent: "space-between",
  gap: 8,
};

export function getPrecoStatus(tipo) {
  if (tipo === "dinamico") {
    return (
      <Tooltip title="Dinâmico">
        <BarChart style={{ color: green[400] }} fontSize="small" />
      </Tooltip>
    );
  }
  if (tipo === "fixo") {
    return (
      <Tooltip title="Fixo">
        <MonetizationOn style={{ color: yellow[800] }} fontSize="small" />
      </Tooltip>
    );
  }
  if (tipo === "congelado") {
    return (
      <Tooltip title="Congelado" style={{ color: ICE }}>
        <AcUnit fontSize="small" />
      </Tooltip>
    );
  }
}

export function getAtualizacao(pOferta, pCotacao, pIsDinamico) {
  return isToday(pOferta["publishedAt"]) ? (
    pIsDinamico ? (
      formatDate(pCotacao.updatedAt, true)
    ) : (
      formatDate(pOferta["updatedAt"], true)
    )
  ) : (
    <></>
  );
}
