import api from "@services/api";
import { uuid } from "uuidv4";

export const xUuid = uuid();

let config = {
  headers: {
    sessionid: xUuid,
  },
};

function post(pTitle, pText) {
  return api.post("/post", { titulo: pTitle, texto: pText });
}

function getPosts(pQtdPosts = 0) {
  return api.get(`/post/list/${pQtdPosts}`, config);
}

function getFavoritePosts(pQtdPosts = 0) {
  return api.get(`/post/fav/${pQtdPosts}`, config);
}

function likePost(pPostId) {
  return api.put(`/post/${pPostId}`);
}

function favoritePost(pPostId) {
  return api.put(`/post/fav/${pPostId}`);
}

function getReplies(pPostId, pQtdPosts = 0) {
  return api.get(`/post/reply/${pPostId}/${pQtdPosts}`, config);
}

function postReply(pPostId, pText) {
  return api.post(`/post/reply/${pPostId}`, { texto: pText });
}

function getFriendsPosts(pQtdFriendsPosts = 0) {
  return api.get(`/post/amizade/${pQtdFriendsPosts}`, config);
}

function getMyPosts(pQtdMeusPosts = 0) {
  return api.get(`/post/meus/${pQtdMeusPosts}`, config);
}

function deletePost(pPostId) {
  return api.delete(`/post/${pPostId}`);
}

const forumService = {
  post,
  getPosts,
  likePost,
  favoritePost,
  getFavoritePosts,
  getReplies,
  postReply,
  getFriendsPosts,
  getMyPosts,
  deletePost,
};

export default forumService;
