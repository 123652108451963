import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";
import {
  Check,
  Clear,
  Email,
  Settings,
  PersonAdd,
  PersonAddDisabled,
  Phone,
  Business,
  Person,
} from "@material-ui/icons";
import amizadeService from "../services/amizadeService";
import { useParams } from "react-router";
import TCButton from "./TCButton";
import { dynamicPhoto } from "./ImageUploader";

export const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  box: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  flex: { display: "flex", alignItems: "center", marginTop: 10 },
  absolute: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

export default function PhotoAndInfo({
  imgSrc,
  nome,
  email,
  telefone1,
  telefone2,
  isEditable,
  onClickToEdit,
  isLoggedUser,
  isCompany,
}) {
  const { userId } = useParams();
  const classes = useStyles();
  return (
    <Box className={classes.mainDiv}>
      {isEditable && (
        <Box className={classes.absolute}>
          <IconButton onClick={onClickToEdit}>
            <Settings fontSize="large" />
          </IconButton>
        </Box>
      )}
      {!isLoggedUser && !isCompany && (
        <Box className={classes.absolute}>
          <SmartFriendshipButton userId={userId} />
        </Box>
      )}

      {imgSrc ? (
        <img
          alt="img"
          src={dynamicPhoto(imgSrc)}
          style={{ borderRadius: 75, height: 150, width: 150 }}
        />
      ) : isCompany ? (
        <Business style={{ height: 150, width: 150 }} />
      ) : (
        <Person style={{ height: 150, width: 150 }} />
      )}

      <div style={{ marginLeft: 50 }}>
        <Box className={classes.box}>
          <Typography variant="h4" gutterBottom>
            {nome}
          </Typography>
        </Box>
        <Box className={classes.flex}>
          <Email fontSize="small" />
          <Typography variant="body1" style={{ marginLeft: 10 }}>
            {email}
          </Typography>
        </Box>
        {telefone1 && (
          <Box className={classes.flex}>
            <Phone fontSize="small" />
            <Typography variant="body2" style={{ marginLeft: 10 }}>
              {telefone1}
            </Typography>
          </Box>
        )}
        {telefone2 && (
          <Box className={classes.flex}>
            <Phone fontSize="small" />
            <Typography variant="body2" style={{ marginLeft: 10 }}>
              {telefone2}
            </Typography>
          </Box>
        )}
      </div>
    </Box>
  );
}

function SmartFriendshipButton({ userId }) {
  const [loading, setLoading] = useState(true);
  const [situacao, setSituacao] = useState({});
  function doRequest(cb) {
    setLoading(true);
    cb.then((rSituacao) => setSituacao(rSituacao))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    doRequest(amizadeService.getAmizadeStatus(userId));
  }, []);

  function deletarAmizade() {
    doRequest(amizadeService.deletarAmizade(situacao.amizadeId));
  }

  function criarPedidoAmizade() {
    doRequest(amizadeService.criarPedidoAmizade(userId));
  }

  function deletarPedidoAmizade() {
    doRequest(amizadeService.deletarPedidoAmizade(situacao.pedidoId));
  }

  function aceitarPedidoAmizade() {
    doRequest(amizadeService.aceitarPedidoAmizade(situacao.pedidoId));
  }
  if (loading) {
    return (
      <TCButton
        variant="outlined"
        loading
        color="white"
        style={{ width: 120 }}
      />
    );
  }

  if (situacao.status === 3) {
    return (
      <Button onClick={deletarAmizade} variant="outlined" startIcon={<Clear />}>
        Desfazer amizade
      </Button>
    );
  }

  if (situacao.status === 1) {
    return (
      <Button
        onClick={deletarPedidoAmizade}
        variant="outlined"
        startIcon={<PersonAddDisabled />}
      >
        Deletar pedido
      </Button>
    );
  }

  if (situacao.status === 2) {
    return (
      <Box style={{ display: "flex" }}>
        <Button
          onClick={aceitarPedidoAmizade}
          variant="outlined"
          startIcon={<Check />}
        >
          Aceitar
        </Button>
        <Button
          variant="outlined"
          startIcon={<Clear />}
          style={{ marginLeft: 8 }}
          onClick={deletarPedidoAmizade}
        >
          Recusar
        </Button>
      </Box>
    );
  }

  if (situacao.status === 0) {
    return (
      <Button
        onClick={criarPedidoAmizade}
        variant="outlined"
        startIcon={<PersonAdd />}
      >
        Adicionar
      </Button>
    );
  }

  return <div />;
}
