import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearLocalStorage } from "@utils/localstorage/tokenStorage";
import YesNoModal from "./YesNoModal";
import MainAnimation from "@components/MainAnimation";
import IconLogoWhite from "../assets/icon_tc_white.png";
import {
  AppBar,
  Typography,
  Toolbar,
  Button,
  makeStyles,
  IconButton,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Container,
} from "@material-ui/core";
import {
  FreeBreakfast,
  Brightness4,
  DesktopMac,
  Star,
  EmojiEmotions,
  MenuSharp,
  Business,
  Person,
  Chat,
  Announcement,
  Kitchen,
  FileCopy,
  ContactSupport,
} from "@material-ui/icons";
import { setNewCompaniesCount, setTheme } from "../redux/authReducer";
import { MAIN, DARK } from "@utils/themes";
import {
  setThemeLocalStorage,
  getThemeLocalStorage,
} from "../utils/localstorage/themeStorage";
import { withRouter } from "react-router-dom";
import SocketListener from "./Socket/SocketListener";
import { withStyles } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";
import adminService from "../services/adminService";
import calculadoraService from "../services/calculadoraService";
import CalculatorModal from "./CalculatorModal";
import mixpanel from "@utils/mixpanel/mixpanel.js";
import { setIdx } from "../redux/ofertaReducer";
import InviteToTradeCafe from './InviteToTradeCafe';

const DADOS_INICIAIS_CALCULADORA = {
  r1: 0,
  r2: 0,
  r3: 0,
  s1: 0,
  s2: 0,
  s3: 0,
  dif: 0,
  precoReal1: 0,
  ny1: 0,
  dollar1: 0,
  dif2: 0,
  ny2: 0,
  dollar2: 0,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 240,
    backgroundColor: theme.palette.background.paper,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginTop: 3,
  },
  title: {
    marginLeft: 52,
    flexGrow: 1,
  },
  themeButton: {
    color: "#fff",
  },
}));

function Header({ history, logout }) {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const widthRef = useRef(window.innerWidth);
  const [showCalculator, setShowCalculator] = useState(false);
  const breakpoint = 940;

  const [dadosCalculadora, setDadosCalculadora] = useState(
    DADOS_INICIAIS_CALCULADORA
  );

  const classes = useStyles();
  const dispatch = useDispatch();

  const [user, theme, unseenMessages] = useSelector((states) => [
    states.authReducer.user,
    states.authReducer.theme,
    states.chatReducer.unseenMessages,
  ]);

  useEffect(() => {
    const handleResizeWindow = () => {
      widthRef.current = window.innerWidth;
    };
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    const xTheme = getThemeLocalStorage();
    if (xTheme) {
      dispatch(setTheme(xTheme));
    }

    if (user?._id) {
      calculadoraService.getDados().then((dados) => {
        setDadosCalculadora(dados || DADOS_INICIAIS_CALCULADORA);
      });
    }
  }, [user?._id]);

  function handleTheme() {
    const xTheme = theme === MAIN ? DARK : MAIN;
    setThemeLocalStorage(xTheme);
    dispatch(setTheme(xTheme));
  }
  function goToLogin() {
    history.push("/login");
  }

  function getPendingChats() {
    let xCount = 0;
    if (!unseenMessages) {
      return 0;
    }
    let xUnseenMessages = Object.values(unseenMessages);
    for (let xUnseen of xUnseenMessages) {
      xCount = xCount + xUnseen.length;
    }
    let xTitle = `Café Trade`;
    if (xCount) xTitle += ` (${xCount})`;
    document.title = xTitle;

    return xCount;
  }
  const getRemaningDays = (daysLeft) =>
    `${daysLeft} dia${daysLeft > 1 ? `s` : ""} restante${daysLeft > 1 ? `s` : ""
    }`;

  function handleCalculadoraChange(key, value) {
    const copy = { ...dadosCalculadora };
    copy[key] = value;
    setDadosCalculadora(copy);
  }

  function renderBar() {
    function navigateToScreen(route) {
      history.push(route);
      setDrawerOpen(false);
    }
    if (widthRef.current < breakpoint) {
      return (
        <AppBar position="static">
          <Toolbar>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {user?.empresa?.confirmed || user?.type === "A" ? (
                <div>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={() => setDrawerOpen(true)}
                  >
                    <MenuSharp />
                  </IconButton>
                  <IconButton onClick={() => setShowCalculator(true)}>
                    <Kitchen className={classes.themeButton} />
                  </IconButton>
                </div>
              ) : (
                <div />
              )}
              {user?._id ? (
                <Button color="inherit" onClick={() => setIsLoggingOut(true)}>
                  Sair
                </Button>
              ) : (
                <Button color="inherit" onClick={goToLogin}>
                  Login
                </Button>
              )}
            </div>
            <Drawer
              anchor={"left"}
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
            >
              <div className={classes.root}>
                <List component="nav" aria-label="main mailbox folders">
                  <ListItem
                    button
                    onClick={() => {
                      dispatch(setIdx(0));
                      navigateToScreen("/feed");
                    }}
                  >
                    <ListItemIcon>
                      <FreeBreakfast />
                    </ListItemIcon>
                    <ListItemText primary="Ofertas" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      dispatch(setIdx(1));
                      navigateToScreen("/feed");
                    }}
                  >
                    <ListItemIcon>
                      <FreeBreakfast />
                    </ListItemIcon>
                    <ListItemText primary="Minhas Ofertas" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => navigateToScreen("/chat")}
                    selected={2 === 1}
                  >
                    <ListItemIcon>
                      <Chat />
                    </ListItemIcon>
                    <ListItemText primary="Chats" />
                  </ListItem>
                  <ListItem
                    button
                    selected={2 === 1}
                    onClick={() => navigateToScreen("/forum")}
                  >
                    <ListItemIcon>
                      <Announcement />
                    </ListItemIcon>
                    <ListItemText primary="Forum" />
                  </ListItem>

                  <ListItem
                    button
                    selected={2 === 1}
                    onClick={() => navigateToScreen("/feedback")}
                  >
                    <ListItemIcon>
                      <EmojiEmotions />
                    </ListItemIcon>
                    <ListItemText primary="Feedback" />
                  </ListItem>
                  {user?.type === "A" && (
                    <ListItem
                      button
                      selected={2 === 1}
                      onClick={() => navigateToScreen("/admin_panel")}
                    >
                      <ListItemIcon>
                        <AdminButton noButton />
                      </ListItemIcon>
                      <ListItemText primary="Admin" />
                    </ListItem>
                  )}
                  <ListItem
                    button
                    selected={2 === 1}
                    onClick={() => history.push("/quick_messages_templates")}
                  >
                    <ListItemIcon>
                      <FileCopy />
                    </ListItemIcon>
                    <ListItemText primary="Divulgação" />
                  </ListItem>
                  <ListItem
                    button
                    selected={2 === 1}
                    onClick={() =>
                      navigateToScreen(`/company/${user?.empresa?._id}`)
                    }
                  >
                    <ListItemIcon>
                      <Business />
                    </ListItemIcon>
                    <ListItemText primary="Empresa" />
                  </ListItem>
                  <ListItem
                    button
                    selected={2 === 1}
                    onClick={() => navigateToScreen(`/profile/${user?._id}`)}
                  >
                    <ListItemIcon>
                      <Person />
                    </ListItemIcon>
                    <ListItemText primary="Usuario" />
                  </ListItem>
                  {/* <ListItem button selected={2 === 1}>
                    <ListItemIcon>
                      <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary="Sair" />
                  </ListItem> */}
                </List>
              </div>
            </Drawer>
          </Toolbar>
        </AppBar>
      );
    }

    return (
      <Toolbar>
        <Container
          maxWidth="xl"
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img
            src={IconLogoWhite}
            width={23}
            height={23}
            alt="icon"
            style={{ marginRight: 8 }}
          />

          {/* <Typography variant="h6">Café Trade</Typography> */}

          {(user?.empresa?.confirmed || user?.type === "A") && (
            <>
              <ListItem
                button
                style={{ width: `auto` }}
                onClick={() => {
                  dispatch(setIdx(0));
                  history.push("/feed");
                }}
              >
                <ListItemText primary="Ofertas" />
              </ListItem>
              <ListItem
                button
                style={{ width: `auto` }}
                onClick={() => {
                  dispatch(setIdx(1));
                  history.push("/feed");
                }}
              >
                <ListItemText primary="Minhas Ofertas" />
              </ListItem>
              <StyledBadge badgeContent={getPendingChats()}>
                <ListItem
                  button
                  style={{ width: `auto` }}
                  onClick={() => history.push("/chat")}
                >
                  <ListItemText primary="Chat" />
                </ListItem>
              </StyledBadge>

              <ListItem
                button
                style={{ width: `auto` }}
                onClick={() => history.push("/forum")}
              >
                <ListItemText primary="Fórum" />
              </ListItem>
              <ListItem
                button
                style={{ width: `auto` }}
                onClick={() => setShowCalculator(true)}
              >
                <ListItemText primary="Calculadora" />
              </ListItem>
              {/* {user?._id && (
                <ListItem
                  button
                  style={{ width: `auto` }}
                  selected={2 === 1}
                  onClick={() => history.push("/vencimentos")}
                >
                  <ListItemText primary="Vencimentos" />
                </ListItem>
              )} */}
              {user?._id && (
                <ListItem
                  button
                  style={{ width: `auto` }}
                  selected={2 === 1}
                  onClick={() => history.push("/quick_messages_templates")}
                >
                  <ListItemText primary="Divulgação" />
                </ListItem>
              )}
            </>
          )}

          {user?.type === "A" && (
            <Tooltip title="Painel">
              <AdminButton history={history} />
            </Tooltip>
          )}
          <Typography variant="h6" className={classes.title}></Typography>

          {user?.empresa?.confirmed ? (
            <>
              <Tooltip title="Convidar">
                <Button>
                  <InviteToTradeCafe />
                </Button>

              </Tooltip>
              <Tooltip title="Empresa">
                <Button
                  color="inherit"
                  onClick={() => history.push(`/company/${user?.empresa?._id}`)}
                >
                  <EmpresaAvatar user={user} />
                </Button>
              </Tooltip>
            </>
          ) : null}

          {(user?.empresa?.confirmed || user?.type === "A") && (
            <Tooltip title="Perfil">
              <Button
                color="inherit"
                onClick={() => history.push(`/profile/${user?._id}`)}
                style={{ marginRight: 40 }}
              >
                {/* <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              {user?.img && (
                <img
                  alt="img user"
                  src={user?.img}
                  style={{
                    height: 30,
                    width: 30,
                    borderRadius: 50,
                    marginRight: 6,
                  }}
                />
              )}
              <Typography variant="h6"> {user?.name}</Typography>
            </div> */}
                <AvatarButton user={user} />
              </Button>
            </Tooltip>
          )}

          {user?.empresa?.pago ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  color: "#ffcc00",
                }}
              >
                <Star height={50} width={50} />
                <span>Plus</span>
              </div>
              <span style={{ fontSize: 12 }}>
                {getRemaningDays(
                  user?.empresa?.pago
                    ? user?.empresa?.daysLeft
                    : user?.empresa?.diasRestantesFreeTrial
                )}
              </span>
            </div>
          ) : user?.type === "A" ? (
            <h3 style={{ marginRight: 12 }}>Admin</h3>
          ) : !user?.empresa?.confirmed ? null : user?.empresa
            ?.diasRestantesFreeTrial ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Periodo de teste</span>
              <span style={{ fontSize: 12 }}>
                {getRemaningDays(user?.empresa?.diasRestantesFreeTrial)}
              </span>
            </div>
          ) : (
            <span style={{ fontWeight: "bold" }}>{user?.name}</span>
          )}
          {user?._id && (
            <Tooltip title="Suporte">
              <Button onClick={() => history.push("/feedback")}>
                <ContactSupport className={classes.themeButton} />
              </Button>
            </Tooltip>
          )}
          <IconButton onClick={handleTheme}>
            <Brightness4 className={classes.themeButton} />
          </IconButton>

          {user?._id ? (
            <Button color="inherit" onClick={() => setIsLoggingOut(true)}>
              Sair
            </Button>
          ) : (
            <Button color="inherit" onClick={goToLogin}>
              Login
            </Button>
          )}
        </Container>
      </Toolbar>
    );
  }

  return (
    <MainAnimation>
      <AppBar position="sticky">
        {user?._id && <SocketListener />}
        {renderBar()}
        <YesNoModal
          showModal={isLoggingOut}
          title={`Deseja sair?`}
          onYes={() => {
            logout();
            setIsLoggingOut(false);
          }}
          handleClose={() => setIsLoggingOut(false)}
        />
        <CalculatorModal
          showModal={showCalculator}
          handleClose={() => setShowCalculator(false)}
          dados={dadosCalculadora}
          handleChange={handleCalculadoraChange}
        />
      </AppBar>
    </MainAnimation>
  );
}

export default withRouter(Header);

export const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 10,
    top: 13,
    border: `0 solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: green[700],
  },
}))(Badge);

const AdminBadge = withStyles((theme) => ({
  badge: {
    right: 17,
    top: 13,
    border: `0 solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: green[700],
  },
}))(Badge);

function AdminButton({ history, noButton }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [newCompaniesCount] = useSelector((states) => [
    states.authReducer.newCompaniesCount,
  ]);

  useEffect(() => {
    adminService
      .getAdminNewCompaniesCount()
      .then((rCount) => {
        dispatch(setNewCompaniesCount(rCount?.count));
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <AdminBadge badgeContent={newCompaniesCount} left>
      {noButton ? (
        <DesktopMac />
      ) : (
        <Button color="inherit" onClick={() => history.push("/admin_panel")}>
          <DesktopMac className={classes.themeButton} />
        </Button>
      )}
    </AdminBadge>
  );
}
const getInitial = (input) =>
  String(input || "")
    .trim()
    .charAt(0);
function AvatarButton({ user }) {
  let [loaded, setLoaded] = useState(false);
  if (user?.img && loaded) {
    return (
      <img
        alt="img user"
        src={user?.img}
        style={{
          height: 30,
          width: 30,
          borderRadius: 50,
          marginRight: 6,
        }}
        onLoad={() => setLoaded(true)}
      />
    );
  }

  return <Person />;
  // let initials = "";

  // const names = user?.name?.split(" ");
  // initials += getInitial(names[0]);
  // initials += getInitial(names[1]);
  // return (
  //   <div
  //     style={{
  //       width: 35,
  //       height: 35,
  //       background: "transparent",
  //       borderRadius: 40,
  //       display: "flex",
  //       alignItems: "center",
  //       justifyContent: "center",
  //     }}
  //   >
  //     {initials}
  //   </div>
  // );
}

export function EmpresaAvatar({ user, size }) {
  let [loaded, setLoaded] = useState(false);
  if (user?.empresa?.img && loaded) {
    return (
      <img
        alt="Img Empresa"
        src={user?.empresa?.img}
        style={{
          borderRadius: 50,
          marginRight: 6,
          width: size || 35,
          height: size || 35,
        }}
        onLoad={() => setLoaded(true)}
      />
    );
  }
  return <Business />;
}
