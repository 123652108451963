const TABELA_HEADERS = {
  padraoInterno: {
    table: "Padrão",
    showOnTable: true,
    onlyMinhasOfertas: true,
  },
  regiaoProdutora: {
    table: "Região",
    showOnTable: true,
    showOnTableMobile: true,
    onlyFeed: true,
  },
  mr: {
    table: "Posto/Retirada",
    showOnTable: true,
    showOnTableMobile: true,
    onlyFeed: true,
  },

  anoSafra: {
    table: "Safra",
    showOnTable: true,
    showOnTableMobile: true,
  },
  especie: {
    table: "Esp",
    showOnTable: true,
    showOnTableMobile: true,
  },
  processamento: {
    table: "Procs",
    showOnTable: true,
    showOnTableMobile: true,
  },
  bebidaBase: {
    table: "Bebida",
    showOnTable: true,
    showOnTableMobile: true,
  },
  gostoAdicional: {
    table: "GostoAd.",
    showOnTable: true,
    showOnTableMobile: true,
  },

  catacao: {
    table: "Cat%",
    showOnTable: true,
    showOnTableMobile: false,
    tipoDeInput: "PORCENTAGEM",
  },
  teorUmidade: {
    table: "Umid%",
    showOnTable: true,
    showOnTableMobile: false,
    tipoDeInput: "PORCENTAGEM",
  },
  aspecto: {
    table: "Asp",
    showOnTable: false,
  },
  tipoCafe: {
    table: "Tipo",
    showOnTable: true,
    showOnTableMobile: true,
  },
  defeito: {
    table: "Def",
    showOnTable: true,
    showOnTableMobile: false,
  },
  fava: {
    table: "Fava",
    showOnTable: true,
    showOnTableMobile: true,
  },

  p20: {
    table: "P20",
    showOnTable: true,
    showOnTableMobile: false,
    tipoDeInput: "PORCENTAGEM",
  },

  p19: {
    table: "P19",
    showOnTable: true,
    showOnTableMobile: false,
    tipoDeInput: "PORCENTAGEM",
  },

  p18: {
    table: "P18",
    showOnTable: true,
    showOnTableMobile: false,
    tipoDeInput: "PORCENTAGEM",
  },

  p17: {
    table: "P17",
    showOnTable: true,
    showOnTableMobile: false,
    tipoDeInput: "PORCENTAGEM",
  },

  p16: {
    table: "P16",
    showOnTable: true,
    showOnTableMobile: false,
    tipoDeInput: "PORCENTAGEM",
  },

  p15: {
    table: "P15",
    showOnTable: true,
    showOnTableMobile: false,
    tipoDeInput: "PORCENTAGEM",
  },

  p14: {
    table: "P14",
    showOnTable: true,
    showOnTableMobile: false,
    tipoDeInput: "PORCENTAGEM",
  },

  p13: {
    table: "P13",
    showOnTable: true,
    showOnTableMobile: false,
    tipoDeInput: "PORCENTAGEM",
  },
  quantidadeSacos: {
    table: "Qtd",
    showOnTable: true,
    showOnTableMobile: false,
  },
  preco: {
    table: "Preço",
    showOnTable: true,
    showOnTableMobile: true,
    tipoDeInput: "PRECO",
  },

  precoNY: {
    table: "KC",
    showOnTable: false,
  },
  praca: {
    table: "Praça",
    showOnTable: false,
  },

  //
  //   Municipio da Praca de comercializacao
  //

  //
  //   Usuario
  //
  municipio: {
    table: "MP",
    showOnTable: false,
  },
  //   empresa: {
  //     table: "Empresa",
  //     showOnTable: true,
  //     tipoDeInput: "populate",
  //     key: "nomeFantasia",
  //   },

  //
  //   Municipio da Empresa
  //

  //
  //   Dt atualizacao da oferta
  //

  cor: {
    table: "Cor",
    showOnTable: false,
  },

  dollar: {
    table: "BRL",
    showOnTable: false,
  },
  diferencial: {
    table: "Diff",
    showOnTable: false,
  },

  tp: {
    table: "Tipo",
    showOnTable: false,
  },

  f13: {
    table: "FP13",
    showOnTable: false,
  },

  f10: {
    table: "FP10",
    showOnTable: false,
  },

  pva: {
    table: "Pva",
    showOnTable: false,
  },
  numeroLote: {
    table: "Lote",
    showOnTable: false,
  },
  fundoLote: {
    table: "Fundo",
    showOnTable: false,
  },
  ql: {
    table: "Quebra",
    showOnTable: false,
  },

  impurezas: {
    table: "Imp",
    showOnTable: false,
  },

  moka: {
    table: "Moka",
    showOnTable: false,
  },

  certificacao: {
    table: "Cert",
    showOnTable: false,
  },
  obs: {
    table: "obs",
    showOnTable: false,
  },
};

const TABELA_HEADERS_APPEARING = {};
const MINHAS_OFERTAS_TABELA_HEADERS_APPEARING = {};
Object.keys(TABELA_HEADERS).map((key) => {
  if (
    TABELA_HEADERS[key].showOnTable &&
    !TABELA_HEADERS[key].onlyMinhasOfertas
  ) {
    TABELA_HEADERS_APPEARING[key] = TABELA_HEADERS[key];
  }
  if (TABELA_HEADERS[key].showOnTable && !TABELA_HEADERS[key].onlyFeed) {
    MINHAS_OFERTAS_TABELA_HEADERS_APPEARING[key] = TABELA_HEADERS[key];
  }
});

module.exports = TABELA_HEADERS;
module.exports.TABELA_HEADERS_APPEARING = TABELA_HEADERS_APPEARING;
module.exports.MINHAS_OFERTAS_TABELA_HEADERS_APPEARING =
  MINHAS_OFERTAS_TABELA_HEADERS_APPEARING;
