import React, { useEffect, useState } from "react";
import { Container, IconButton, Paper } from "@material-ui/core";
import MainAnimation from "@components/MainAnimation";
import adminService from "@services/adminService";
import { CastConnected, Refresh } from "@material-ui/icons";
import TCLoading from "../TCLoading";


function ConnectedUsersPanel() {
  let [loading, setLoading] = useState(true)
  let [connectedUsers, setConnectedUsers] = useState([])
  function getConnectedUsers() {
    setLoading(true)
    adminService.getConnectedUsers().then((rUsers) => {
      setConnectedUsers(rUsers)
    }).finally(() => setLoading(false))
  }
  useEffect(() => {
    getConnectedUsers()
  }, [])
  return (
    <MainAnimation>
      <Container maxWidth="md" style={{ paddingTop: 20, display: "flex" }}>
        <Paper
          style={{
            width: "100%",
            padding: 24,
            minHeight: 300,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 style={{ gap: 12, display: 'flex', alignItems: 'center' }}> <CastConnected style={{ fontSize: '30px' }} />Usuarios Conectados para receber Atualizações</h1>
            <IconButton onClick={() => getConnectedUsers()}>
              <Refresh />
            </IconButton>
          </div>
          {loading ? (<div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: 300,
            }}
          >
            <TCLoading size={40} />
          </div>) : (<ol>
            {connectedUsers?.map(rUser => <li>({!rUser?.savedFilters ? 0 : Object.values(rUser?.savedFilters).length}) - {rUser.name} </li>)}
          </ol>)}

        </Paper>
      </Container>
    </MainAnimation>
  );
}

export default ConnectedUsersPanel;
