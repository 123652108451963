import api from "@services/api";
import mixpanel from "@utils/mixpanel/mixpanel.js";
import { DIVULGACAO_CRIADA } from "../utils/mixpanel/mixpanelEvents";

function getMensagensRapidas() {
  return api.get(`/mensagem-rapida/`);
}

function getMensagemRapidaSubstituida(pMensagemRapidaId, pOfertaId) {
    return api.get(`/mensagem-rapida/substituida/${pMensagemRapidaId}/oferta/${pOfertaId}`);
  }

function upsertMensagemRapida(pMensagemRapida) {
  if(pMensagemRapida._id){
    return api.put(`/mensagem-rapida/${pMensagemRapida._id}`, pMensagemRapida);
  }
  return api.post(`/mensagem-rapida/`, pMensagemRapida).then(r=>{
    mixpanel.track(DIVULGACAO_CRIADA)
    return r
  })
}

function deletarMensagemRapida(pMensagemRapidaId) {   
  return api.delete(`/mensagem-rapida/${pMensagemRapidaId}`);
}


const mensagemRapidaService = {
    getMensagensRapidas,
    getMensagemRapidaSubstituida,
    upsertMensagemRapida,
    deletarMensagemRapida
};

export default mensagemRapidaService;
