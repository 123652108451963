const INITIAL_STATE = {
  idx: 0,
  selectedOfertaId: null,
  ofertas: {},
  minhasOfertas: {},
  freeOfertas: {},
  filtros: { modo: "C" },
  selectedFiltro: { nome: "", _id: "" },
  fastChat: { ofertaId: "", destinoUsuario: {} },
};

const SET_IDX = "OFERTA_REDUCER/SET_IDX";
const SET_OFERTAS = "OFERTA_REDUCER/SET_OFERTAS";
const SET_FREE_OFERTAS = "OFERTA_REDUCER/SET_FREE_OFERTAS";
const SET_MINHAS_OFERTAS = "OFERTA_REDUCER/SET_MINHAS_OFERTAS";
const ADD_EDIT_FEED_OFERTA = "OFERTA_REDUCER/ADD_EDIT_FEED_OFERTA";
const ADD_MINHAS_OFERTAS = "OFERTA_REDUCER/ADD_MINHAS_OFERTAS";
const DELETE_OFERTA = "OFERTA_REDUCER/DELETE_OFERTA";
const UPDATE_PUBLISHED_OFERTA = "OFERTA_REDUCER/UPDATE_PUBLISHED_OFERTA";
const SELECT_OFERTA_ID = "OFERTA_REDUCER/SELECT_OFERTA_ID";
const CLEAR_OFERTA_REDUCER = "OFERTA_REDUCER/CLEAR_OFERTA_REDUCER";
const SET_FILTROS = "OFERTA_REDUCER/SET_FILTROS";
const RESET_FILTROS = "OFERTA_REDUCER/RESET_FILTROS";
const SET_SELECTED_FILTRO = "OFERTA_REDUCER/SET_SELECTED_FILTRO";
const SET_FAST_CHAT = "OFERTA_REDUCER/SET_FAST_CHAT";

export function setIdx(idx) {
  return {
    type: SET_IDX,
    payload: idx,
  };
}
export function setOfertas(ofertas) {
  return {
    type: SET_OFERTAS,
    payload: ofertas,
  };
}
export function setFreeOfertas(ofertas) {
  return {
    type: SET_FREE_OFERTAS,
    payload: ofertas,
  };
}

export function setMinhasOfertas(ofertas) {
  return {
    type: SET_MINHAS_OFERTAS,
    payload: ofertas,
  };
}

export function addEditFeedOferta(oferta) {
  return {
    type: ADD_EDIT_FEED_OFERTA,
    payload: oferta,
  };
}

export function addMinhasOfertas(oferta) {
  return {
    type: ADD_MINHAS_OFERTAS,
    payload: oferta,
  };
}

export function setFiltros(filtros) {
  return { type: SET_FILTROS, payload: filtros };
}

export function resetFiltros() {
  return { type: RESET_FILTROS };
}

export function deleteOferta(ofertaId) {
  return {
    type: DELETE_OFERTA,
    payload: ofertaId,
  };
}

export function updatedPublishedOferta(pUserId, pOferta) {
  return {
    type: UPDATE_PUBLISHED_OFERTA,
    payload: { oferta: pOferta, userId: pUserId },
  };
}

export function selectOfertaId(ofertaId) {
  return {
    type: SELECT_OFERTA_ID,
    payload: ofertaId,
  };
}

export function clearOfertaReducer() {
  return {
    type: CLEAR_OFERTA_REDUCER,
  };
}

export function setSelectedFiltro(pNomeFiltro) {
  return {
    type: SET_SELECTED_FILTRO,
    payload: pNomeFiltro,
  };
}

export function setFastChat(pOfertaId, pDestinoUsuario) {
  return {
    type: SET_FAST_CHAT,
    payload: {
      ofertaId: pOfertaId,
      destinoUsuario: pDestinoUsuario,
    },
  };
}
export default function ofertaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_IDX:
      return { ...state, idx: action.payload };
    case SET_OFERTAS:
      return { ...state, ofertas: action.payload };
    case SET_FREE_OFERTAS:
      return { ...state, freeOfertas: action.payload };
    case SET_MINHAS_OFERTAS:
      return { ...state, minhasOfertas: action.payload };
    case ADD_EDIT_FEED_OFERTA:
      const xOferta = action.payload;

      return {
        ...state,
        ofertas: {
          ...state.ofertas,
          data: {
            ...state.ofertas.data,
            [xOferta._id]: xOferta,
          },
        },
      };

    case ADD_MINHAS_OFERTAS:
      return {
        ...state,
        minhasOfertas: {
          ...state.minhasOfertas,
          [action.payload?._id]: action.payload,
        },
      };

    case DELETE_OFERTA:
      const newStates = { ...state };
      delete newStates.ofertas.data[action.payload];
      delete newStates.minhasOfertas[action.payload];
      return newStates;

    case UPDATE_PUBLISHED_OFERTA:
      let novosStates = {
        ...state,
      };
      let oferta = action.payload.oferta;

      if (oferta?.publishedAt) {
        novosStates.ofertas.data[oferta._id] = oferta;
      } else {
        delete novosStates.ofertas.data[oferta._id];
      }
      return novosStates;
    case SELECT_OFERTA_ID:
      return {
        ...state,
        selectedOfertaId: action.payload,
      };
    case SET_FILTROS:
      return {
        ...state,
        filtros: action.payload,
      };

    case RESET_FILTROS:
      return {
        ...state,
        filtros: INITIAL_STATE.filtros,
        selectedFiltro: {},
      };

    case CLEAR_OFERTA_REDUCER:
      return { ...INITIAL_STATE, freeOfertas: state.freeOfertas };
    case SET_SELECTED_FILTRO:
      return { ...state, selectedFiltro: action.payload };
    case SET_FAST_CHAT:
      return {
        ...state,
        fastChat: {
          ofertaId: action.payload.ofertaId,
          destinoUsuario: action.payload.destinoUsuario,
        },
      };
    default:
      return state;
  }
}
