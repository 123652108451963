import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Container, MenuItem, Paper, Select, Typography } from "@material-ui/core";
import MainAnimation from "@components/MainAnimation";
import adminService from "@services/adminService";
import showToast from "../Toast";
import { VencimentoRcIds, VencimentosKCIds } from "../../utils/data/vencimentosRC";
import {
  ImgCotacao,
} from "@components/Feed/CotacaoInfo";
import TCButton from "../../components/TCButton";
import { rmPreSymbolLondres, rmPreSymbolNY } from "../../utils/showPreco";
import { FlashOn } from "@material-ui/icons";

function CotacaoPanel() {
  const [cotacao] = useSelector((states) => [states.cotacaoReducer]);

  let [checked, setChecked] = useState(!!cotacao.automatic);
  let [kc, setKc] = useState(cotacao.manualSymbolKC);
  let [rc, setRc] = useState(cotacao.manualSymbolRC);
  let [loading, setLoading] = useState(false);

  function putCotacao() {
    setLoading(true)
    return adminService.putCotacao({ automatic: checked ? 1 : 0, manualSymbolKC: kc, manualSymbolRC: rc }).then(rCotacao => {
      showToast.success("Cotacao Salva!")
    }).catch(e => showToast.error(e.message)).finally(() => setLoading(false))
  }

  return (
    <MainAnimation>
      <Container maxWidth="xl" style={{ paddingTop: 20, display: "flex" }}>
        <Paper
          style={{
            width: "100%",
            padding: 24,
            minHeight: 300,
          }}
        >
          <h1>Cotacoes</h1>

          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between', gap: 12 }}>

            <Typography variant='subtitle1' >{checked ? 'Automatica' : 'Manual'}</Typography>
            <FlashOn
              checked={!!checked}
              style={{ color: checked ? "#ffcc00" : '#FFF', cursor: 'pointer', transition: "all 0.3s ease-in-out", fontSize: 90 }}
              onClick={(e) => setChecked(!checked)}
            />
            <div style={{ display: 'flex', alignItems: 'center', gap: 12, marginTop: 40 }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }}>

                <span > <ImgCotacao /> NY</span>

                <Select
                  placeholder="Cotacao KC..."
                  variant="outlined"
                  value={kc}
                  onChange={(e) => setKc(e.target.value)}
                  style={{ minWidth: 120 }}
                >
                  {Object.keys(VencimentosKCIds).map((each) => (
                    <MenuItem value={rmPreSymbolNY(each)}>{each}</MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }}>
                <span > <ImgCotacao isLondres /> Londres</span>

                <Select
                  placeholder="Cotacao RC..."
                  value={rc}
                  onChange={(e) => setRc(e.target.value)}

                  style={{ minWidth: 120 }}
                  variant="outlined"
                >
                  {Object.keys(VencimentoRcIds).map((each) => (
                    <MenuItem value={rmPreSymbolLondres(each)}>{each}</MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <TCButton loading={loading} onClick={putCotacao}>Salvar</TCButton>
          </div>
        </Paper>
      </Container>
    </MainAnimation>
  );
}

export default CotacaoPanel;
