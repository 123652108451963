import api from "@services/api";

function getUser(pUserId) {
  return api.get(`/user/details/${pUserId}`);
}

function uploadUserImg(pUserId, pFormData) {
  return api.post(`/upload/user/${pUserId}`, pFormData);
}

function deleteUserImg(pUserId) {
  return api.delete(`/upload/user/${pUserId}`);
}

const userService = { getUser, uploadUserImg, deleteUserImg };

export default userService;
