import React, { useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import ofertaService from "@services/ofertaService";
import { addMinhasOfertas } from "@redux/ofertaReducer";
import { Edit } from "@material-ui/icons";
import Td from "../components/Td";
import { grey } from "@material-ui/core/colors";
import TCLoading from "../../TCLoading";
import replaceVirgulasToCommas from "../../../utils/virgulaToComma";
import showPreco from "../../../utils/showPreco";
import { getPrecoStatus } from "../FeedTable/FeedTr";

function EditableNumberTd({
  oferta,
  cotacao,
  isPreco,
  number,
  theme,
  isEditable,
  isCongelado,
  isDinamico,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [numberV, setNumberV] = useState(number);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  function handleSubmit(e) {
    e.preventDefault();
    let number = e.target?.number?.value;
    // number = number.replace(".", "");
    number = replaceVirgulasToCommas(number);
    if (isNaN(number) || (isPreco && number < 0) || number == 0) {
      return setError(true);
    }

    let editedOferta = {
      _id: oferta._id,
    };

    //That should not be here
    if (!isPreco) {
      editedOferta.diferencial = numberV;
      editedOferta.preco = showPreco(oferta, cotacao);
    } else {
      editedOferta.preco = numberV;
    }

    setIsEditing(false);
    setLoading(true);
    return ofertaService
      .editOferta(editedOferta)
      .then((rOferta) => {
        dispatch(addMinhasOfertas(rOferta));
      })
      .finally(() => setLoading(false));
  }

  return (
    <Td>
      {loading ? (
        <TCLoading />
      ) : !isEditing ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          {isPreco
            ? getPrecoStatus(
                !oferta.diferencial
                  ? "fixo"
                  : isDinamico
                    ? `dinamico`
                    : isCongelado
                      ? `congelado`
                      : null,
              )
            : null}
          <span>{number}</span>
          {isEditable ? (
            <Edit
              style={{ fontSize: 12, marginLeft: 6, cursor: "pointer" }}
              onClick={() => {
                setIsEditing(true);
              }}
            />
          ) : (
            <div style={{ width: 22 }} />
          )}
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <SmallInput
            value={numberV}
            onChange={(val) => setNumberV(replaceVirgulasToCommas(val))}
            error={error}
            theme={theme}
          />
        </form>
      )}
    </Td>
  );
}

export let SmallInput = ({ label, error, theme, value, onChange, width }) => {
  let onChange2 = (e) => {
    let str = e?.target?.value;

    // Regular expression to match valid numbers (integers or floats with up to 2 decimal places)
    const regex = /^-?\d*\.?\d{0,2}$/;

    // Allow empty input
    if (str === "") {
      return onChange("");
    }

    // Check for single digit negative case
    if (str === "-" && value.length === 2) {
      return onChange(""); // Clear if only the negative sign is left
    }

    // Allow backspace on a single digit negative number
    if (value.length === 2 && value[0] === "-" && str.length === 1) {
      return onChange(""); // Clear if just negative sign is left
    }

    // Check if the input matches the regex
    if (!regex.test(str)) {
      return; // Ignore invalid input
    }

    // If the value hasn't changed, do nothing
    if (str === value) {
      return;
    }

    return onChange(str); // Pass the valid string to onChange
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width,
      }}
    >
      <label style={{ fontSize: 9 }}>{label}</label>
      <input
        style={{
          backgroundColor: theme === "DARK" ? grey[700] : grey[200],
          border: `1px solid ${error ? "red" : "grey"}`,
          maxWidth: 60,
          color: theme === "DARK" ? "white" : "black",
          borderRadius: 2,
        }}
        name="number"
        value={value}
        onChange={onChange2}
      />
    </div>
  );
};
// export let SmallInput = ({ label, error, theme, value, onChange, width }) => {
//   return (
//     <TextField
//       variant="outlined"
//       size="small"
//       style={{
//         transform: `scale(0.8)`,

//         maxWidth: 60,
//       }}
//       label={label}
//       value={value}
//       onChange={onChange}
//       autoFocus
//     />
//     // <label style={{ fontSize: 9 }}>{label}</label>
//     // <input
//     //   style={{
//     //     backgroundColor: theme === "DARK" ? grey[700] : grey[200],
//     //     border: `1px solid ${error ? "red" : "grey"}`,
//     //     maxWidth: 60,
//     //     color: theme === "DARK" ? "white" : "black",
//     //     borderRadius:2
//     //   }}
//     //   name="number"
//     //   value={value}
//     //   onChange={onChange}
//     // />
//   );
// };
export default EditableNumberTd;
