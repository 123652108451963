const INITIAL_STATE = {
  dollar: null,
  precoNY: null,
  precoLondres: null,
  hour: new Date().toISOString(),
  vencimentos: [],
};

const SET_COTACAO = "COTACAO_REDUCER/SET_COTACAO";
const SET_VENCIMENTOS = "COTACAO_REDUCER/SET_VENCIMENTOS";

export function setCotacao(pCotacao) {
  return { type: SET_COTACAO, payload: pCotacao };
}
export function setVencimentos(pVencimentos) {
  return { type: SET_VENCIMENTOS, payload: pVencimentos };
}

export default function cotacaoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_COTACAO:
      return { ...state, ...action.payload };
    case SET_VENCIMENTOS:
      return {
        ...state,
        vencimentos: action.payload,
      };

    default:
      return state;
  }
}
