import React, { useEffect, useState } from "react";
import { FlexColumnDiv, FlexWrapDiv, Section } from "../components/DetailOffer";
import PercentageInput from "@components/PercentageInput";
import { getFromArrayOrObject } from "../components/ModalCadastrarOferta";
import {
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import OBJETO_CAMPOS, {
  complementoRow,
  descricaoRow,
  peneirasRow,
} from "../utils/data/formFields";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Autocomplete } from "@material-ui/lab";
import MUNICIPIOS from "../utils/data/municipios";
import { useDispatch, useSelector } from "react-redux";
import adminService from "../services/adminService";
import { addMinhasOfertas } from "@redux/ofertaReducer";
import ofertaService from "@services/ofertaService";
import showToast from "../components/Toast";
import { useStyles } from "../components/PercentageInput";
import {
  calcularDiferencial,
  calcularDiferencialLondres,
} from "../utils/cotacao";
import ImageUploader from "../components/ImageUploader";
import TCButton from "../components/TCButton";
import { setIdx } from "../redux/ofertaReducer";
import replaceVirgulasToCommas from "../utils/virgulaToComma";
import ForexRatesWidget from "../components/ForexRatesWidget";
import { ArrowBack } from "@material-ui/icons";
import showPreco, {
  getPrecoFinalLondresNumber,
  getPrecoFinalLondresNumberOrString,
  getPrecoFinalNYNumber,
  getPrecoFinalNYNumberOrString,
} from "../utils/showPreco";
import {
  ImgCotacao,
  USDImg,
  getMonthOfVencimento,
} from "../components/Feed/CotacaoInfo";

const useStyles2 = makeStyles((theme) => {
  return {
    formControl: {
      margin: 2,
      minWidth: 180,
      maxWidth: 200,
      fontSize: 10,
    },
    inputLabel: {
      fontSize: 14,
    },
    inpt: {
      maxWidth: 210,
      minWidth: 190,
      margin: "2px 0px",
    },
    inptHide: {
      display: "none",
    },
    first: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  };
});
function getSchema(pIsPreco) {
  return yup.object().shape({
    especie: yup.string().required(),
    processamento: yup.string().required(),
    anoSafra: yup.string().required(),
    bebidaBase: yup.string().required(),
    fava: yup.string().required(),
    aspecto: yup.string().required(),
    cor: yup.string().required(),
    padrao: yup.string().required(),
    vencimento: pIsPreco ? undefined : yup.string().required(),
  });
}

export default function CadastrarOferta({ history }) {
  const classes = useStyles2();
  const [user, minhasOfertas, selectedOfertaId, cotacao] = useSelector(
    (states) => [
      states.authReducer.user,
      states.ofertaReducer.minhasOfertas,
      states.ofertaReducer.selectedOfertaId,
      states.cotacaoReducer,
    ]
  );

  const defaultValues = selectedOfertaId
    ? {
        ...minhasOfertas[selectedOfertaId],
        vencimento: minhasOfertas[selectedOfertaId]?.vencimento,
      }
    : { modo: "C" };

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const [isPreco, setIsPreco] = useState(true);
  const [errorPreco, setErrorPreco] = useState(false);
  const [errorDif, setErrorDif] = useState(false);
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const [preco, setPreco] = useState("");
  const [dif, setDif] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(getSchema(true)),
  });
  const isAdmin = user.type === "A";
  const isDif = !isPreco;
  useEffect(() => {
    dispatch(setIdx(null));

    if (selectedOfertaId) {
      const xPreco = minhasOfertas[selectedOfertaId].preco;
      if (xPreco) {
        setIsPreco(true);
        setPreco(xPreco);
      }
      const xDif = minhasOfertas[selectedOfertaId].diferencial;
      if (xDif) {
        setIsPreco(false);
        setDif(xDif);
      }
    }

    if (isAdmin) {
      adminService
        .getFakeCompanies()
        .then((rCompanies) => setCompanies(rCompanies))
        .catch((e) => console.log("e", e));
    }
  }, []);

  function addOrEditOferta(pOferta) {
    if (selectedOfertaId) {
      return ofertaService.editOferta(pOferta).then((rOferta) => {
        dispatch(addMinhasOfertas(rOferta));

        return rOferta;
      });
    }

    if (isAdmin) {
      return adminService.addOferta(pOferta).then((rOferta) => {
        dispatch(addMinhasOfertas(rOferta));
        return rOferta;
      });
    }
    return ofertaService.addOferta(pOferta).then((rOferta) => {
      dispatch(addMinhasOfertas(rOferta));
      return rOferta;
    });
  }

  function submitOferta(dados) {
    Object.keys(dados).map((rKey) => {
      if (!selectedOfertaId && dados[rKey] === "") dados[rKey] = undefined;
    });
    setLoading(true);
    if (dados.diferencial) {
      //Save the preco at the moment of creation/edit
      dados.preco = showPreco(dados, cotacao);
    }
    return addOrEditOferta(dados)
      .then((rOferta) => {
        showToast.success(
          `Oferta ${selectedOfertaId ? "Editada" : "Cadastrada"}`
        );
        if (preview) {
          return uploadFile(selectedFile, rOferta).then(() => {
            return rOferta;
          });
        }
        return rOferta;
      })
      .catch((e) => {
        showToast.error(
          `Erro ${selectedOfertaId ? "Editando" : "Cadastrando"} Oferta`
        );
      })
      .finally(() => setLoading(false));
  }
  function uploadFile(pFile, oferta = null) {
    const formData = new FormData();
    formData.append("file", pFile);
    setLoadingPhoto(true);

    return ofertaService
      .uploadOfertaImg(oferta ? oferta._id : selectedOfertaId, formData)
      .then((rOferta) => {
        dispatch(addMinhasOfertas(rOferta));
        setValue("img", rOferta.img);
      })
      .catch((err) => showToast.error(err.message))
      .finally(() => setLoadingPhoto(false));
  }

  function handleUpload(e) {
    uploadFile(e.target.files[0]);
  }
  function handleDelete() {
    setLoadingPhoto(true);
    ofertaService
      .deleteImg(selectedOfertaId)
      .finally(() => {
        setValue("img", null);
      })
      .catch((e) => {
        console.log(e);
        showToast.error(e.message);
      })
      .finally(() => setLoadingPhoto(false));
  }

  useEffect(() => {
    // create the preview
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  const handlePrepareUpload = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };
  function handleCheckbox(pType) {
    if (pType === "PRECO") {
      setIsPreco(true);
      setDif("");
      setErrorDif(false);
      setValue("diferencial", "");
      setValue("vencimento", null);
      return;
    }
    setIsPreco(false);
    setErrorPreco(false);
    setPreco("");
    setValue("preco", "");
  }
  const xVencimento = watch("vencimento");
  const xEspecie = watch("especie");
  const xIsCompra = watch("modo") === "C";

  function renderVencimentos(especie, onlyLastVencimento) {
    if (onlyLastVencimento) {
      return (
        <div
          style={{
            display: "flex",
            gap: 20,
            alignItems: "center",
            marginBottom: 12,
          }}
        >
          <Typography variant="subtitle">
            <ImgCotacao height={12} isLondres={xEspecie !== "Arabica"} />
            {` `}
            {getMonthOfVencimento(cotacao, xEspecie !== "Arabica")}
            {xEspecie}
            {` `}
            {xEspecie === `Arabica`
              ? getPrecoFinalNYNumberOrString(cotacao)
              : getPrecoFinalLondresNumberOrString(cotacao)}
            {` `}
          </Typography>

          <div style={{ display: "flex", gap: 2 }}>
            <USDImg fontSize={20} />
            <Typography variant="caption">Dollar:</Typography>
            <Typography variant="caption">{cotacao.dollar}</Typography>
          </div>
        </div>
      );
    }
    return (
      <FormControl
        error={errors["vencimento"]}
        className={classes.formControl}
        style={{ marginBottom: 12, width: 228 }}
        size="small"
        variant="outlined"
      >
        <InputLabel className={classes.inputLabel}>{`Vencimento ${
          xEspecie === "Arabica" ? "KC" : "RC"
        }`}</InputLabel>
        <Select {...register("vencimento")} value={xVencimento}>
          {Object.values(cotacao.vencimentos).map((rVenc) => {
            if (xEspecie === "Arabica" && rVenc.preSymbol === "KC")
              return (
                <MenuItem value={rVenc._id}>{rVenc.contractSymbol}</MenuItem>
              );

            if (especie === "Robusta" && rVenc.preSymbol === "RM") {
              return (
                <MenuItem value={rVenc._id}>{rVenc.contractSymbol}</MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
    );
  }
  return (
    <Container
      maxWidth="xl"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ForexRatesWidget />
      <Container maxWidth="lg">
        <form onSubmit={handleSubmit(submitOferta)}>
          <Paper
            elevation={24}
            sx={{ p: 2, maxWidth: "300px" }}
            style={{
              padding: 30,
              marginTop: 20,
              marginBottom: 20,
              paddingBottom: 10,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={() => history.goBack()}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h5" style={{}}>
                Cadastrar Nova Oferta
              </Typography>
            </div>
            {companies.length ? (
              <EmpresasSelect
                getValues={getValues}
                classes={classes}
                errors={errors}
                watch={watch}
                register={register}
                companies={companies}
              />
            ) : null}

            {isAdmin && companies?.length === 0 && (
              <p>Sem empresas falsas,crie uma</p>
            )}
            <Box className={classes.first}>
              <CompraVendaButton
                onChange={(modo) => setValue("modo", modo)}
                defaultValue={getValues("modo")}
              />

              <div style={{ display: "flex", gap: 4 }}>
                <FormInput
                  field={"numeroLote"}
                  register={register}
                  errors={errors}
                />

                <FormInput
                  field={"padraoInterno"}
                  register={register}
                  errors={errors}
                  fullWidth
                />
              </div>

              {xIsCompra && (
                <>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label"
                  >
                    Posto em
                    <FormInput
                      field={"mr"}
                      register={register}
                      errors={errors}
                      control={control}
                      getValues={getValues}
                    />
                  </InputLabel>
                </>
              )}
              {!xIsCompra && (
                <>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label"
                  >
                    Retirado em
                    <FormInput
                      field={"mr"}
                      register={register}
                      errors={errors}
                      control={control}
                      getValues={getValues}
                    />
                  </InputLabel>
                </>
              )}
            </Box>

            {!xIsCompra && (
              <>
                <Section title="Origem">
                  <FlexWrapDiv>
                    <FormInput
                      field={"regiaoProdutora"}
                      register={register}
                      errors={errors}
                    />
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                    >
                      Municipio Produtor
                      <FormInput
                        field={"municipio"}
                        register={register}
                        control={control}
                        errors={errors}
                        getValues={getValues}
                      />
                    </InputLabel>
                  </FlexWrapDiv>
                </Section>
              </>
            )}

            {xIsCompra && (
              <>
                <Section title="Origem">
                  <FlexWrapDiv>
                    <FormInput
                      field={"regiaoProdutora"}
                      register={register}
                      errors={errors}
                    />
                  </FlexWrapDiv>
                </Section>
              </>
            )}

            <Section title="Descricao">
              <FlexWrapDiv>
                {descricaoRow.map((column) => (
                  <FlexColumnDiv>
                    {column.map((each) => (
                      <FormInput
                        field={each.key}
                        register={register}
                        errors={errors}
                        getValues={getValues}
                        setValue={setValue}
                      />
                    ))}
                  </FlexColumnDiv>
                ))}
              </FlexWrapDiv>
            </Section>

            <Section title="Preco">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", columnGap: 12 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={isPreco}
                        onClick={(e) => handleCheckbox("PRECO")}
                      />

                      <Typography variant="inherit">Preço</Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={isDif}
                        onClick={() => handleCheckbox("DIF")}
                      />
                      <Typography variant="inherit" style={{ marginRight: 12 }}>
                        Diferencial
                      </Typography>
                    </div>
                  </div>
                </div>
                {isPreco && (
                  <TextField
                    variant="outlined"
                    className={isDif ? classes.inptHide : classes.inpt}
                    //placeholder="Preco em R$"
                    label="Preco em R$"
                    size="small"
                    value={preco}
                    error={errorPreco}
                    onChange={(e) => {
                      setValue("diferencial", "");
                      setPreco(replaceVirgulasToCommas(e.target.value));
                      setValue(
                        "preco",
                        replaceVirgulasToCommas(e.target.value)
                      );
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                {isDif ? (
                  !xEspecie ? (
                    <InputLabel
                      className={classes.inputLabel}
                    >{`Escolha uma especie para selecionar um vencimento`}</InputLabel>
                  ) : (
                    <>
                      {renderVencimentos(xEspecie, true)}
                      <TextField
                        variant="outlined"
                        className={isPreco ? classes.inptHide : classes.inpt}
                        //placeholder={`Diferencial em ${
                        //  xEspecie === "Arabica" ? "cts/lb" : "US$/MT"
                        //}`}
                        label={`Diferencial em ${
                          xEspecie === "Arabica" ? "cts/lb" : "US$/MT"
                        }`}
                        size="small"
                        error={errorDif}
                        onChange={(e) => {
                          setValue("diferencial", e.target.value);
                          setValue("preco", "");
                          setDif(e.target.value);
                        }}
                        value={dif}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </>
                  )
                ) : null}

                {isPreco && (
                  <ResumePreco
                    preco={preco}
                    cotacao={cotacao}
                    especie={xEspecie}
                  />
                )}

                {isDif && (
                  <ResumeDiferencial
                    dif={dif}
                    cotacao={cotacao}
                    vencimento={cotacao.vencimentos?.[xVencimento]}
                    especie={xEspecie}
                    oferta={getValues()}
                  />
                )}
              </div>
            </Section>

            <Section title="Peneiras">
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  columnGap: 16,
                }}
              >
                {peneirasRow.map((each) => (
                  <FormInput
                    field={each.key}
                    register={register}
                    errors={errors}
                    getValues={getValues}
                    setValue={setValue}
                    isPeneira
                  />
                ))}
              </div>
            </Section>

            <Section title="Complemento">
              <FlexWrapDiv>
                {complementoRow.map((eachColumn) => (
                  <FlexColumnDiv>
                    {eachColumn.map((each) => (
                      <FormInput
                        field={each.key}
                        register={register}
                        errors={errors}
                        getValues={getValues}
                        setValue={setValue}
                      />
                    ))}
                  </FlexColumnDiv>
                ))}
              </FlexWrapDiv>
            </Section>
            <Section title="Observações">
              <TextField
                multiline
                rows={3}
                maxRows={6}
                variant="outlined"
                fullWidth
                {...register("obs")}
              />
            </Section>
            <Section title="Imagem">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ImageUploader
                  src={selectedOfertaId ? watch("img") : preview}
                  handleUpload={
                    selectedOfertaId ? handleUpload : handlePrepareUpload
                  }
                  loading={loadingPhoto}
                  noSugestionSize
                  handleDelete={
                    selectedOfertaId ? handleDelete : () => setPreview(null)
                  }
                />
              </div>
            </Section>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 12,
              }}
            >
              <TCButton fullWidth loading={loading}>
                {`${selectedOfertaId ? "Editar " : "Cadastrar"}oferta`}
              </TCButton>
            </div>
          </Paper>
        </form>
      </Container>
    </Container>
  );
}
const defaultProps = {
  options: MUNICIPIOS,
  getOptionLabel: (option) => option.Nome,
};

const FormInput = ({
  field,
  register,
  errors,
  control,
  fullWidth,
  getValues,
  style,
  setValue,
  isPeneira,
}) => {
  let classes = useStyles();
  const currentInput = OBJETO_CAMPOS[field];
  if (currentInput?.tipoDeInput === "INPUT") {
    return (
      <TextField
        {...register(field)}
        error={errors[field]}
        fullWidth={fullWidth}
        size="small"
        variant="outlined"
        label={OBJETO_CAMPOS[field]?.display}
        style={{ marginBottom: 8, marginRight: 4, ...style }}
      />
    );
  }
  const xIsPercentage = currentInput?.tipoDeInput === "PORCENTAGEM";

  if (xIsPercentage) {
    return (
      <PercentageInput
        initialValue={getValues(field)}
        label={currentInput.display}
        setValue={setValue}
        register={register}
        field={field}
        errors={errors}
        isPeneira={isPeneira}
      />
    );
  }
  if (currentInput?.tipoDeInput === "SELECT" || xIsPercentage) {
    return (
      <FormControl
        style={{
          marginBottom: 8,
          maxWidth: 228,
          minWidth: 228,
        }}
        error={errors[field]}
        variant="outlined"
        fullWidth
        size="small"
      >
        <InputLabel
          shrink
          htmlFor="age-native-label-placeholder"
          className={classes.inputLabel}
        >
          {OBJETO_CAMPOS[field]?.display}
        </InputLabel>
        <Select
          {...register(field)}
          defaultValue={getValues ? getValues(field) : ""}
        >
          {field !== "modo" ? (
            <MenuItem value={""}>{`Sem ${currentInput.display}`}</MenuItem>
          ) : (
            <div />
          )}
          {xIsPercentage ? <MenuItem value={0}>X</MenuItem> : <div />}
          {xIsPercentage ? <MenuItem value={-1}>UP</MenuItem> : <div />}
          {getFromArrayOrObject(
            currentInput.valores,
            currentInput.tipoDeInput == "PORCENTAGEM"
          )}
        </Select>
      </FormControl>
    );
  }

  const xAutoCompleteDefault = getValues(field) || "";

  if (currentInput?.tipoDeInput === "AUTOCOMPLETE") {
    return (
      <Controller
        name={field}
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...defaultProps}
            defaultValue={{ Nome: xAutoCompleteDefault }}
            onChange={(e, data) => {
              return field.onChange(data?.Nome || "");
            }}
            style={{ width: 228 }}
            renderInput={(params) => (
              <TextField {...params} label={currentInput.display} />
            )}
          />
        )}
      />
    );
  }

  return <p>FIX THIS!</p>;
};

function CompraVendaButton({ onChange, defaultValue }) {
  const classes = useStyles();
  return (
    <RadioGroup
      onChange={(e) => onChange(e.target.value)}
      row
      defaultValue={defaultValue}
    >
      <FormControlLabel
        value={"C"}
        classes={{ label: classes.formcontrollabelBig }}
        control={
          <Radio
            classes={{
              root: classes.radioGreen,
              checked: classes.checked,
            }}
          />
        }
        label="Compra"
      />
      <FormControlLabel
        value={"V"}
        classes={{ label: classes.formcontrollabelBig }}
        control={
          <Radio
            classes={{
              root: classes.radioOrange,
              checked: classes.checked,
            }}
          />
        }
        label="Venda"
      />
    </RadioGroup>
  );
}

function ResumePreco({ preco, cotacao, especie }) {
  if (!especie || !preco) return null;
  const xIsArabica = especie === "Arabica";
  return (
    <Typography variant="body1">{`Com o preco de R$ ${preco} e o dollar de ${
      cotacao.dollar
    } temos um diferencial de ${
      xIsArabica
        ? calcularDiferencial(
            preco,
            getPrecoFinalNYNumber(cotacao),
            cotacao.dollar
          )
        : calcularDiferencialLondres(
            preco,
            getPrecoFinalLondresNumber(cotacao),
            cotacao.dollar
          )
    } ${xIsArabica ? "cts/lb" : "US$/MT"}`}</Typography>
  );
}

function ResumeDiferencial({ dif, cotacao, vencimento, especie, oferta }) {
  if (!especie || !dif) return null;
  const xIsArabica = especie === "Arabica";
  let xString = `Com um diferencial de ${dif} ${
    xIsArabica ? "cts/lb" : "US$/MT"
  } e dollar de R$ ${cotacao.dollar}, 1 saco de 60Kg de ${
    xIsArabica ? "ARABICA" : "ROBUSTA"
  } é igual a R$ `;
  xString += showPreco(oferta, cotacao);

  return (
    <div style={{ textAlign: "center", marginTop: 12 }}>
      <Typography variant="caption">{xString}</Typography>
    </div>
  );
}

function EmpresasSelect({
  classes,
  getValues,
  errors,
  watch,
  register,
  companies,
}) {
  return (
    <FormControl
      className={classes.formControl}
      error={errors["vencimentosKC"]}
      style={{ width: 233, marginBottom: 17 }}
    >
      <InputLabel shrink id="demo-simple-select-placeholder-label-label">
        Empresa
      </InputLabel>

      <Select value={watch("empresa")?._id} {...register("empresa")}>
        {companies.map((rCompany) => (
          <MenuItem value={rCompany._id}>{rCompany.nomeFantasia}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
