import axios from "axios";

function getCepInfo(pCep) {
  return hitBrasilAPI(pCep);
}

function hitViaCEP(pCep) {
  return axios
    .get(`https://viacep.com.br/ws/${pCep}/json/`)
    .then((rResp) => rResp.data);
}

function hitBrasilAPI(pCep) {
  return axios
    .get(`https://brasilapi.com.br/api/cep/v2/${pCep}`)
    .then((rResp) => rResp.data);
}

const outsideService = { getCepInfo };

export default outsideService;
