import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Button,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Clear } from "@material-ui/icons";
import empresaService from "@services/empresaService";
import { useStyles } from "@pages/Profile";
import { cnpjMask } from "../../utils/masks";
import * as yup from "yup";
import phoneRegExp from "@utils/phoneRegExp";
import ImageUploader from "@components/ImageUploader";
import showToast from "../Toast";
import { setAuthInfoCompany } from "../../redux/authReducer";
import { useDispatch, useSelector } from "react-redux";
import TCLoading from "../TCLoading";
import { phoneRegExpEmpty } from "../../utils/phoneRegExp";
import { TIPOS_EMPRESAS } from "../../utils/data/formFields";
import PhoneInput from "../../PhoneInput";

const schema = yup.object().shape({
  nomeFantasia: yup.string().required("Preencha o Nome Fantasia da empresa"),
  razaoSocial: yup.string().required("Preencha a Razao Social da empresa"),
  email: yup.string().required("Preencha o email").email("Email invalido"),
  telefone1: yup.string().matches(phoneRegExp, "Telefone invalido"),
  telefone2: yup.string().matches(phoneRegExpEmpty, "Telefone invalido"),
  tipo: yup.string().required("Tipo obrigatorio"),
});

export default function EditCompany({
  onCloseEdit,
  submitEditedCompany,
  setCompany,
  company,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: company, resolver: yupResolver(schema) });

  function handleUpload(e) {
    const formData = new FormData();

    formData.append("file", e.target.files[0]);
    setLoadingImg(true);

    return empresaService
      .uploadImg(company._id, formData)
      .then((rCompany) => {
        dispatch(setAuthInfoCompany({ ...rCompany, img: rCompany.img }));
        setValue("img", rCompany.img);
      })
      .catch((err) => {
        console.log(err);
        showToast.error(e.message);
      })
      .finally(() => setLoadingImg(false));
  }
  function handleDeleteImg() {
    setLoadingImg(true);
    empresaService
      .deleteImg(company?._id)
      .finally(() => {
        setCompany({ ...company, img: null });
        setValue("img", null);
      })
      .catch((e) => showToast.error(e.message))
      .finally(() => setLoadingImg(false));
  }

  return (
    <Box style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: 0, right: 0 }}>
        <IconButton onClick={onCloseEdit}>
          <Clear fontSize="large" />
        </IconButton>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}></div>
      {loading ? (
        <Box className={classes.center}>
          <TCLoading size={40} />
        </Box>
      ) : (
        <div
          style={{
            marginTop: 30,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <ImageUploader
            src={watch("img") || ""}
            handleUpload={handleUpload}
            loading={loadingImg}
            handleDelete={handleDeleteImg}
          />
          <form onSubmit={handleSubmit(submitEditedCompany)}>
            <div style={{ display: "flex", marginTop: 30 }}>
              <Typography variant="body1:" style={{ width: 80 }}>
                CNPJ:
              </Typography>
              <Typography variant="body2">{cnpjMask(watch("cnpj"))}</Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 20 }}
            >
              <Typography variant="body1:" style={{ width: 80 }}>
                Nome:
              </Typography>
              <Typography variant="body2">
                {getValues("nomeFantasia")}
              </Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 20 }}
            >
              <Typography variant="body1:" style={{ width: 80 }}>
                Razao Social:
              </Typography>
              <Typography variant="body2">
                {getValues("razaoSocial")}
              </Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 20 }}
            >
              <Typography variant="body1:" style={{ width: 80 }}>
                E-mail:
              </Typography>

              <TextField
                variant="outlined"
                size="small"
                error={errors["email"]}
                helperText={errors["email"]?.message}
                {...register("email")}
              />
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 20 }}
            >
              <Typography variant="body1:" style={{ width: 80 }}>
                Telefone1:
              </Typography>
              <PhoneInput
                classes={classes}
                errors={errors}
                register={register}
                name="telefone1"
                variant="outlined"
                value={watch("telefone1") || ""}
              />
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 20 }}
            >
              <Typography variant="body1:" style={{ width: 80 }}>
                Telefone2:
              </Typography>

              <PhoneInput
                classes={classes}
                errors={errors}
                register={register}
                variant="outlined"
                name="telefone2"
                value={watch("telefone2") || ""}
              />
            </div>
            <FormControl
              error={errors[`tipo`]}
              variant="outlined"
              fullWidth
              size="small"
              style={{ marginTop: 24, display: "flex", flexDirection: "row" }}
            >
              <Typography
                variant="body1:"
                style={{ width: 80, marginRight: 24 }}
              >
                Tipo de empresa:
              </Typography>
              <Select
                {...register(`tipo`)}
                style={{ width: "100%" }}
                defaultValue={getValues ? getValues(`tipo`) : ""}
                placeholder="Selecione..."
                variant="outlined"
                disabled
              >
                {TIPOS_EMPRESAS.map((each) => (
                  <MenuItem value={each}>{each}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              style={{ width: 200, marginTop: 20 }}
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => console.log("errors", errors)}
            >
              Salvar
            </Button>
          </form>
        </div>
      )}
    </Box>
  );
}
