import React from "react";
import { Container, Card, Typography, makeStyles } from "@material-ui/core";
import MainAnimation from "../components/MainAnimation";
import { Settings, WhatsApp } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { setIdx } from "@redux/ofertaReducer";
import showToast from "@components/Toast";

const useStyles = makeStyles((theme) => ({
  card: {
    height: 300,
    width: 300,
    cursor: "pointer",
    padding: 40,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    transition: "transform 0.3s ease, background-color 0.3s ease", // Add transition for transform and background-color
    "&:hover": {
      transform: "scale(1.05)", // Grow card on hover
    },
  },
}));
function QuickMessagesConfig({ history }) {
  let dispatch = useDispatch();
  const classes = useStyles();
  return (
    <MainAnimation>
      <Container
        maxWidth="md"
        style={{
          paddingTop: 40,
          display: "flex",
          gap: 24,
          justifyContent: "center",
        }}
      >
        <Card
          className={classes.card}
          onClick={() => {
            history.push("/feed");
            dispatch(setIdx(1));
            showToast.info("Utilize o incone do whatsapp para gerar mensagens");
          }}
        >
          <WhatsApp style={{ fontSize: 90 }} />
          <Typography>Gerar Mensagem</Typography>
        </Card>
        <Card
          className={classes.card}
          onClick={() => history.push("quick_messages_templates")}
        >
          <Settings style={{ fontSize: 90 }} />
          <Typography>Configurar Divulgações</Typography>
        </Card>
      </Container>
    </MainAnimation>
  );
}

export default QuickMessagesConfig;
