const INITIAL_STATE = {
  posts: [],
  favPosts: [],
  friendsPosts: [],
  myPosts: [],
  waitingPosts: [],
  replies: {},
};

const CLEAR_FORUM = "FORUM_REDUCER/CLEAR_FORUM";
const ADD_POST = "FORUM_REDUCER/ADD_POST";
const SET_POSTS = "FORUM_REDUCER/SET_POSTS";
const SET_FAVORITE_POSTS = "FORUM_REDUCER/SET_FAVORITE_POSTS";
const SET_FRIENDS_POSTS = "FORUM_REDUCER/SET_FRIENDS_POSTS";
const SET_MY_POSTS = "FORUM_REDUCER/SET_MY_POSTS";
const SET_WAITING_POSTS = "FORUM_REDUCER/SET_WAITING_POSTS";
const DELETE_REPLY = "FORUM_REDUCER/DELETE_REPLY";

const SET_CHANGE_ALL_LISTS = "FORUM_REDUCER/SET_CHANGE_ALL_LISTS";
const SET_REPLIES = "FORUM_REDUCER/SET_REPLIES";
const ADD_REPLY = "FORUM_REDUCER/ADD_REPLY";
const HANDLE_ADD_REPLY_COUNT = "FORUM_REDUCER/HANDLE_ADD_REPLY_COUNT";
const REMOVE_REPLY_COUNT = "FORUM_REDUCER/REMOVE_REPLY_COUNT";

export function clearForumReducer() {
  return {
    type: CLEAR_FORUM,
  };
}

export function setPosts(pPosts, pWaitingPosts = false) {
  return {
    type: SET_POSTS,
    payload: { posts: pPosts, waitingPosts: pWaitingPosts },
  };
}

export function setFavPosts(pPosts) {
  return {
    type: SET_FAVORITE_POSTS,
    payload: pPosts,
  };
}

export function setFriendsPosts(pPosts) {
  return {
    type: SET_FRIENDS_POSTS,
    payload: pPosts,
  };
}

export function setMyPosts(pPosts) {
  return {
    type: SET_MY_POSTS,
    payload: pPosts,
  };
}

export function setWaitingPosts(pPosts) {
  return {
    type: SET_WAITING_POSTS,
    payload: pPosts,
  };
}

export function addPost(pNewPost) {
  return {
    type: ADD_POST,
    payload: pNewPost,
  };
}

export function handleChangeAllLists(pPostId, pResponse, pMethod) {
  return {
    type: SET_CHANGE_ALL_LISTS,
    payload: { postId: pPostId, response: pResponse, method: pMethod },
  };
}

export function handleDeleteAllLists(pPostId) {
  return {
    type: SET_CHANGE_ALL_LISTS,
    payload: { postId: pPostId, method: "isDeleted" },
  };
}

export function handleDeleteReply(pPost) {
  return {
    type: DELETE_REPLY,
    payload: pPost,
  };
}

export function removeReplyCount() {
  return {
    type: REMOVE_REPLY_COUNT,
  };
}

export function setReplies(pPostId, pReplies) {
  return {
    type: SET_REPLIES,
    payload: {
      postId: pPostId,
      replies: pReplies,
    },
  };
}
export function addReply(pPostId, pReply) {
  return {
    type: ADD_REPLY,
    payload: {
      postId: pPostId,
      reply: pReply,
    },
  };
}

export function handleAddReplyCount(pPostId) {
  return {
    type: HANDLE_ADD_REPLY_COUNT,
    payload: { postId: pPostId, positive: true },
  };
}

export function handleDeleteReplyCount(pPostId) {
  return {
    type: HANDLE_ADD_REPLY_COUNT,
    payload: { postId: pPostId, positive: false },
  };
}

export default function forumReducer(state = INITIAL_STATE, action) {
  let copyPosts,
    copyFavoritePosts,
    foundIndex,
    foundFavIndex,
    newStates,
    foundFriendsIndex,
    copyFriendsPosts,
    copyMyPosts,
    foundMyIndex;

  switch (action.type) {
    case CLEAR_FORUM:
      return INITIAL_STATE;
    case SET_POSTS:
      return {
        ...state,
        posts: action.payload?.posts,
      };
    case ADD_POST:
      return {
        ...state,
        posts: [action.payload, ...state.posts],
      };

    case SET_FAVORITE_POSTS:
      return {
        ...state,
        favPosts: action.payload,
      };

    case SET_FRIENDS_POSTS:
      return {
        ...state,
        friendsPosts: action.payload,
      };

    case SET_MY_POSTS:
      return {
        ...state,
        myPosts: action.payload,
      };
    case SET_WAITING_POSTS:
      return {
        ...state,
        waitingPosts: action.payload,
      };

    case SET_REPLIES:
      return {
        ...state,
        replies: {
          ...state.replies,
          [action.payload.postId]: action.payload.replies,
        },
      };
    case ADD_REPLY:
      //If the replies weren't fetched before we shouldnt add them
      if (!state.replies[action.payload.postId]) {
        return state;
      }
      return {
        ...state,
        replies: {
          ...state.replies,
          [action.payload.postId]: [
            action.payload.reply,
            ...state.replies[action.payload.postId],
          ],
        },
      };

    case SET_CHANGE_ALL_LISTS:
      let xPostId = action.payload.postId;
      copyPosts = [...state.posts];
      foundIndex = copyPosts.findIndex((x) => x._id == xPostId);

      copyFavoritePosts = [...state.favPosts];
      foundFavIndex = copyFavoritePosts.findIndex((x) => x._id == xPostId);

      copyFriendsPosts = [...state.friendsPosts];
      foundFriendsIndex = copyFriendsPosts.findIndex((x) => x._id == xPostId);

      copyMyPosts = [...state.myPosts];
      foundMyIndex = copyMyPosts.findIndex((x) => x._id == xPostId);

      const xResp = action.payload.response;
      if (action.payload.method == "isLiked") {
        if (foundIndex >= 0) {
          xResp.isFavorite = copyPosts[foundIndex].isFavorite;
          copyPosts[foundIndex] = { ...copyPosts[foundIndex], ...xResp };
        }

        if (foundFavIndex >= 0) {
          xResp.isFavorite = copyFavoritePosts[foundFavIndex].isFavorite;
          copyFavoritePosts[foundFavIndex] = xResp;
        }

        if (foundFriendsIndex >= 0) {
          xResp.isFavorite = copyFriendsPosts[foundFriendsIndex].isFavorite;
          copyFriendsPosts[foundFriendsIndex] = xResp;
        }

        if (foundMyIndex >= 0) {
          xResp.isFavorite = copyMyPosts[foundMyIndex].isFavorite;
          copyMyPosts[foundMyIndex] = xResp;
        }
      }
      if (action.payload.method == "isFavorited") {
        if (copyPosts && foundIndex >= 0) {
          copyPosts[foundIndex].isFavorite = xResp.isFavorite;
        }

        if (foundFavIndex >= 0) {
          copyFavoritePosts[foundFavIndex].isFavorite = xResp.isFavorite;
        }

        if (foundFriendsIndex >= 0) {
          copyFriendsPosts[foundFriendsIndex].isFavorite = xResp.isFavorite;
        }

        if (foundMyIndex >= 0) {
          copyMyPosts[foundMyIndex].isFavorite = xResp.isFavorite;
        }
      }

      if (action.payload.method === "isDeleted") {
        if (copyPosts && foundIndex >= 0) {
          copyPosts.splice(foundIndex, 1);
        }

        if (foundFavIndex >= 0) {
          copyFavoritePosts.splice(foundFavIndex, 1);
        }

        if (foundFriendsIndex >= 0) {
          copyFriendsPosts.splice(foundFriendsIndex, 1);
        }

        if (foundMyIndex >= 0) {
          copyMyPosts.splice(foundMyIndex, 1);
        }
      }

      newStates = { ...state };
      newStates.posts = copyPosts;

      if (foundFavIndex >= 0) {
        newStates.favPosts = copyFavoritePosts;
      }
      if (foundFriendsIndex >= 0) {
        newStates.friendsPosts = copyFriendsPosts;
      }
      if (foundMyIndex >= 0) {
        newStates.myPosts = copyMyPosts;
      }
      return newStates;

    case HANDLE_ADD_REPLY_COUNT:
      copyPosts = [...state.posts];
      let postId = action.payload.postId;
      newStates = { ...state };
      let foundWaitingIndex = state.waitingPosts.findIndex(
        (x) => x._id == postId
      );
      if (foundWaitingIndex >= 0) {
        let copyWaitingPosts = [...state.waitingPosts];
        setReplyCount(
          copyWaitingPosts[foundWaitingIndex],
          action.payload.positive
        );

        newStates.waitingPosts = copyWaitingPosts;
        return newStates;
      }

      foundIndex = copyPosts.findIndex((x) => x._id === postId);

      if (foundIndex >= 0) {
        setReplyCount(copyPosts[foundIndex], action.payload.positive);
      }
      newStates.posts = copyPosts;

      copyFavoritePosts = [...state.favPosts];
      foundFavIndex = copyFavoritePosts.findIndex((x) => x._id === postId);

      if (foundFavIndex >= 0) {
        setReplyCount(
          copyFavoritePosts[foundFavIndex],
          action.payload.positive
        );

        newStates.favPosts = copyFavoritePosts;
      }

      copyFriendsPosts = [...state.friendsPosts];
      foundFriendsIndex = copyFriendsPosts.findIndex((x) => x._id === postId);

      if (foundFriendsIndex >= 0) {
        setReplyCount(
          copyFriendsPosts[foundFriendsIndex],
          action.payload.positive
        );

        newStates.friendsPosts = copyFriendsPosts;
      }

      copyMyPosts = [...state.myPosts];
      foundMyIndex = copyMyPosts.findIndex((x) => x._id === postId);

      if (foundMyIndex >= 0) {
        setReplyCount(copyMyPosts[foundMyIndex], action.payload.positive);
        newStates.myPosts = copyMyPosts;
      }

      return newStates;

    case DELETE_REPLY:
      const copyReplies = { ...state.replies };

      let copyPostReplies = copyReplies[action.payload.post].filter(
        (obj) => obj._id !== action.payload._id
      );

      copyReplies[action.payload.post] = copyPostReplies;

      return { ...state, replies: copyReplies };
    default:
      return state;
  }
}

const setReplyCount = (obj, isPositive) => {
  obj.repliesCount = isPositive
    ? obj.repliesCount + 1 || 1
    : obj.repliesCount - 1;
};
