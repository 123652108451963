import React from "react";
import { green, orange } from "@material-ui/core/colors";

export default function CVBadge({ modo }) {
  return (
    <div
      style={{
        paddingLeft: 8,
        paddingRight: 8,
        marginRight: 8,
        width: 6,
      }}
    >
      <span
        style={{
          color: "white",
          fontWeight: "bold",
          fontSize: 12,
          height: 17,
          width: 17,
          borderRadius: 10,
          backgroundColor: modo == "C" ? green[600] : orange[800],
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {modo}
      </span>
    </div>
  );
}
