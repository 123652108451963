import BEBIDA_BASE from "./data/bebidabase";
import showPreco from "./showPreco";

export default function buildOfertaMsg(pUser, pOferta, cotacao) {
  return `Ola, sou ${pUser?.name?.toUpperCase()} da empresa ${pUser?.empresa?.nomeFantasia?.toUpperCase()} e gostaria de informacoes sobre a sua oferta abaixo : \n\n${
    pOferta.modo == "C" ? "COMPRA" : "VENDA"
  } de ${pOferta.especie ? pOferta.especie : ``} ${
    pOferta.fava ? pOferta.fava : ``
  } ${
    pOferta.processamento ? pOferta.processamento : ``
    // }, bebida ${BEBIDA_BASE[pOferta.bebidaBase].detail},${
  } ${pOferta.bebidaBase ? `bebida ${pOferta.bebidaBase}` : ``} ${
    pOferta.catacao ? `com ${pOferta.catacao}% de catação` : ""
  } com o valor de R$ ${showPreco(pOferta, cotacao)}`;
}
