import chatService from "@services/chatService";
import store from "@redux/store";

const INITIAL_STATE = {
  chats: {},
  messages: {},
  unseenMessages: {},
  chatId: "",
};

const SET_CHATS = "CHAT_REDUCER/SET_CHATS";
const ADD_CHAT = "CHAT_REDUCER/ADD_CHAT";
const SET_MESSAGES = "CHAT_REDUCER/SET_MESSAGES";
const SET_CHAT_ID = "CHAT_REDUCER/SET_CHAT_ID";
const SET_LAST_MESSAGE = "CHAT_REDUCER/SET_LAST_MESSAGE";
const SEE_MESSAGES = "CHAT_REDUCER/SEE_MESSAGES";
const SET_UNSEEN_MESSAGES = "CHAT_REDUCER/SET_UNSEEN_MESSAGES";

export function setChats(pChats) {
  return { type: SET_CHATS, payload: pChats };
}

export function addChat(pChat) {
  return { type: ADD_CHAT, payload: pChat };
}

export function setMessages(pMessages) {
  return { type: SET_MESSAGES, payload: pMessages };
}
export function setChatId(pChatId) {
  return { type: SET_CHAT_ID, payload: pChatId };
}

export function seeMessages(pChatId) {
  return { type: SEE_MESSAGES, payload: pChatId };
}

export function setLastMessage(pMsgObj) {
  return {
    type: SET_LAST_MESSAGE,
    payload: pMsgObj,
  };
}

export function setUnseenMessages(pUnseenMessages) {
  return { type: SET_UNSEEN_MESSAGES, payload: pUnseenMessages };
}

export async function addMessageToRedux(
  pMessageObj,
  pFromBackEnd = false,
  pIsFromFeed = false
) {
  let dispatch = store.dispatch;
  let xStates = store.getState();
  let messages = xStates.chatReducer.messages;
  let unseenMessages = xStates.chatReducer.unseenMessages;
  let chats = xStates.chatReducer.chats;
  let chatId = xStates.chatReducer.chatId;
  let xMessagesObj = { ...messages };
  let xUnseenMessagesObj = { ...unseenMessages };
  let xChatId = pMessageObj.chat;

  let xIsSeeingTheMessageWhenReceive = chatId == xChatId;
  let xIsFromNewChat = !chats[xChatId];
  if (xIsFromNewChat) {
    let xNewChat = await chatService.getChatById(xChatId);
    dispatch(addChat(xNewChat));
  }
  if (xIsSeeingTheMessageWhenReceive) {
    if (pFromBackEnd) {
      chatService.visualizeMessages(xChatId);
    }
    if (!xMessagesObj[xChatId]) {
      xMessagesObj[xChatId] = [pMessageObj];
    } else {
      xMessagesObj[xChatId].splice(0, 0, pMessageObj);
    }
  } else {
    //If is not creating message from feed oferta
    if (!pIsFromFeed) {
      if (!xUnseenMessagesObj[xChatId]) {
        xUnseenMessagesObj[xChatId] = [pMessageObj];
      } else {
        xUnseenMessagesObj[xChatId].splice(0, 0, pMessageObj);
      }
    } else {
      if (!xMessagesObj[xChatId]) {
        xMessagesObj[xChatId] = [pMessageObj];
      } else {
        xMessagesObj[xChatId].splice(0, 0, pMessageObj);
      }
    }
  }

  dispatch(setLastMessage(pMessageObj));
  dispatch(setMessages(xMessagesObj));
  dispatch(setUnseenMessages(xUnseenMessagesObj));
}

export default function chatReducer(state = INITIAL_STATE, action) {
  let xChatId, xSeenMessages, xUnseenMessages;
  switch (action.type) {
    case SET_CHATS:
      return { ...state, chats: action.payload };
    case ADD_CHAT:
      return {
        ...state,
        chats: { ...state.chats, [action.payload._id]: action.payload },
      };

    case SET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    case SET_UNSEEN_MESSAGES:
      return {
        ...state,
        unseenMessages: action.payload,
      };
    case SET_CHAT_ID:
      return { ...state, chatId: action.payload };
    case SET_LAST_MESSAGE:
      xChatId = action.payload.chat;

      const x = {
        ...state,
        chats: {
          ...state.chats,
          [xChatId]: {
            ...state.chats[xChatId],
            ultimaMensagem: action.payload,
          },
        },
      };
      return x;
    case SEE_MESSAGES:
      xChatId = action.payload;
      xUnseenMessages = { ...state.unseenMessages };
      xSeenMessages = { ...state.messages };

      if (!xSeenMessages[xChatId]) {
        xSeenMessages[xChatId] = [];
      }
      if (!xUnseenMessages[xChatId]) {
        xUnseenMessages[xChatId] = [];
      }

      xSeenMessages[xChatId] = [
        ...xUnseenMessages[xChatId],
        ...xSeenMessages[xChatId],
      ];

      xUnseenMessages[xChatId] = [];

      return {
        ...state,
        messages: xSeenMessages,
        unseenMessages: xUnseenMessages,
      };
    default:
      return state;
  }
}
