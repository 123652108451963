import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import { useStyles } from "./Profile";
import { useSelector } from "react-redux";
import MainAnimation from "../components/MainAnimation";
import TCLoading from "../components/TCLoading";
import QuickMessageInput from "@components/QuickMessages/QuickMessageInput";
import TCButton from "../components/TCButton";
import { DeleteOutlineOutlined, Edit, FileCopy } from "@material-ui/icons";
import mensagensRapidaService from "@services/mensagensRapidasService";
import showToast from "@components/Toast";
import YesNoModal from "@components/YesNoModal";
import QuickMessageCopy from "../components/QuickMessages/QuickMessageCopy";
function QuickMessagesTemplates({ history }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  let [quickMessages, setQuickMessages] = useState({});
  let [selectedQuickMessage, setSelectedQuickMessage] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [minhasOfertas] = useSelector((states) => [
    states.ofertaReducer.minhasOfertas,
  ]);
  useEffect(() => {
    mensagensRapidaService
      .getMensagensRapidas()
      .then((m) => setQuickMessages(m))
      .catch((e) => {})
      .finally(() => setLoading(false));
  }, []);

  function onAdd(newQuickMessage) {
    if (!newQuickMessage.nome) {
      return showToast.error("Informe o Nome da Divulgação");
    }

    if (!newQuickMessage.value) {
      return showToast.error("Informe a Mensagem");
    }

    if(!newQuickMessage?.attributesToShow?.length){
      return showToast.error("Selecione ao menos uma coluna para mostrar")
    }
    
    setLoading(true);
    mensagensRapidaService
      .upsertMensagemRapida(newQuickMessage)
      .then((rMsgRapida) => {
        let copyQuickMessages = { ...quickMessages };
        copyQuickMessages[rMsgRapida?._id] = rMsgRapida;
        setQuickMessages(copyQuickMessages);
      })
      .catch((e) => showToast.error(e.message))
      .finally(() => setLoading(false));
    setIsEditing(false);
    setSelectedQuickMessage();
  }

  function deletarMensagemRapida(pMensagemRapidaId) {
    setLoading(true);
    mensagensRapidaService
      .deletarMensagemRapida(pMensagemRapidaId)
      .then((rMsgRapida) => {
        let copyQuickMessages = { ...quickMessages };
        delete copyQuickMessages[rMsgRapida?._id];
        setQuickMessages(copyQuickMessages);
      })
      .catch((e) => showToast.error(e.message))
      .finally(() => setLoading(false));
    setIsDeleting(false);
    setSelectedQuickMessage();
  }

  return (
    <MainAnimation>
      <Container
        maxWidth="sm"
        style={{ paddingTop: 40, display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 24,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FileCopy fontSize={"large"} />
            <Typography variant="h4" style={{ marginLeft: 12 }}>
              Divulgações
            </Typography>
          </div>
          <TCButton onClick={() => setIsEditing(true)}>Nova Divulgação</TCButton>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
          <YesNoModal
            showModal={isDeleting}
            title={`Deseja deletar ${quickMessages[selectedQuickMessage]?.nome}?`}
            onYes={() => {
              deletarMensagemRapida(selectedQuickMessage);
            }}
            handleClose={() => {
              setIsDeleting(false);
              setSelectedQuickMessage(undefined);
            }}
          />
          {((selectedQuickMessage || selectedQuickMessage === 0) &&
            !isDeleting) ||
          isEditing ? (
            <QuickMessageInput
              onAdd={onAdd}
              mensagemRapida={quickMessages[selectedQuickMessage] || {}}
              onClose={() => {
                setSelectedQuickMessage(undefined);
                setIsEditing(false);
              }}
            />
          ) : loading ? (
            <Box className={classes.center}>
              <TCLoading size={40} />
            </Box>
          ) : !Object.values(quickMessages)?.length ? (
            <Typography
              variant="body1"
              style={{ textAlign: "center", marginTop: 84 }}
            >
              Voce ainda não possui Divulgações.
            </Typography>
          ) : (
            <>
              {Object.values(quickMessages)?.map((quickMessage, i) => (
                <Paper
                  className={classes.paper}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    whiteSpace: "pre-wrap",
                    padding: 48,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                      {quickMessage?.nome}
                    </Typography>
                    <div style={{ alignSelf: "start", display: "flex" }}>
                      <IconButton
                        onClick={() =>
                          setSelectedQuickMessage(quickMessage?._id)
                        }
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setIsDeleting(true);
                          setSelectedQuickMessage(quickMessage?._id);
                        }}
                      >
                        <DeleteOutlineOutlined />
                      </IconButton>
                    </div>
                  </div>
                  <QuickMessageCopy
                    ofertas={minhasOfertas}
                    quickMessage={quickMessage}
                  />
                </Paper>
              ))}
            </>
          )}
        </div>
      </Container>
    </MainAnimation>
  );
}

export default QuickMessagesTemplates;
