import React, { useState } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ImgsViewer from "react-images-viewer";
import {
  FlexColumnDiv,
  FlexWrapDiv,
  FotoOferta,
  InfoEmpresa,
  Section,
  StyledFieldValue,
} from "../../DetailOffer";
import OBJETO_CAMPOS, {
  complementoRow,
  descricaoRow,
  peneirasRow,
} from "../../../utils/data/formFields";
import _ from "lodash";
import buildOfertaCab from "@utils/buildOfertaCab";
import TypographyLineBreak from "../../TypographyLineBreak";
import { grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/styles";
import { Container, Typography } from "@material-ui/core";
import { BlinkingPreco } from "./FeedTr";
import showPreco from "../../../utils/showPreco";
import { getPrecoStatus } from "../FeedTable/FeedTr";

function getPeneira(p) {
  if (!p && p !== 0) return null;
  if (p === -1) {
    return "UP";
  }
  if (p === 0) {
    return "X";
  }
  return `${p}%`;
}
function getPercentage(percentage) {
  if (percentage || percentage === 0) {
    return `${percentage}%`;
  }
  return "";
}

export default function OfertaDetails({ oferta, cotacao, handleFastChatOpen }) {
  const text = buildOfertaCab(oferta, cotacao);

  const [isImgOpen, setIsImgOpen] = useState(false);
  return (
    <TableRow>
      <TableCell colSpan={27}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <StyledDetailsDiv>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 8,
              }}
            >
              <Typography variant="caption">{text}</Typography>
              {!oferta.diferencial ? (
                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  {getPrecoStatus("fixo")}
                  R$ {showPreco(oferta, cotacao)}
                </div>
              ) : oferta.congelada && oferta.diferencial ? (
                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  {getPrecoStatus("congelado")}
                  R$ {showPreco(oferta, cotacao)}
                </div>
              ) : (
                <BlinkingPreco
                  preco={showPreco(oferta, cotacao)}
                  cotacao={cotacao}
                />
              )}
            </div>

            <InfoEmpresa
              ofertaId={oferta._id}
              empresa={oferta.empresa}
              handleFastChatOpen={handleFastChatOpen}
            />
            {oferta.numeroLote || oferta.mr ? (
              <Section title="Detalhes">
                {(oferta.numeroLote || oferta.mr) && (
                  <FlexWrapDiv>
                    <StyledFieldValue
                      field={"numeroLote"}
                      value={oferta.numeroLote}
                    />
                    <StyledFieldValue field={"mr"} value={oferta.mr} />
                  </FlexWrapDiv>
                )}

                {oferta.modo === "V" &&
                  (oferta.regiaoProdutora || oferta.municipio) && (
                    <>
                      <FlexWrapDiv>
                        <StyledFieldValue
                          field={"regiaoProdutora"}
                          value={oferta.regiaoProdutora}
                        />
                        <StyledFieldValue
                          field={"municipio"}
                          value={oferta.municipio}
                        />
                      </FlexWrapDiv>
                    </>
                  )}
              </Section>
            ) : null}
            <Section title="Descrição">
              <FlexWrapDiv>
                {descricaoRow.map((column) => (
                  <FlexColumnDiv>
                    {column.map((each) => (
                      <StyledFieldValue
                        field={each.key}
                        value={getRealName(oferta, each.key)}
                      />
                    ))}
                  </FlexColumnDiv>
                ))}
              </FlexWrapDiv>
            </Section>

            {checkIfHaveFields(oferta, peneirasRow) && (
              <Section title="Peneiras">
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    columnGap: 16,
                  }}
                >
                  {peneirasRow.map((each) => (
                    <StyledFieldValue
                      field={each.key}
                      value={getPeneira(oferta[each.key])}
                    />
                  ))}
                </div>
              </Section>
            )}

            {checkIfHaveFields(oferta, complementoRow, true) && (
              <Section title="Complemento">
                <FlexWrapDiv>
                  {complementoRow.map((eachColumn) => (
                    <FlexColumnDiv>
                      {eachColumn.map((each) => (
                        <StyledFieldValue
                          field={each.key}
                          value={getPercentage(oferta[each.key])}
                        />
                      ))}
                    </FlexColumnDiv>
                  ))}
                </FlexWrapDiv>
              </Section>
            )}
            {oferta.obs && (
              <Section title="Observações">
                <Container maxWidth="sm">
                  <TypographyLineBreak texto={oferta.obs} variant="body2" />
                </Container>
              </Section>
            )}

            <FotoOferta oferta={oferta} setIsImgOpen={setIsImgOpen} />
          </StyledDetailsDiv>
        </div>
      </TableCell>

      <ImgsViewer
        imgs={[{ src: oferta.img }]}
        isOpen={isImgOpen}
        onClose={() => setIsImgOpen(false)}
      />
    </TableRow>
  );
}

export function getRealName(oferta, eachKey) {
  const xVals = OBJETO_CAMPOS[eachKey]?.valores;
  if (_.isPlainObject(xVals)) {
    return (
      OBJETO_CAMPOS[eachKey]?.valores?.[oferta[eachKey]]?.detail ||
      oferta[eachKey]
    );
  }
  return oferta[eachKey];
}

function checkIfHaveFields(oferta, row, isComplemento) {
  let hasFields = false;

  row.map((each) => {
    if (isComplemento) {
      each.map((e) => {
        if (oferta[e?.key]) {
          hasFields = true;
        }
      });
    } else if (oferta[each?.key]) {
      hasFields = true;
    }
  });
  return hasFields;
}
const DetailsDiv = ({ children, classes }) => {
  return <div className={classes.det}>{children}</div>;
};
const StyledDetailsDiv = withStyles((theme) => ({
  det: {
    maxWidth: 1200,
    padding: 30,
    background: theme.palette.type === "dark" ? grey[900] : grey[300],
    margin: 30,
    border: "1px solid grey",
    borderRadius: 12,
  },
}))(DetailsDiv);
