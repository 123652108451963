import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import authService from "@services/authService";
import {
  makeStyles,
  TextField,
  Container,
  Card,
  Button,
  Typography,
} from "@material-ui/core";
import showToast from "../components/Toast";
import MainAnimation from "@components/MainAnimation";
import { useParams } from "react-router-dom";
import TCButton from "../components/TCButton";
import { useDispatch } from "react-redux";
import { setAuthInfo } from "@redux/authReducer";
import { setTokenLocalStorage } from "@utils/localstorage/tokenStorage";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    height: 600,
    justifyContent: "center",
    flexDirection: "column",
  },
  card: {
    padding: 18,
  },
  inpt: {
    marginBottom: 12,
  },
});

function PasswordReset({ history }) {
  const classes = useStyles();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const schema = pvGetSchema();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: {}, resolver: yupResolver(schema) });

  function handlePasswordReset(pData) {
    setLoading(true);

    return authService
      .resetPassword({ token, password: pData.password })
      .then((rAuthInfo) => {
        dispatch(setAuthInfo(rAuthInfo?.usuario));
        setTokenLocalStorage(rAuthInfo?.usuario?.authToken);
        history.push("/feed");
        showToast.success("Sua senha foi resetada com sucesso!")
      })
      .catch((e) => showToast.error(e.message))
      .finally(() => setLoading(false));
  }
  return (
    <MainAnimation>
      <Container className={classes.container}>
        <form onSubmit={handleSubmit(handlePasswordReset)}>
          <Card className={classes.card}>
            <div
              style={{
                width: 300,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" style={{ marginBottom: 12 }}>
                Insira sua nova senha
              </Typography>
              <TextField
                variant="outlined"
                className={classes.inpt}
                label={"Nova Senha"}
                helperText={errors["password"]?.message}
                error={errors["password"]}
                type="password"
                inputProps={{
                  form: {
                    autocomplete: 'off',
                  },
                }}
                {...register("password")}
              />

              <TextField
                variant="outlined"
                className={classes.inpt}
                label={"Confirme a senha"}
                type="password"
                helperText={errors["password2"]?.message}
                error={errors["password2"]}
                inputProps={{
                  form: {
                    autocomplete: 'off',
                  },
                }}
                {...register("password2")}
              />
              <TCButton loading={loading} size="medium">
                Redefinir
              </TCButton>
            </div>
          </Card>
        </form>
      </Container>
    </MainAnimation>
  );
}

export default PasswordReset;

function pvGetSchema() {
  return yup.object().shape({
    password: yup
      .string()
      .required("Preencha a senha")
      .min(8, "Senha deve ser maior que 8 caracteres"),
    password2: yup
      .string()
      .oneOf([yup.ref("password"), null], "Senhas devem ser iguais"),
  });
}
