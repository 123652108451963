import api from "@services/api";
import store from "../redux/store";
import checkIfPremium from "@utils/checkIfPremium";
import mixpanel from "@utils/mixpanel/mixpanel.js";
import {
  LISTA_OFERTAS_COM_FILTROS,
  OFERTA_CRIADA,
  OFERTA_DELETADA,
  OFERTA_EDITADA,
  OFERTA_PUBLICADA,
  INTERESSE_ENVIADO,
  OFERTA_REMOVIDA,
  OFERTA_CONGELADA,
  OFERTA_DESCONGELADA,
} from "../utils/mixpanel/mixpanelEvents";

function getOfertas(pFilters, pOfertasObj = {}) {
  const xUser = store.getState().authReducer?.user;
  const isPremium = checkIfPremium(xUser);

  if (!isPremium) {
    return getFreeOfertas(pFilters.modo);
  }
  const xFilter = pFilters ? `&filter=${JSON.stringify(pFilters)}` : "";
  const xPage = pOfertasObj.page ? pOfertasObj.page + 1 : 1;
  return api
    .get(`/oferta?page=${xPage}&limit=30${xFilter}`)
    .then((rOfertasObj) => {
      rOfertasObj.data = { ...pOfertasObj.data, ...rOfertasObj.data };
      if (pFilters && Object.values(pFilters || {}).length > 2) {
        mixpanel.track(LISTA_OFERTAS_COM_FILTROS, pFilters);
      }
      return rOfertasObj;
    });
}

function getFreeOfertas(pCV) {
  return api.get(`/oferta/free/${pCV}`);
}

async function getMinhasOfertas(pUser) {
  if (pUser.type === "A") {
    let promises = [api.get("/oferta/minhas"), getMinhasEmpresaOfertas()];
    return Promise.all(promises).then(
      ([rMinhasOfertas, rMinhasEmpresasOfertas]) => {
        return { ...rMinhasOfertas, ...rMinhasEmpresasOfertas };
      }
    );
  }
  return getMinhasEmpresaOfertas();
}

function getMinhasEmpresaOfertas() {
  return api.get("/oferta/minhas-empresa");
}

function deleteOferta(pOfertaId) {
  return api.delete(`/oferta/${pOfertaId}`).then((r) => {
    mixpanel.track(OFERTA_DELETADA, { ofertaId: pOfertaId });
    return r;
  });
}

function addOferta(pOferta) {
  return api.post("/oferta", pOferta).then((r) => {
    mixpanel.track(OFERTA_CRIADA, pOferta);
    return r;
  });
}

function editOferta(pOferta) {
  return api.put(`/oferta/${pOferta._id}`, pOferta).then((r) => {
    mixpanel.track(OFERTA_EDITADA, pOferta);
    return r;
  });
}

function publishOferta(pOfertaId, pShouldPublish = false) {
  const xYesNo = pShouldPublish ? "yes" : "no";
  return api.put(`/oferta/publish/${xYesNo}/${pOfertaId}`).then((r) => {
    mixpanel.track(
      `${pShouldPublish ? OFERTA_CONGELADA : OFERTA_DESCONGELADA}`,
      {
        ofertaId: pOfertaId,
      }
    );
    return r;
  });
}

function freezeOferta(pOfertaId, pPreco, pShouldFreeze = false) {
  const xYesNo = pShouldFreeze ? "yes" : "no";
  return api
    .put(`/oferta/freeze/${xYesNo}/${pOfertaId}`, { preco: pPreco })
    .then((r) => {
      mixpanel.track(` ${pShouldFreeze ? OFERTA_PUBLICADA : OFERTA_REMOVIDA}`, {
        ofertaId: pOfertaId,
      });
      return r;
    });
}

function sendInterestMessage(pDestinoUsuarioId, pOfertaId, pMsgObj) {
  return api
    .post(`/oferta/interest/${pOfertaId}/${pDestinoUsuarioId}`, pMsgObj)
    .then((r) => {
      mixpanel.track(INTERESSE_ENVIADO, { pMsgObj });
      return r;
    });
}

function createFakeInteresseOferta(pOfertaId) {
  return api.post(`/oferta/interesse-fake/${pOfertaId}`);
}

function uploadOfertaImg(pOfertaId, pFormData) {
  return api.post(`/upload/oferta/${pOfertaId}`, pFormData);
}

function deleteImg(pOfertaId) {
  return api.delete(`/upload/oferta/${pOfertaId}`);
}

const ofertaService = {
  getOfertas,
  deleteOferta,
  addOferta,
  editOferta,
  getFreeOfertas,
  getMinhasOfertas,
  publishOferta,
  sendInterestMessage,
  createFakeInteresseOferta,
  uploadOfertaImg,
  deleteImg,
  freezeOferta,
};

export default ofertaService;
