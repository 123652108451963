import React, { useState } from "react";
import {
  Divider,
  IconButton,
  makeStyles,
  Modal,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Clear } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { grey } from "@material-ui/core/colors";
import {
  calcularDiferencial,
  calcularPrecoArabicaDollar,
  calcularPrecoArabicaReal,
} from "../utils/cotacao";
import calculadoraService from "@services/calculadoraService";
import showToast from "@components/Toast";
import TCButton from "./TCButton";
import replaceVirgulasToCommas from "../utils/virgulaToComma";
import { getPrecoFinalNYNumber, getPrecoFinalNYNumberOrString } from "../utils/showPreco";

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2, 2),
    borderRadius: 6,
    width: 650,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    
  },
}));

export default function CalculatorModal({
  handleClose,
  showModal,
  dados,
  handleChange,
}) {
  const [loading, setLoading] = useState(false);
  const [cotacao, theme] = useSelector((states) => [
    states.cotacaoReducer,
    states.authReducer.theme,
  ]);
  const classes = useStyles();

  function handleSave() {
    setLoading(true);
    return calculadoraService
      .createUpdateDados(dados)
      .then((_) => {
        showToast.success("Dados salvos");
      })
      .catch((e) => showToast.error(e.message))
      .finally(() => setLoading(false));
  }
  return (
    <Modal 
      aria-labelledby="transition--famodal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal}>
        <div className={classes.paper}>
          <IconButton
            style={{ position: "absolute", top: 6, right: 6 }}
            onClick={handleClose}
          >
            <Clear fontSize="medium" />
          </IconButton>
          <Title>
            Cálculo dos Suportes e Resistencias para BMF com Diferencial de NY
          </Title>
          <table
            style={{
              width: "100%",
              padding: 12,
            }}
          >
            <tr>
              <th></th>
              <th>NY em cts/lb</th>
              <th>Diferencial em cts/lb</th>
              <th>BMF em US$</th>
            </tr>
            <div style={{ height: 6 }} />
            <CRow
              handleChange={handleChange}
              label="Resistencia 3"
              chave="r3"
              dados={dados}
              theme={theme}
            />
            <CRow
              handleChange={handleChange}
              label="Resistencia 2"
              chave="r2"
              dados={dados}
              theme={theme}
            />
            <CRow
              handleChange={handleChange}
              label="Resistencia 1"
              chave="r1"
              dados={dados}
              theme={theme}
            />

            <tr>
              <td align="center" style={{ paddingTop: 8, paddingBottom: 8 }}>
                NY
              </td>
              <td align="center">{getPrecoFinalNYNumberOrString(cotacao)}</td>
              <td align="center">
                <CustomInput
                  value={dados.dif}
                  theme={theme}
                  onChange={(text) => handleChange("dif", text)}
                />
              </td>
              <td align="center">
                {calcularPrecoArabicaDollar(
                  dados.dif,
                  getPrecoFinalNYNumber(cotacao)
                ).toFixed(2)}
              </td>
            </tr>
            <CRow
              handleChange={handleChange}
              label="Suporte 1"
              chave="s1"
              dados={dados}
              theme={theme}
            />
            <CRow
              handleChange={handleChange}
              label="Suporte 2"
              chave="s2"
              dados={dados}
              theme={theme}
            />
            <CRow
              handleChange={handleChange}
              label="Suporte 3"
              chave="s3"
              dados={dados}
              theme={theme}
            />
            <div style={{ height: 8 }} />
          </table>
          <Divider />
          <Title>Cálculo do Diferencial NY com o preco em Reais</Title>
          <div style={{ height: 12 }} />
          <CalcularDiferencialSection
            dados={dados}
            handleChange={handleChange}
          />
          <div style={{ height: 12 }} />
          <Divider />

          <div style={{ height: 12 }} />
          <TCButton loading={loading} onClick={handleSave}>
            Salvar
          </TCButton>
        </div>
      </Fade>
    </Modal>
  );
}

function CRow({ label, chave, dados, handleChange, theme }) {
  const xDol = calcularPrecoArabicaDollar(dados.dif, dados[chave])?.toFixed(2);
  return (
    <tr>
      <td align="center">{label}</td>
      <td align="center">
        <CustomInput
          theme={theme}
          value={dados[chave]}
          onChange={(text) => handleChange(chave, text)}
        />
      </td>
      <td align="center"></td>
      <td align="center">{xDol}</td>
    </tr>
  );
}

function CustomInput({ onChange, theme, value }) {
  return (
    <input
      style={{
        backgroundColor: theme === "DARK" ? grey[700] : grey[200],
        border: `1px solid grey`,
        maxWidth: 60,
        borderRadius: 2,
        color: theme === "DARK" ? "white" : "black",
      }}
      value={value}
      onChange={(e) => onChange(replaceVirgulasToCommas(e.target.value))}
    />
  );
}

function CalcularDiferencialSection({ dados, handleChange }) {
  const [theme] = useSelector((states) => [states.authReducer.theme]);

  return (
    <table style={{ width: "100%" }}>
      <tr>
        <th> Preco em R$</th>
        <th>NY em cts/lb</th>
        <th>Dolar</th>
        <th>Diferencial em cts/lb</th>
      </tr>
      <tr>
        <td align="center">
          <CustomInput
            value={dados.precoReal1}
            onChange={(text) => handleChange("precoReal1", text)}
            theme={theme}
          />
        </td>
        <td align="center">
          <CustomInput
            value={dados.ny1}
            onChange={(text) => handleChange("ny1", text)}
            theme={theme}
          />
        </td>
        <td align="center">
          <CustomInput
            value={dados.dollar1}
            onChange={(text) => handleChange("dollar1", text)}
            theme={theme}
          />
        </td>
        <td align="center">
          {calcularDiferencial(dados.precoReal1, dados.ny1, dados.dollar1)}
        </td>
      </tr>

      <div style={{ height: 12 }} />
      <tr>
        <td colSpan={4}>
          <Divider />
        </td>
      </tr>
      <tr>
        <td colSpan={4}>
          <Title>Cálculo do Preco em Reais com Diferencial NY</Title>
        </td>
      </tr>
      <div style={{ height: 12 }} />
      <CalcularRealSection dados={dados} handleChange={handleChange} />
    </table>
  );
}

function Title({ children }) {
  return <h3 style={{ textAlign: "center", marginBottom: 0 }}>{children}</h3>;
}
function CalcularRealSection({ dados, handleChange }) {
  const [theme] = useSelector((states) => [states.authReducer.theme]);

  return (
    <>
      <tr>
        <th>Diferencial em cts/lb</th>
        <th>NY em cts/lb</th>
        <th>Dolar</th>
        <th> Preco em R$</th>
      </tr>
      <tr>
        <td align="center">
          <CustomInput
            value={dados.dif2}
            onChange={(text) => handleChange("dif2", text)}
            theme={theme}
          />
        </td>
        <td align="center">
          <CustomInput
            value={dados.ny2}
            onChange={(text) => handleChange("ny2", text)}
            theme={theme}
          />
        </td>
        <td align="center">
          <CustomInput
            value={dados.dollar2}
            onChange={(text) => handleChange("dollar2", text)}
            theme={theme}
          />
        </td>
        <td align="center">
          {calcularPrecoArabicaReal(dados.dif2, dados.ny2, dados.dollar2)}
        </td>
      </tr>
    </>
  );
}
