import api from "@services/api";

function getFeedbacks() {
  return api.get("/feedback/new");
}

function getFeedbacksUtils() {
  return api.get("/feedback/util");
}

function postFeedback(pFeedback) {
  return api.post("/feedback", pFeedback);
}

function putFeedback(pFeedback) {
  return api.put("/feedback", pFeedback);
}

const feedbackService = {
  getFeedbacks,
  getFeedbacksUtils,
  postFeedback,
  putFeedback,
};

export default feedbackService;
