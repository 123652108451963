import api from "@services/api";

function getAdminInfo() {
  return api.get("/admin/info");
}

function getAdminEmpresasInfo() {
  return api.get("/admin/info/empresas");
}

function getAdminNewCompanies() {
  return api.get("/admin/empresa/confirm");
}

function getAdminNewCompaniesCount() {
  return api.get("/admin/empresa/confirm/count");
}

function adminAcceptCompany(pCompanyId) {
  return api.put(`/admin/empresa/confirm/${pCompanyId}`);
}

function adminRefuseCompany(pCompanyId) {
  return api.delete(`/admin/empresa/confirm/${pCompanyId}`);
}

function getFakeCompanies() {
  return api.get("/admin/empresa/fake");
}

function createFakeCompanies(pEmpresa) {
  return api.post("/admin/empresa/fake", pEmpresa);
}

function deleteFakeCompany(pEmpresaId) {
  return api.delete(`/admin/empresa/fake/${pEmpresaId}`);
}

function agreeDataSharingFakeCompany(pYesNo, pEmpresaId) {
  return api.put(`/admin/empresa/fake/agree/${pYesNo}/${pEmpresaId}`);
}

function addOferta(pOferta) {
  return api.post("/admin/oferta", pOferta);
}

function getFakeInteresseOfertas() {
  return api.get("/admin/oferta/interesse-fake");
}
function confirmFakeInteresseOferta(pFakeInteresseId) {
  return api.put(`/admin/oferta/interesse-fake/${pFakeInteresseId}`);
}

function getInvites() {
  return api.get(`/admin/invite`);
}

function getConnectedUsers() {
  return api.get(`/admin/connected_users`);
}

function putCotacao(pCotacao) {
  return api.put(`/admin/cotacao`,pCotacao);
}

const adminService = {
  getAdminInfo,
  getAdminNewCompanies,
  getAdminNewCompaniesCount,
  adminAcceptCompany,
  adminRefuseCompany,
  getFakeCompanies,
  createFakeCompanies,
  deleteFakeCompany,
  agreeDataSharingFakeCompany,
  addOferta,
  getFakeInteresseOfertas,
  confirmFakeInteresseOferta,
  getAdminEmpresasInfo,
  getInvites,
  putCotacao,
  getConnectedUsers
};

export default adminService;
