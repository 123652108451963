import React, { useEffect, useState } from "react";
import authService from "@services/authService";
import { makeStyles, Container } from "@material-ui/core";
import showToast from "../components/Toast";
import { useParams } from "react-router";
import TCLoading from "../components/TCLoading";
import { useDispatch } from "react-redux";
import { setAuthInfo } from "@redux/authReducer";
import { setTokenLocalStorage } from "@utils/localstorage/tokenStorage";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    height: 800,
    justifyContent: "center",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

function ConfirmAccount({ history }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { confirmToken } = useParams();

  useEffect(() => {
    handleConfirmAccount();
  }, []);
  function handleConfirmAccount() {
    authService
      .confirmAccount(confirmToken)
      .then((rAuthInfo) => {
        dispatch(setAuthInfo(rAuthInfo?.usuario));
        setTokenLocalStorage(rAuthInfo?.usuario?.authToken);
        history.push("/feed");
        showToast.success("Sua conta foi confirmada. Bem-vindo ao Café Trade!");
      })
      .catch((e) => {
        showToast.error(e.message);
      })
      .finally(() => setLoading(false));
  }
  return (
    <Container className={classes.container}>
      {loading ? <TCLoading size={40} /> : <h1>Um erro aconteceu</h1>}
    </Container>
  );
}

export default ConfirmAccount;
