import React from "react";
import { Box, Typography } from "@material-ui/core";
import Firefox from "@utils/images/firefox.png";
import Chrome from "@utils/images/chrome.png";
import Edge from "@utils/images/edge.png";
import Safari from "@utils/images/safari.png";

export default function Browsers({ browsers }) {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        flex: 1,
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", maxWidth: 300, flex: 0.8 }}>
        <BrowserDiv browsers={browsers} browser="chrome" />
        <BrowserDiv browsers={browsers} browser="firefox" />
        <BrowserDiv browsers={browsers} browser="safari" />
        <BrowserDiv browsers={browsers} browser="edge" />
      </div>
    </Box>
  );
}

function BrowserDiv({ browsers, browser }) {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
        marginTop: 18,
      }}
    >
      <Image browser={browser} />
      <Typography variant="h5">{browsers?.[browser]}</Typography>
    </Box>
  );
}
function Image({ browser }) {
  let xImg;
  const SIZE = 40;

  if (browser === "firefox") {
    xImg = Firefox;
  } else if (browser === "chrome") {
    xImg = Chrome;
  } else if (browser === "safari") {
    xImg = Safari;
  } else {
    xImg = Edge;
  }
  return <img src={xImg} width={SIZE} height={SIZE} />;
}
