import { createStore, combineReducers } from "redux";
import ofertaReducer from "@redux/ofertaReducer";
import authReducer from "@redux/authReducer";
import cotacaoReducer from "@redux/cotacaoReducer";
import chatReducer from "@redux/chatReducer";
import forumReducer from "@redux/forumReducer";

const combinedReducers = combineReducers({
  ofertaReducer,
  authReducer,
  cotacaoReducer,
  chatReducer,
  forumReducer,
});

const CLEAR_ALL = "ROOT_REDUCER/CLEAR_ALL";

export function clearAllReducers() {
  return { type: CLEAR_ALL };
}

const rootReducer = (state, action) => {
  if (action.type === CLEAR_ALL) {
    //keep theme
    state = { authReducer: { theme: state.authReducer.theme } };
  }
  return combinedReducers(state, action);
};

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
