import React from "react";
import { Typography } from "@material-ui/core";
import TCLoading from "../../TCLoading";
import { BotaoCadastrar } from "@pages/Feed";

export default function LoadingAndSemOfertas({ colspan, loading, isFeed }) {
  return (
    <tr>
      <td colSpan={colspan} align="center" height="583">
        {loading ? (
          <TCLoading size={50} />
        ) : isFeed ? (
          <div
            style={{
              marginTop: 300,
            }}
          >
            <Typography variant="subtitle1" align="center">
              Não existem ofertas publicadas.
            </Typography>
          </div>
        ) : (
          <div
            style={{
              height: 100,
              display: "flex",
              justifyContent: "center",
              gap: 8,
            }}
          >
            <Typography variant="subtitle1" align="center">
              Sua Empresa não possui ofertas.
            </Typography>
          </div>
        )}
      </td>
    </tr>
  );
}
