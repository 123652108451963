import React, { useEffect, useState } from "react";
import {
  makeStyles,
  InputAdornment,
  FormControl,
  InputLabel,
  Tooltip,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import { GREEN } from "@utils/themes";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 228,
    borderRadius: 4,
    overflow: "hidden",
  },
  input: {
    padding: "8px 12px",
    fontSize: 16,
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
  },
  progressBar: {
    height: 4,
    backgroundColor: theme.palette.type === "dark" ? grey[600] : grey[300],
    transition: "width 0.3s ease-in-out",
  },
  inputLabel: {
    backgroundColor: theme.palette.type === "dark" ? grey[800] : grey[100],
  },
  radio: {
    display: "flex",
    justifyContent: "center",
  },
  radioGreen: {
    "&$checked": {
      color: GREEN,
    },
  },
  radioOrange: {
    "&$checked": {
      color: theme.palette.secondary.main,
    },
  },
  checked: {},
}));

const PercentageInput = ({
  label,
  setValue,
  register,
  field,
  errors,
  initialValue,
  isPeneira,
  ...props
}) => {
  const classes = useStyles();
  let initialVal =
    initialValue === 0 ? `X` : initialValue === -1 ? `UP` : initialValue;
  const [percentage, setPercentage] = useState(initialVal || ``);

  // IMPORTANT
  // 0 => X, -1 => UP
  useEffect(() => {
    if (setValue) {
      if (percentage === `X`) {
        setValue(field, 0);
      } else if (percentage === `UP`) {
        setValue(field, -1);
      } else {
        setValue(field, percentage);
      }
    }
  }, [percentage]);

  const setPct = (value) => {
    const regex = /^(\d{0,2}(\.\d{0,1})?|100)$/;
    if (regex.test(value)) {
      setPercentage(value);
    }
  };
  const handleChange = (event) => {
    let value = event.target.value.trim();
    if (isPeneira) {
      if (value.toLowerCase() === "x") {
        setPercentage(`X`);
      } else if (value.toLowerCase() === "u") {
        // If the current state is "UP", set the state to "u" directly
        if (percentage.toUpperCase() === "UP") {
          setPercentage(``);
        } else {
          setPercentage(`UP`);
        }
      } else {
        setPct(value);
      }
    } else {
      setPct(value);
    }
  };

  const handleKeyPress = (event) => {
    // Prevent input if length is more than 4 (including decimal)
    if (event.target.value.length >= 4) {
      event.preventDefault();
    }
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.progressBar}
        style={{ width: `${parseFloat(percentage) || 0}%` }}
      />
      <FormControl
        style={{
          marginBottom: 8,
          maxWidth: 228,
          minWidth: 228,
        }}
        variant="outlined"
        fullWidth
        size="small"
      >
        <InputLabel
          shrink
          htmlFor="age-native-label-placeholder"
          className={classes.inputLabel}
        >
          {label}
        </InputLabel>
        <TextField
          variant="outlined"
          placeholder={isPeneira ? `UP, X, 99.5` : ``}
          size="small"
          value={percentage}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          autoComplete="off" // Turn off autocompletehandleChange
          {...props}
          InputProps={{
            endAdornment: (
              <Tooltip title={isPeneira ? `UP, X, 90.5` : ``}>
                <InputAdornment style={{ cursor: "pointer" }} position="end">
                  %
                </InputAdornment>
              </Tooltip>
            ),
          }}
        />
      </FormControl>
    </div>
  );
};

export default PercentageInput;
