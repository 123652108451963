import React, { useEffect, useState } from "react";
import { Container, Paper, makeStyles, Typography } from "@material-ui/core";
import MainAnimation from "../components/MainAnimation";
import paymentService from "@services/paymentService";
import showToast from "@components/Toast";
import CopyToClipboard from "@components/CopyToClipboard";
import { useLocation } from "react-router-dom";
import TCLoading from "../components/TCLoading";
import QRCode from 'qrcode';
import { useSelector } from "react-redux";

export const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
    width: "100%",
  },
  center: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: 408,
  },
}));

function Payment() {
  let location = useLocation();
  // Access the search parameters from the location object
  const searchParams = new URLSearchParams(location.search);
  
  // Get specific search parameters by name
  const planoId = searchParams.get('plano');
  
  const [planos] = useSelector((states) => [
    states.authReducer.planos,
  ]);
  
  
  let plano = planos?.find(plano=> plano.id === planoId)
  if (!plano?.id) {
    return <h3>{JSON.stringify(plano)}</h3>;
  }

  return (
    <MainAnimation>
      <Container
        maxWidth="md"
        style={{
          paddingTop: 20,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <QrCodePagamento selectedPlano={plano} />
      </Container>
    </MainAnimation>
  );
}

export default Payment;

export const useStyles2 = makeStyles((theme) => ({
  divImg: {
    flex: 0.35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 200,
    flexDirection: "column",
  },
  divDetails: {
    flex: 0.65,
    marginLeft: 24,
    [theme.breakpoints.down("xs")]: {
      marginTop: 12,
      marginLeft: 0,
    },
  },
  paper: {
    display: "flex",
    padding: 28,
    [theme.breakpoints.down("xs")]: {
      padding: 42,
      flexDirection: "column",
    },
  },
}));

const generateQRCode = async (pixCode) => {
  try {
    const qrCodeDataUrl = await QRCode.toDataURL(pixCode);
    return qrCodeDataUrl;
  } catch (error) {
    console.error("Erro ao gerar o QR Code:", error);
  }
};

const QrCodePagamento = ({ selectedPlano }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [cobranca, setCobranca] = useState();
  const [qrCode, setQrCode] = useState();
  const classes = useStyles2();
  useEffect(() => {
    setLoading(true);
    paymentService
      .getQrCode(selectedPlano.id)
      .then(async (rCobranca) => {
        setCobranca(rCobranca);
        setQrCode(await generateQRCode(rCobranca.copiaCola));
      })
      .catch((e) => {
        setError(true);
        showToast.error(e.message);
      })
      .finally(() => setLoading(false));
  }, []);

  if(loading){
    return(
      <Paper className={classes.paper} >
        <TCLoading size={40}/>
      </Paper>
    )
  }
  if(!cobranca){
    return(
      <Paper className={classes.paper} style={{display:'flex',flexDirection:'column'}}>
        Error
      </Paper>
    )
  }
  return (
    <Paper className={classes.paper} style={{display:'flex',flexDirection:'column'}}>
      <div style={{display:'flex'}}>
      {error ? null : (
        <div class={classes.divImg}>
            <div>
              <p style={{width:'100%',textAlign:'center'}}>PIX</p>
              <img
                src={qrCode}
                alt="QRcode imagem"
                style={{ width: 250, height: "auto", marginBottom: 12 }}
              />
            </div>
        </div>
      )}

      <div class={classes.divDetails}>
        <Typography variant="h5">Detalhes Plano</Typography>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <Typography component="h6" variant="h4" color="textPrimary">
            R$ {selectedPlano.preco}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            /Mês
          </Typography>
        </div>
        <Typography variant="caption">
          Sua empresa podera ter ate {selectedPlano.qtdUsuarios} usuario
          {selectedPlano.qtdUsuarios !== 1 && "s"}
        </Typography>
        <CopyToClipboard copyText={cobranca?.copiaCola} />
      </div>
      </div>
     
    </Paper>
  );
};
