import api from "@services/api";
import { getTokenLocalStorage } from "@utils/localstorage/tokenStorage";
import mixpanel from "@utils/mixpanel/mixpanel.js";
import { CONVITE_ENVIADO } from "../utils/mixpanel/mixpanelEvents";

function login(pEmail, pPassword) {
  return api.post("/auth/login", { email: pEmail, password: pPassword });
}

function sendInviteEmail(pData) {
  return api.post("/auth/send_invite_email", pData).then((r) => {
    mixpanel.track(CONVITE_ENVIADO, {
      emailConvidado: pData.email,
      convidado: pData.name,
    });
    return r;
  });
}

function register(pData) {
  return api.post("/auth/register", pData);
}

function editUser(pData) {
  return api.put("/auth/edit", pData);
}

function confirmAccount(pToken) {
  return api.post(`/auth/confirm_account/${pToken}`);
}

function getAuthFromToken() {
  const xToken = getTokenLocalStorage();
  if (!xToken) {
    return Promise.resolve();
  }
  return api.get("/auth/tokenInfo");
}

function forgotPassword(pEmail) {
  return api.post("/auth/forgot_password", { email: pEmail });
}
function resetPassword(pObj) {
  return api.post("/auth/reset_password", pObj);
}

const authService = {
  login,
  register,
  confirmAccount,
  getAuthFromToken,
  editUser,
  forgotPassword,
  sendInviteEmail,
  resetPassword,
};

export default authService;
