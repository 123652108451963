import api from "@services/api";

function getChats() {
  return api.get("/chat");
}

function getChatById(pChatId) {
  return api.get(`/chat/id/${pChatId}`);
}

function getMessages(pChatId, pMsgCount = 0) {
  return api.get(`/chat/messages/${pChatId}/${pMsgCount}`);
}

function getUnseenMessages() {
  return api.get(`/chat/messages/unseen`);
}

function visualizeMessages(pChatId) {
  return api.put(`/chat/${pChatId}`);
}

function startNewChatWithUser(pUserId) {
  return api.post(`/chat/${pUserId}`);
}
const chatService = {
  getChats,
  getChatById,
  getMessages,
  getUnseenMessages,
  visualizeMessages,
  startNewChatWithUser,
};

export default chatService;
