import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Register from "@pages/Register";
import empresaService from "@services/empresaService";
import { Typography } from "@material-ui/core";

//Register for companies Invites and TradeCafe Invites

function RegisterWithInvite({}) {
  const [invite, setInvite] = useState({});
  const [error, setError] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    empresaService
      .getInviteFromToken(token)
      .then((rInvite) => setInvite(rInvite))
      .catch((e) => setError(e.message));
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 600,
        flexDirection: "column",
      }}
    >
      {" "}
      {error ? (
        <h1>{error}</h1>
      ) : (
        <>
          <div style={{ display: "flex", marginTop: 200, gap: 12 }}>
            <Typography variant="h6">Bem vindo, </Typography>
            <Typography
              variant="h6"
              style={{ fontStyle: "bold"}}
            >
              {invite?.nome}
            </Typography>
          </div>
        <div style={{ marginTop: 24, gap: 12 }}>
            {console.log("invite",invite
                )}
              
              { invite?.empresa?.nomeFantasia? (
              <Typography variant="body1" align="center">
              Você foi convidado por
              {` ${invite?.usuario?.name}`} para se juntar{" "}
              {invite?.empresa?.nomeFantasia
                ? ` a empresa ${invite?.empresa?.nomeFantasia} n`
                : ` `}
              a nossa comunidade Café Trade.
            </Typography>):(
  <Typography variant="body1" align="center">
          Você foi convidado por {invite?.usuario?.name}, da empresa {invite?.usuario?.empresa?.nomeFantasia} para se juntar à nossa comunidade Café Trade, a plataforma especializada em anúncios de café verde.            </Typography>)
            }


            <Typography variant="subtitle1" align='center'>
    Estamos entusiasmados em tê-lo(a) conosco, para se juntar à nossa comunidade.

            </Typography>
     <Typography variant="subtitle1" align='center'>

  Por favor, preencha o formulário abaixo, com seus dados pessoais para concluir seu cadastro.
              </Typography>
           
          </div>

          <Register inviteToken={token} invite={invite} />
        </>
      )}
    </div>
  );
}

export default RegisterWithInvite;
