import React from "react";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";

const CEPInput = ({variant, classes, errors, register , name, label}) => {
  return (
    <InputMask mask="99999-999" {...register(name)} maskChar="">
      {(inputProps) => {
        return (
          <TextField
            placeholder="CEP"
            variant={variant}
            className={classes.inpt}
            label={label}
            error={errors[name]}
            helperText={errors[name]?.message}
            {...inputProps}
          />
        );
      }}
    </InputMask>
  );
};
export default CEPInput;
