import {
  Container,
  Card,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React from "react";
import IconLogoWhite from "../assets/icon_tc_white.png";
import { ErrorOutline } from "@material-ui/icons";
import TCButton from "./TCButton";
import mixpanel from "@utils/mixpanel/mixpanel.js";
import { ERROR_BOUNDARY } from "../utils/mixpanel/mixpanelEvents";
import { isLocal } from "../appConfig";
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.    return { hasError: true };  }
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    if (isLocal) console.error(`== Error Boundary ==>`, error);
    mixpanel.track(ERROR_BOUNDARY, { error });
  } // You can also log the error to an error reporting service    logErrorToMyService(error, errorInfo);  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI      return <h1>Something went wrong.</h1>;    }
      return <ErrorComponent />;
    }
    return this.props.children;
  }
}

const ErrorComponent = () => {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <AppBar position="static">
        <Toolbar>
          <img
            src={IconLogoWhite}
            width={23}
            height={23}
            alt="icon"
            style={{ marginRight: 8 }}
          />
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">
        <Card
          style={{
            marginTop: 24,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 24,
          }}
        >
          <ErrorOutline
            style={{ color: "red", marginBottom: 24 }}
            fontSize="large"
          />
          <Typography variant="subtitle1" style={{ marginBottom: 12 }}>
            Ops! Algo deu errado..
          </Typography>
          <Typography
            variant="caption"
            align="center"
            style={{ marginBottom: 12 }}
          >
            Nossa equipe já está ciente do problema e pede desculpas pelo
            inconveniente. Estamos trabalhando em uma solução e resolveremos o
            problema o mais breve possível
          </Typography>
          <TCButton onClick={() => window.location.reload()}>
            Reiniciar Aplicação
          </TCButton>
        </Card>
      </Container>
    </div>
  );
};
