import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import forumService from "@services/forumService";
import {
  makeStyles,
  Button,
  Typography,
  Paper,
  TextareaAutosize,
  Divider,
  Grid,
} from "@material-ui/core";
import PostList from "./PostList";
import { setFavPosts } from "../../redux/forumReducer";

function FavoriteForum({ }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [theme, user, favPosts] = useSelector((states) => [
    states.authReducer.theme,
    states.authReducer.user,
    states.forumReducer.favPosts,
  ]);

  //   const classes = useStyles(theme)();

  useEffect(() => {
    getFavPosts();
  }, []);

  function getFavPosts(pQtdFavPosts) {
    setLoading(true);
    const xArray = !pQtdFavPosts ? [] : favPosts;
    forumService
      .getFavoritePosts(pQtdFavPosts || 0)
      .then((rPosts) => {
        dispatch(setFavPosts([...xArray, ...rPosts]));
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }

  return (
    <Paper
      style={{
        width: "100%",
        minHeight: 800,
        padding: 20,
        marginBottom: 80,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Favoritos
      </Typography>
      <Divider />
      <PostList
        theme={theme}
        user={user}
        getPosts={getFavPosts}
        posts={favPosts}
        loading={loading}
      />
    </Paper>
  );
}

export default FavoriteForum;
