import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography, Card, makeStyles } from "@material-ui/core";
import { CameraFront } from "@material-ui/icons";
import InviteUserToCompanyModal from "../components/Company/InviteUserToCompanyModal";

export default function InviteToTradeCafe({ empresa }) {
  const [lista, setLista] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  function onSuccessInviteToCompany(pConvite) {
    const copyLista = { ...lista };
    copyLista.convites.push(pConvite);
    setLista(copyLista);
  }

  function sendMailModalOpen() {
    return setIsModalOpen(true);
  }

  return (
    <Box
      style={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CameraFront onClick={sendMailModalOpen} style={{ color: 'white' }} />

      <InviteUserToCompanyModal
        handleClose={() => {
          setIsModalOpen(false)
        }}
        showModal={isModalOpen}
        onSuccess={onSuccessInviteToCompany}
      />
    </Box>
  );
}
