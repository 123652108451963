const BEBIDA_BASE = {
  EM: {
    detail: "E. Mole",
    label: "Estritamente Mole",
    value: "E.Mole",
    sortingKey: "e____",
  },
  M: {
    detail: "Mole",
    label: "Mole",
    value: "Mole",
    sortingKey: "m____",
  },
  AM: {
    detail: "A. Mole",
    label: "Apenas Mole",
    value: "A.Mole",
    sortingKey: "am___",
  },
  Cn: {
    detail: "Conilon",
    label: "Conilon",
    value: "Conilon",
    sortingKey: "cn___",
  },
  D: {
    detail: "Duro",
    label: "Duro",
    value: "Duro",
    sortingKey: "d____",
  },
  DRd: {
    detail: "Duro/Riado",
    label: "Duro/Riado",
    value: "DuroRiado",
    sortingKey: "drd__",
  },
  DRdRio: {
    detail: "Duro/Riado/Rio",
    label: "Duro/Riado/Rio",
    value: "DuroRiadoRio",
    sortingKey: "drdrio",
  },
  Rd: {
    detail: "Riado",
    label: "Riado",
    value: "Riado",
    sortingKey: "rd___",
  },
  /*
  RdRio: {
    detail: "Riado/Rio",
    label: "RdRio - Riado/Rio",
    value: "RdRio",
    sortingKey: "rdrio",
  },
  */
  RdRio: {
    detail: "Riado/Rio",
    label: "Riado/Rio",
    value: "RiadoRio",
    sortingKey: "rdrio",
  },
  Rio: {
    detail: "Rio",
    label: "Rio",
    value: "Rio",
    sortingKey: "rio__",
  },
  RioZ: {
    detail: "Rio Zona",
    label: "Rio Zona",
    value: "RioZona",
    sortingKey: "rioz_",
  },
 
  
 };

export default BEBIDA_BASE;
