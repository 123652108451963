import React, { useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import PostInfo from "./PostInfo";
import forumService from "../../services/forumService";
import { useDispatch, useSelector } from "react-redux";
import { setReplies } from "../../redux/forumReducer";
import TCLoading from "../TCLoading";

export default function PostRepliesList({ post, theme, repliesList }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const user = useSelector((states) => states.authReducer.user);
  useEffect(() => {
    if (!repliesList?.length) {
      setLoading(true);
      forumService
        .getReplies(post._id)
        .then((rReplies) => {
          dispatch(setReplies(post._id, rReplies));
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
  }, []);
  return (
    <Collapse in timeout="auto" unmountOnExit>
      {loading ? (
        <RepliesLoading />
      ) : (
        repliesList
          ?.slice()
          .reverse()
          .map((rReplyPost) => (
            <PostReply post={rReplyPost} theme={theme} user={user} />
          ))
      )}
    </Collapse>
  );
}

function PostReply({ post, theme, user }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 50,
          paddingRight: 12,
        }}
      >
        <pre style={{ whiteSpace: `pre-wrap` }}>{post.texto}</pre>
        <PostInfo post={post} theme={theme} user={user} isReply />
      </div>
      <Divider />
    </>
  );
}

function RepliesLoading() {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        height: 40,
      }}
    >
      <TCLoading />
    </div>
  );
}
