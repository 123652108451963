import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  Container,
  Paper,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Business, Phone, PhoneForwarded, Check } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import MainAnimation from "@components/MainAnimation";
import adminService from "@services/adminService";
import showToast from "@components/Toast";
import CopyToClipboard from "@components/CopyToClipboard";
import TCLoading from "../TCLoading";

function FakeInteresseOferta() {
  const [fakeInteresseOfertas, setFakeInteresseOfertas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCompletar, setLoadingCompletar] = useState(false);

  const [user, theme] = useSelector((states) => [
    states.authReducer.user,
    states.authReducer.theme,
  ]);

  useEffect(() => {
    setLoading(true);
    adminService
      .getFakeInteresseOfertas()
      .then((rFakeInteresseOfertas) =>
        setFakeInteresseOfertas(rFakeInteresseOfertas)
      )
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, []);

  function handleCompletar(pId) {
    setLoadingCompletar(true);
    adminService
      .confirmFakeInteresseOferta(pId)
      .then(() => {
        let copyCompanies = [...fakeInteresseOfertas];
        copyCompanies = copyCompanies.filter((obj) => obj._id !== pId);

        showToast.success("Interesse Completo");
        setFakeInteresseOfertas(copyCompanies);
      })
      .catch((e) => showToast.error(e.message))
      .finally(() => setLoadingCompletar(false));
  }

  if (user?.type !== "A") {
    return <h2>Not admin</h2>;
  }
  return (
    <MainAnimation>
      <Container maxWidth="md" style={{ paddingTop: 20, display: "flex" }}>
        <Paper
          style={{
            width: "100%",
            padding: 24,
            minHeight: 300,
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 300,
              }}
            >
              <TCLoading size={40} />
            </div>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h4" gutterBottom>
                  Fake Interesse ofertas
                </Typography>
                <PhoneForwarded
                  style={{
                    fontSize: 35,
                  }}
                />
              </div>
              {fakeInteresseOfertas?.length ? (
                fakeInteresseOfertas?.map((rFakeInteresseOferta) => (
                  <FakeInteresseOfertaCard
                    key={rFakeInteresseOferta._id}
                    fakeInteresseOferta={rFakeInteresseOferta}
                    theme={theme}
                    updateParent={(id) => handleCompletar(id)}
                    loadingCompletar={loadingCompletar}
                  />
                ))
              ) : (
                <h2 style={{ marginTop: 90, textAlign: "center" }}>
                  Sem fake interesse de ofertas
                </h2>
              )}
            </>
          )}
        </Paper>
      </Container>
    </MainAnimation>
  );
}

function FakeInteresseOfertaCard({
  theme,
  fakeInteresseOferta,
  updateParent,
  loadingCompletar,
}) {
  return (
    <Card
      style={{
        margin: 8,
        marginTop: 30,
        paddingBottom: 12,
        padding: 10,
        backgroundColor: theme == "DARK" ? grey[700] : grey[300],
        position: "relative",
        transition: "all 0.2s ease-in-out",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <Typography variant="h6">Avisar a </Typography>
        <div style={{ display: "flex" }}>
          <Business style={{ marginRight: 8 }} />
          <Typography variant="h6">
            {fakeInteresseOferta?.oferta?.empresa?.nomeFantasia}
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle1" style={{ marginRight: 12 }}>
            {fakeInteresseOferta?.oferta?.empresa?.fakeUsuarioContato}
          </Typography>
          <Phone style={{ marginRight: 8 }} />
          <Typography variant="subtitle2">
            {fakeInteresseOferta?.oferta?.empresa?.telefone1}
          </Typography>
        </div>
      </div>
      <div>
        <Typography variant="h6">Interessado:</Typography>
        <div style={{ display: "flex" }}>
          <Business style={{ marginRight: 8 }} />
          <Typography variant="h6">
            {fakeInteresseOferta?.usuario?.empresa?.nomeFantasia}
          </Typography>
        </div>
        <Typography>
          {fakeInteresseOferta?.usuario?.empresa?.telefone1}
        </Typography>
      </div>
      <div>
        <Typography variant="h6">Oferta:</Typography>
        <div style={{ display: "flex" }}>
          <Typography>{fakeInteresseOferta?.oferta?.bebidaBase}</Typography>
          <Typography style={{ marginLeft: 12 }}>
            {fakeInteresseOferta?.oferta?.anoSafra}
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography>{fakeInteresseOferta?.oferta?.aspecto}</Typography>
          <Typography style={{ marginLeft: 12 }}>
            {fakeInteresseOferta?.oferta?.fava}
          </Typography>
          <Typography style={{ marginLeft: 12 }}>
            {fakeInteresseOferta?.oferta?.cor}
          </Typography>
          <Typography style={{ marginLeft: 12 }}>
            {fakeInteresseOferta?.oferta?.aspecto}
          </Typography>
        </div>
      </div>
      <CopyToClipboard
        copyText={
          "Ola, somos do Café Trade e temos um usuario interessado na sua oferta"
        }
        label="Copiar"
      />
      {loadingCompletar ? (
        <TCLoading />
      ) : (
        <div>
          <Typography variant="subtitle2">Completar</Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={() => updateParent(fakeInteresseOferta?._id)}>
              <Check />
            </IconButton>
          </div>
        </div>
      )}
    </Card>
  );
}

export default FakeInteresseOferta;
