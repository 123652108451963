import { Button } from "@material-ui/core";
import TCLoading from "./TCLoading";

export default function TCButton({
  onClick,
  variant,
  color,
  children,
  loading,
  ...props
}) {
  return (
    <Button
      type="submit"
      variant={variant || "contained"}
      color={color || "primary"}
      onClick={onClick}
      {...props}
      style={{ minWidth: 120,...props.style, textTransform: "none" }}
    >
      {loading ? <TCLoading /> : children}
    </Button>
  );
}
