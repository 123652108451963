import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Box,
  makeStyles,
  Typography,
  Divider,
} from "@material-ui/core";
import { Group } from "@material-ui/icons";
import { useParams } from "react-router";
import empresaService from "../services/empresaService";
import { useStyles } from "./Profile";
import PhotoAndInfo from "../components/PhotoAndInfo";
import EditCompany from "../components/Company/EditCompany";
import EditarMembros from "../components/Company/EditarMembros";
import showToast from "../components/Toast";
import { setAuthInfoCompany } from "../redux/authReducer";
import { useDispatch } from "react-redux";
import MainAnimation from "../components/MainAnimation";
import TCLoading from "../components/TCLoading";

function Company({ history }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const { companyId } = useParams();

  useEffect(() => {
    if (companyId) {
      empresaService
        .getCompany(companyId)
        .then((rCompany) => {
          setCompany(rCompany);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  function submitEditedCompany(pEditedCompany) {
    setLoading(true);

    return empresaService
      .editCompany(pEditedCompany)
      .then((rCompany) => {
        showToast.success("Empresa Editada");
        dispatch(setAuthInfoCompany(rCompany));
        setCompany(rCompany);
      })
      .catch((e) => {
        showToast.error("Um erro ocorreu");
        console.log(e);
      })
      .finally(() => setLoading(false));
  }

  return (
    <MainAnimation>
      <Container maxWidth="md" style={{ paddingTop: 20, display: "flex" }}>
        <Paper className={classes.paper}>
          {loading ? (
            <Box className={classes.center}>
              <TCLoading size={40} />
            </Box>
          ) : !isEditing ? (
            <Box>
              <PhotoAndInfo
                imgSrc={company?.img}
                nome={company?.nomeFantasia}
                email={company?.email}
                telefone1={company?.telefone1}
                telefone2={company?.telefone2}
                isEditable={company?.isAdmin}
                onClickToEdit={() => setIsEditing(true)}
                isCompany
              />

              <EditarMembros empresa={company} />
            </Box>
          ) : (
            <EditCompany
              onCloseEdit={() => setIsEditing(false)}
              submitEditedCompany={submitEditedCompany}
              company={company}
              setCompany={setCompany}
            />
          )}
        </Paper>
      </Container>
    </MainAnimation>
  );
}

export default Company;

export const useStyles2 = makeStyles((theme) => ({
  membro: {
    cursor: "pointer",
    height: 40,
    alignItems: "center",
    display: "flex",
    opacity: 0.8,
    "&:hover": {
      opacity: 1,
    },
  },
  membros: {
    marginTop: 60,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
}));
function Membros({ history, empresaId }) {
  const [lista, setLista] = useState({});

  useEffect(() => {
    empresaService
      .getMembros(empresaId)
      .then((rMembros) => {
        setLista(rMembros);
      })
      .catch((e) => console.log(e));
  }, []);
  const classes = useStyles2();

  return (
    <Box className={classes.membros}>
      <div style={{ maxWidth: 300 }}>
        <Box style={{ display: "flex" }}>
          <Group fontSize="large" />
          <Typography variant="h5" style={{ marginLeft: 20 }}>
            Membros
          </Typography>
        </Box>
        <Box style={{ marginLeft: 10 }}>
          {lista.membros?.map((rMembro) => (
            <div>
              <Box
                className={classes.membro}
                onClick={() => history.push(`/profile/${rMembro._id}`)}
              >
                <Typography variant="body2">{`•  ${rMembro.name}`}</Typography>
              </Box>
              <Divider />
            </div>
          ))}
        </Box>
      </div>
    </Box>
  );
}
