import TypographyLineBreak from "@components/TypographyLineBreak";
import { Checkbox, makeStyles, Modal, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import termos from "../../utils/termos/termos.txt";
import { useEffect, useState } from "react";
export const useStyles = makeStyles((theme) => ({
  modal: {
    top: 62,
    bottom: 62,
    paddingLeft: "22%",
    paddingRight: "22%",
    padding: 50,
    //     backgroundColor: pTheme !== "DARK" ? "white" : grey[800],
    position: "absolute",
    borderRadius: 4,
    overflowY: "scroll",
    [theme.breakpoints.down("md")]: {
      top: 12,
      left: 12,
      right: 12,
      bottom: 12,
      padding: 10,
      maxHeight: "200%",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
  },
}));

export default function Termos({ accepted, onAccept }) {
  let [open, setOpen] = useState(false);
  const [fileContent, setFileContent] = useState("");
  let classes = useStyles();

  useEffect(() => {
    const fetchFileContent = async () => {
      try {
        const response = await fetch(termos);
        const text = await response.text();
        setFileContent(text);
      } catch (error) {
        console.error("Error fetching the file:", error);
      }
    };

    fetchFileContent();
  }, []);

  return (
    <div style={{ display: "flex", gap: "4", alignItems: "center" }}>
      <Checkbox checked={accepted} onChange={onAccept} />
      <span style={{ marginRight: 8 }}>Aceito os </span>
      <a
        onClick={() => setOpen(true)}
        style={{ cursor: "pointer", textDecoration: "underline" }}
      >
        Termos de Uso
      </a>
      <Modal
        aria-labelledby="transition--famodal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        //         BackdropComponent={Backdrop}
        //         BackdropProps={{
        //           timeout: 500,
        //         }}
      >
        <div className={classes.paper}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">
              Termos de Uso e Condições de Navegação{" "}
            </Typography>
            <Close
              style={{
                position: "absolute",
                top: 14,
                right: 20,
                width: 27,
                height: 27,
                opacity: 0.7,
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            />
          </div>
          <TypographyLineBreak texto={fileContent} white variant="body2" />
        </div>
      </Modal>
    </div>
  );
}
