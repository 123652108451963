import React from "react";
import { CameraAlt, Clear } from "@material-ui/icons";
import { IconButton, Typography } from "@material-ui/core";
import TCLoading from "./TCLoading";

const commonStyles = (height) => ({
  height: height,
  width: height,
  cursor: "pointer",
  borderRadius: 12,
});

export default function ImageUploader({
  src,
  height = 118,
  handleUpload,
  loading,
  handleDelete,
  noSugestionSize
}) {
  return (
    <label
      style={{
        ...commonStyles(height),
        border: "1px dashed grey",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        position: "relative",
      }}
    >
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "none",
        }}
        onChange={handleUpload}
      />
      {loading && (
        <TCLoading
          size={40}
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      )}
      {src ? (
        <>
          <img src={dynamicPhoto(src)} style={{ ...commonStyles(height) }} alt="img" />
          <IconButton
            style={{
              color: "white",
              position: "absolute",
              backgroundColor: "grey",
              opacity: 0.6,
              top: 2,
              right: 2,
              padding: 6,
            }}
            onClick={handleDelete}
          >
            <Clear style={{ fontSize: 22 }} />
          </IconButton>
        </>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>

          <CameraAlt style={{ fontSize: 52, color: "grey" }} />
          {!noSugestionSize ? <Typography variant='caption'>190 x 190</Typography> : null}
        </div>
      )}
    </label>
  );
}

export function dynamicPhoto(src){
  // return `${src}?${new Date().getTime()}`
  return src;
}