import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Clear } from "@material-ui/icons";
import * as yup from "yup";
import phoneRegExp from "@utils/phoneRegExp";
import ImageUploader from "@components/ImageUploader";
import userService from "../../services/userService";
import showToast from "@components/Toast";
import { setAuthInfo } from "../../redux/authReducer";
import { useDispatch } from "react-redux";
import { phoneRegExpEmpty } from "../../utils/phoneRegExp";
import PhoneInput from "../../PhoneInput";

const schema = yup.object().shape({
  name: yup.string().required("Preencha seu o nome "),
  //   email: yup.string().required("Preencha o email").email("Email invalido"),
  telefone1: yup.string().matches(phoneRegExp, "Telefone invalido"),
  telefone2: yup.string().matches(phoneRegExpEmpty, "Telefone invalido"),
});

export default function EditProfile({
  onCloseEdit,
  submitEditedUser,
  user,
  setUser,
}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,

    formState: { errors },
  } = useForm({ defaultValues: user, resolver: yupResolver(schema) });

  function handleUpload(e) {
    const formData = new FormData();

    formData.append("file", e.target.files[0]);
    setLoading(true);

    return userService
      .uploadUserImg(user._id, formData)
      .then((rUser) => {
        dispatch(setAuthInfo({ ...user, img: rUser.img }));
        setValue("img", rUser.img);
      })
      .catch((err) => {
        console.log(err);
        showToast.error(e.message);
      })
      .finally(() => setLoading(false));
  }
  function handleDelete() {
    setLoading(true);
    userService
      .deleteUserImg(user?._id)
      .finally(() => {
        dispatch(setAuthInfo({ ...user, img: null }));

        setValue("img", null);
      })
      .catch((e) => showToast.error(e.message))
      .finally(() => setLoading(false));
  }
  return (
    <Box style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: 0, right: 0 }}>
        <IconButton onClick={onCloseEdit}>
          <Clear fontSize="large" />
        </IconButton>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}></div>

      <div
        style={{
          marginTop: 30,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <ImageUploader
          src={watch("img") || ""}
          handleUpload={handleUpload}
          loading={loading}
          handleDelete={handleDelete}
        />

        <form onSubmit={handleSubmit(submitEditedUser)}>
          {/* <div style={{ display: "flex", marginTop: 30 }}>
            <Typography variant="body1:" style={{ width: 80 }}>
              CPF:
            </Typography>
            <Typography variant="body2">{cpfMask(watch("cpf"))}</Typography>
          </div> */}
          <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
            <Typography variant="body1:" style={{ width: 80 }}>
              E-mail:
            </Typography>

            <Typography variant="body2">{watch("email")}</Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
            <Typography variant="body1:" style={{ width: 80 }}>
              Nome:
            </Typography>

            <TextField
              variant="outlined"
              size="small"
              error={errors["name"]}
              helperText={errors["name"]?.message}
              {...register("name")}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
            <Typography variant="body1:" style={{ width: 80 }}>
              Telefone1:
            </Typography>
            <PhoneInput
              classes={{}}
              errors={errors}
              register={register}
              name="telefone1"
              variant='outlined'
              value={watch("telefone1") || ""}

            />
          </div>
          {getValues().telefone2 && (
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 20 }}
            >
              <Typography variant="body1:" style={{ width: 80 }}>
                Telefone2:
              </Typography>

              <TextField
                variant="outlined"
                size="small"
                error={errors["telefone2"]}
                helperText={errors["telefone2"]?.message}
                {...register("telefone2")}
              />
            </div>
          )}
          <Button
            style={{ width: 200, marginTop: 20 }}
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => console.log("errors", errors)}
          >
            Salvar
          </Button>
        </form>
      </div>
      {/* )} */}
    </Box>
  );
}
