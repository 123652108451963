export default function replaceVirgulasToCommas(string) {
  const replaceString = string.replaceAll(",", ".");

  if (!replaceString.includes(".")) {
    if (replaceString?.length > 7) {
      return replaceString.slice(0, -1);
    }
    return replaceString;
  }

  if (replaceString.length == 1) {
    return replaceString.slice(0, -1);
  }

  if (replaceString.split(".")?.length > 2) {
    return replaceString.slice(0, -1);
  }
  const rightSide = replaceString.split(".")[1];
  if (rightSide && rightSide.length > 2) {
    return replaceString.slice(0, -1);
  }
  return replaceString;
}
