import React, { useEffect, useState } from "react";
import {
  Card,
  Divider,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Clear, WhatsApp } from "@material-ui/icons";
import { useSelector } from "react-redux";
import TCButton from "@components/TCButton";
import mensagensRapidaService from "@services/mensagensRapidasService";
import { grey } from "@material-ui/core/colors";
import CopyToClipboard from "@components/CopyToClipboard";
import QuickMessageCopy from "./QuickMessageCopy";

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2, 2),
    borderRadius: 6,
    width: 950,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export default function QuickMessageModal({
  handleClose,
  showModal,
  selectedOfertaId,
  dados,
  handleChange,
}) {
  const [quickMessages, setQuickMessages] = useState({});
  const [selectedQuickMessageId, setSelectedQuickMessageId] =
    useState(undefined);
  const [minhasOfertas] = useSelector((states) => [
    states.ofertaReducer.minhasOfertas,
  ]);
  const classes = useStyles();

  useEffect(() => {
    mensagensRapidaService
      .getMensagensRapidas()
      .catch((e) => {})
      .then((rQuickMessages) => setQuickMessages(rQuickMessages));
  }, []);

  return (
    <Modal
      aria-labelledby="transition--famodal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal}>
        <div className={classes.paper}>
          <IconButton
            style={{ position: "absolute", top: 6, right: 6 }}
            onClick={handleClose}
          >
            <Clear fontSize="medium" />
          </IconButton>
          <div style={{ display: "flex", alignItems: "center" }}>
            <WhatsApp fontSize="large" />
            <h1 style={{ paddingLeft: 8 }}>Mensagem Rapida</h1>
          </div>
          <Divider />
          <div style={{ height: 12 }} />

          <div style={{ display: "flex" }}>
            <div
              style={{ width: "30%", borderRight: `1px solid ${grey[500]}` }}
            >
              <span>Divulgações:</span>
              {Object.values(quickMessages)?.map((x) => (
                <MenuItem
                  style={{
                    padding: 12,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => setSelectedQuickMessageId(x._id)}
                  selected={x._id === selectedQuickMessageId}
                >
                  <span>{x.nome}</span>
                </MenuItem>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 34,
                width: "100%",
                whiteSpace: "pre-wrap",
              }}
            >
              {selectedQuickMessageId ? (
                <QuickMessageCopy
                  quickMessage={quickMessages[selectedQuickMessageId]}
                  ofertas={minhasOfertas}
                />
              ) : (
                <span>{"Selecione uma Divulgação"}</span>
              )}
            </div>
          </div>
          <div style={{ height: 12 }} />
        </div>
      </Fade>
    </Modal>
  );
}
