import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from "@material-ui/core";
import showPreco from "@utils/showPreco";
import { FileCopy } from "@material-ui/icons";
import OBJETO_CAMPOS from "../../utils/data/formFields";
import _ from "lodash";
import { useSelector } from "react-redux";
import mixpanel from "@utils/mixpanel/mixpanel.js";
import { DIVULGACAO_COPIADA } from "../../utils/mixpanel/mixpanelEvents";

export default function QuickMessageCopy({ quickMessage, ofertas }) {
  let { value, attributesGroupBy, attributesToShow } = quickMessage || {};
  const [user, cotacao] = useSelector((states) => [
    states.authReducer.user,
    states.cotacaoReducer,
  ]);
  const [copiedText, setCopiedText] = useState("");
  let style = { width: "100%", marginTop: 4 };
  style.display = "flex";
  style.justifyContent = "space-between";
  style.fontSize = 12;
  style.alignItems = "center";
  style.marginBottom = 12;

  if (!ofertas || !attributesToShow?.length) {
    return null;
  }

  // let copyOfertas = Object.values(ofertas)?.map((oferta) => {
  //   let newOferta = {};
  //   for (let key of Object.values(choosenOptions)) {
  //     newOferta[OBJETO_CAMPOS[key]?.display] = getRealName(oferta, key);
  //   }
  //   return newOferta;
  // });
  // Function to group data by specified attributes
  function groupBy(data, attributes) {
    return data.reduce((groups, item) => {
      const key = attributes.map((attr) => getRealName(item, attr)).join("|");
      (groups[key] = groups[key] || []).push(item);
      return groups;
    }, {});
  }

  // Function to format data according to selected attributes
  function formatData(ofertas, attributes) {
    return ofertas.map((oferta) => {
      return attributes
        .filter((attr) => attr)
        .map((attr) => {
          if (attr === `preco`) {
            let preco = showPreco(oferta, cotacao, user.empresa);
            return preco ? ` R$ ${preco}` : `Sem preco`;
          }
          return getRealName(oferta, attr);
        })
        .join(" ");
    });
  }

  // Group data by the specified attributes
  const groupedData = groupBy(Object.values(ofertas), attributesGroupBy);
  // Format data according to selected attributes
  const formattedData = Object.entries(groupedData).flatMap(
    ([group, items]) => {
      const formattedGroup = `\n- ${group}`;
      const formattedItems = formatData(items, attributesToShow);
      if (!group) {
        return formattedItems;
      }
      return [formattedGroup, ...formattedItems];
    }
  );

  function getRealName(oferta, eachKey) {
    if ( OBJETO_CAMPOS[eachKey]?.valores?.[oferta[eachKey]]?.detail) {
      let x = OBJETO_CAMPOS[eachKey]?.valores?.[oferta[eachKey]]?.detail;
      return OBJETO_CAMPOS[eachKey]?.valores?.[oferta[eachKey]]?.detail;
    }

    return oferta[eachKey];
  }

  let companyInfoString = user?.empresa?.nomeFantasia
    ? `\n\n ${user?.empresa?.nomeFantasia} \n${user?.name} \n${user.telefone1}`
    : ``;
  let stringSubstituted =
    quickMessage.value +
    `\n\n${formattedData.join("\n")}\n\n${
      quickMessage?.valueFooter || ""
    }${companyInfoString}`;

  return (
    <div style={style}>
      <span>{stringSubstituted}</span>
      <CopyToClipboard
        text={stringSubstituted}
        onCopy={() => {
          setCopiedText(stringSubstituted);
          mixpanel.track(DIVULGACAO_COPIADA, { quickMessage });
        }}
      >
        <div>
          <Tooltip
            title={copiedText === value ? "Copiado!" : "Clique para copiar"}
            placement="top"
          >
            <div style={{ cursor: "pointer", marginTop: 12 }}>
              <IconButton>
                <FileCopy fontSize="small" />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </CopyToClipboard>
    </div>
  );
}
