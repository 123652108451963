import axios from "axios";
import {
  clearLocalStorage,
  getTokenLocalStorage,
} from "@utils/localstorage/tokenStorage";
import showToast from "../components/Toast";
import { setAuthInfo } from "../redux/authReducer";
import { baseUrl } from "../appConfig";

const api = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;

export const setupInterceptors = (logout) => {
  api.interceptors.request.use(function (config) {
    let xToken = getTokenLocalStorage();
    if (xToken) {
      config.headers.Authorization = xToken;
    }
    return config;
  });

  api.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response?.data;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      if (error.message === "Network Error") {
        showToast.error(
          "Erro conectando ao servidor ",
          //  + error.toJSON().config.baseURL,
          1
        );
        return Promise.reject({
          status: 503,
        });
      }

      const xApiError = error?.response?.data;
      //If its a token error
      if (xApiError?.status === 401 && xApiError.code == 1) {
        logout(xApiError?.message, 1);
      }

      if (xApiError?.status === 402) {
        showToast.warning("Pagamento inexistente");
      }
      return Promise.reject(xApiError);
    }
  );
};
