import React from "react";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";

const PhoneInput = ({variant, classes, errors, register , name, label, value}) => {
  return (
    <InputMask mask="(99) 999999999" {...register(name)} maskChar="" value={value}>
      {(inputProps) => {
        return (
          <TextField
            type="tel"
            variant={variant}
            size="small"
            className={classes.inpt}
            label={label}
            error={errors[name]}
            helperText={errors[name]?.message}
            {...inputProps}
          />
        );
      }}
    </InputMask>
  );
};
export default PhoneInput;
