import api from "@services/api";
import mixpanel from "@utils/mixpanel/mixpanel.js";
import { CONVITE_PARA_EMPRESA_ENVIADO } from "@utils/mixpanel/mixpanelEvents.js";

function getCompany(pCompanyId) {
  return api.get(`/empresa/details/${pCompanyId}`);
}

function getCompanies() {
  return api.get("/empresa");
}

function registerCompany(pCompany) {
  return api.post("/empresa", pCompany);
}

function getFullInfoCompany(pCompanyId) {
  return api.get(`/empresa/full/${pCompanyId}`);
}

function editCompany(pCompany) {
  return api.put(`/empresa/${pCompany._id}`, pCompany);
}

function getMembros(pCompanyId) {
  return api.get(`/empresa/membros/${pCompanyId}`);
}

function getMembrosOferta(pCompanyId) {
  return api.get(`/empresa/membros_oferta/${pCompanyId}`);
}

function getInviteFromToken(pInviteToken) {
  return api.get(`/empresa/info_invite_token/${pInviteToken}`);
}

function inviteToCompany(pData, pEmpresaId) {
  let xConvite = {
    email: pData.email,
    name: pData.name,
    empresaId: pEmpresaId,
  };
  return api.post("/empresa/invite/usuario", xConvite).then((r) => {
    mixpanel.track(CONVITE_PARA_EMPRESA_ENVIADO, xConvite);
    return r;
  });
}

function deleteCompanyMember(pCompanyId, pUserId) {
  return api.delete(`/empresa/${pCompanyId}/membro/${pUserId}`);
}

function registerWithInvite(pUser) {
  return api.post("/auth/register_with_invite", pUser);
}

function deleteConvite(pConviteId) {
  return api.delete(`/empresa/invite/${pConviteId}`);
}

function uploadImg(pCompanyId, pFormData) {
  return api.post(`/upload/empresa/${pCompanyId}`, pFormData);
}

function deleteImg(pCompanyId) {
  return api.delete(`/upload/empresa/${pCompanyId}`);
}

function checkConvite() {
  return api.get(`/empresa/check-convite`);
}

function acceptConvite(pConviteId) {
  return api.put(`/empresa/agree/yes/${pConviteId}`);
}

function refuseConvite(pConviteId) {
  return api.put(`/empresa/agree/no/${pConviteId}`);
}

const empresaService = {
  getCompany,
  getCompanies,
  registerCompany,
  getFullInfoCompany,
  editCompany,
  getMembros,
  getMembrosOferta,
  getInviteFromToken,
  registerWithInvite,
  inviteToCompany,
  deleteConvite,
  uploadImg,
  deleteImg,
  deleteCompanyMember,
  checkConvite,
  acceptConvite,
  refuseConvite,
};

export default empresaService;
