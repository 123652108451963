export function cnpjMask(pCNPJ) {
  return pCNPJ?.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5"
  );
}

export function cpfMask(pCPF) {
  let xCPF = pCPF?.replace(/[^\d]/g, "");

  //realizar a formatação...
  return xCPF?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}
