export const VencimentoRcIds = {
  RMF22: "62eeaaa62348712cff0b1cfb",
  RMH22: "62eeaaa62348712cff0b1cfc",
  RMK22: "62eeaaa62348712cff0b1cfd",
  RMN22: "62eeaaa62348712cff0b1cfe",
  RMU22: "62eeaaa62348712cff0b1cff",
  RMX22: "62eeaaa62348712cff0b1d00",

  RMF23: "62eeaaa62348712cff0b1d01",
  RMH23: "62eeaaa62348712cff0b1d02",
  RMK23: "62eeaaa62348712cff0b1d03",
  RMN23: "62eeaaa62348712cff0b1d04",
  RMU23: "62eeaaa62348712cff0b1d05",
  RMX23: "62eeaaa62348712cff0b1d06",

  RMF24: "62eeaaa62348712cff0b1d07",
  RMH24: "62eeaaa62348712cff0b1d08",
  RMK24: "62eeaaa62348712cff0b1d09",
  RMN24: "62eeaaa62348712cff0b1d0a",
  RMU24: "62eeaaa62348712cff0b1d0b",
  RMX24: "62eeaaa62348712cff0b1d0c",

  RMF25: "62eeaaa62348712cff0b1d0d",
  RMH25: "62eeaaa62348712cff0b1d0e",
  RMK25: "62eeaaa62348712cff0b1d0f",
  RMN25: "62eeaaa62348712cff0b1d10",
  RMU25: "62eeaaa62348712cff0b1d11",
  RMX25: "62eeaaa62348712cff0b1d12",

  RMF26: "62eeaaa62348712cff0b1d13",
  RMH26: "62eeaaa62348712cff0b1d14",
  RMK26: "62eeaaa62348712cff0b1d15",
  RMN26: "62eeaaa62348712cff0b1d16",
  RMU26: "62eeaaa62348712cff0b1d17",
  RMX26: "62eeaaa62348712cff0b1d18",

  RMF27: "62eeaaa62348712cff0b1d19",
  RMH27: "62eeaaa62348712cff0b1d1a",
  RMK27: "62eeaaa62348712cff0b1d1b",
  RMN27: "62eeaaa62348712cff0b1d1c",
  RMU27: "62eeaaa62348712cff0b1d1d",
  RMX27: "62eeaaa62348712cff0b1d1e",

  RMF28: "62eeaaa62348712cff0b1d1f",
  RMH28: "62eeaaa62348712cff0b1d20",
  RMK28: "62eeaaa62348712cff0b1d21",
  RMN28: "62eeaaa62348712cff0b1d22",
  RMU28: "62eeaaa62348712cff0b1d23",
  RMX28: "62eeaaa62348712cff0b1d24",

  RMF29: "62eeaaa62348712cff0b1d25",
  RMH29: "62eeaaa62348712cff0b1d26",
  RMK29: "62eeaaa62348712cff0b1d27",
  RMN29: "62eeaaa62348712cff0b1d28",
  RMU29: "62eeaaa62348712cff0b1d29",
  RMX29: "62eeaaa62348712cff0b1d2a",

  RMF30: "62eeaaa62348712cff0b1d2b",
  RMH30: "62eeaaa62348712cff0b1d2c",
  RMK30: "62eeaaa62348712cff0b1d2d",
  RMN30: "62eeaaa62348712cff0b1d2e",
  RMU30: "62eeaaa62348712cff0b1d2f",
  RMX30: "62eeaaa62348712cff0b1d30",

  RMF31: "62eeaaa62348712cff0b1d31",
  RMH31: "62eeaaa62348712cff0b1d32",
  RMK31: "62eeaaa62348712cff0b1d33",
  RMN31: "62eeaaa62348712cff0b1d34",
  RMU31: "62eeaaa62348712cff0b1d35",
  RMX31: "62eeaaa62348712cff0b1d36",

  RMF32: "62eeaaa62348712cff0b1d37",
  RMH32: "62eeaaa62348712cff0b1d38",
  RMK32: "62eeaaa62348712cff0b1d39",
  RMN32: "62eeaaa62348712cff0b1d3a",
  RMU32: "62eeaaa62348712cff0b1d3b",
  RMX32: "62eeaaa62348712cff0b1d3c",

  RMF33: "62eeaaa62348712cff0b1d3d",
  RMH33: "62eeaaa62348712cff0b1d3e",
  RMK33: "62eeaaa62348712cff0b1d3f",
  RMN33: "62eeaaa62348712cff0b1d40",
  RMU33: "62eeaaa62348712cff0b1d41",
  RMX33: "62eeaaa62348712cff0b1d42",

  RMF34: "62eeaaa62348712cff0b1d43",
  RMH34: "62eeaaa62348712cff0b1d44",
  RMK34: "62eeaaa62348712cff0b1d45",
  RMN34: "62eeaaa62348712cff0b1d46",
  RMU34: "62eeaaa62348712cff0b1d47",
  RMX34: "62eeaaa62348712cff0b1d48",

  RMF35: "62eeaaa62348712cff0b1d49",
  RMH35: "62eeaaa62348712cff0b1d4a",
  RMK35: "62eeaaa62348712cff0b1d4b",
  RMN35: "62eeaaa62348712cff0b1d4c",
  RMU35: "62eeaaa62348712cff0b1d4d",
  RMX35: "62eeaaa62348712cff0b1d4e",

  RMF36: "62eeaaa62348712cff0b1d4f",
  RMH36: "62eeaaa62348712cff0b1d50",
  RMK36: "62eeaaa62348712cff0b1d51",
  RMN36: "62eeaaa62348712cff0b1d52",
  RMU36: "62eeaaa62348712cff0b1d53",
  RMX36: "62eeaaa62348712cff0b1d54",

  RMF37: "62eeaaa62348712cff0b1d55",
  RMH37: "62eeaaa62348712cff0b1d56",
  RMK37: "62eeaaa62348712cff0b1d57",
  RMN37: "62eeaaa62348712cff0b1d58",
  RMU37: "62eeaaa62348712cff0b1d59",
  RMX37: "62eeaaa62348712cff0b1d5a",

  RMF38: "62eeaaa62348712cff0b1d5b",
  RMH38: "62eeaaa62348712cff0b1d5c",
  RMK38: "62eeaaa62348712cff0b1d5d",
  RMN38: "62eeaaa62348712cff0b1d5e",
  RMU38: "62eeaaa62348712cff0b1d5f",
  RMX38: "62eeaaa62348712cff0b1d60",

  RMF39: "62eeaaa62348712cff0b1d61",
  RMH39: "62eeaaa62348712cff0b1d62",
  RMK39: "62eeaaa62348712cff0b1d63",
  RMN39: "62eeaaa62348712cff0b1d64",
  RMU39: "62eeaaa62348712cff0b1d65",
  RMX39: "62eeaaa62348712cff0b1d66",

  RMF40: "62eeaaa62348712cff0b1d67",
  RMH40: "62eeaaa62348712cff0b1d68",
  RMK40: "62eeaaa62348712cff0b1d69",
  RMN40: "62eeaaa62348712cff0b1d6a",
  RMU40: "62eeaaa62348712cff0b1d6b",
  RMX40: "62eeaaa62348712cff0b1d6c",

  RMF41: "62eeaaa62348712cff0b1d6d",
  RMH41: "62eeaaa62348712cff0b1d6e",
  RMK41: "62eeaaa62348712cff0b1d6f",
  RMN41: "62eeaaa62348712cff0b1d70",
  RMU41: "62eeaaa62348712cff0b1d71",
  RMX41: "62eeaaa62348712cff0b1d72",

  RMF42: "62eeaaa62348712cff0b1d73",
  RMH42: "62eeaaa62348712cff0b1d74",
  RMK42: "62eeaaa62348712cff0b1d75",
  RMN42: "62eeaaa62348712cff0b1d76",
  RMU42: "62eeaaa62348712cff0b1d77",
  RMX42: "62eeaaa62348712cff0b1d78",

  RMF43: "62eeaaa62348712cff0b1d79",
  RMH43: "62eeaaa62348712cff0b1d7a",
  RMK43: "62eeaaa62348712cff0b1d7b",
  RMN43: "62eeaaa62348712cff0b1d7c",
  RMU43: "62eeaaa62348712cff0b1d7d",
  RMX43: "62eeaaa62348712cff0b1d7e",

  RMF44: "62eeaaa62348712cff0b1d7f",
  RMH44: "62eeaaa62348712cff0b1d80",
  RMK44: "62eeaaa62348712cff0b1d81",
  RMN44: "62eeaaa62348712cff0b1d82",
  RMU44: "62eeaaa62348712cff0b1d83",
  RMX44: "62eeaaa62348712cff0b1d84",

  RMF45: "62eeaaa62348712cff0b1d85",
  RMH45: "62eeaaa62348712cff0b1d86",
  RMK45: "62eeaaa62348712cff0b1d87",
  RMN45: "62eeaaa62348712cff0b1d88",
  RMU45: "62eeaaa62348712cff0b1d89",
  RMX45: "62eeaaa62348712cff0b1d8a",

  RMF46: "62eeaaa62348712cff0b1d8b",
  RMH46: "62eeaaa62348712cff0b1d8c",
  RMK46: "62eeaaa62348712cff0b1d8d",
  RMN46: "62eeaaa62348712cff0b1d8e",
  RMU46: "62eeaaa62348712cff0b1d8f",
  RMX46: "62eeaaa62348712cff0b1d90",

  RMF47: "62eeaaa62348712cff0b1d91",
  RMH47: "62eeaaa62348712cff0b1d92",
  RMK47: "62eeaaa62348712cff0b1d93",
  RMN47: "62eeaaa62348712cff0b1d94",
  RMU47: "62eeaaa62348712cff0b1d95",
  RMX47: "62eeaaa62348712cff0b1d96",

  RMF48: "62eeaaa62348712cff0b1d97",
  RMH48: "62eeaaa62348712cff0b1d98",
  RMK48: "62eeaaa62348712cff0b1d99",
  RMN48: "62eeaaa62348712cff0b1d9a",
  RMU48: "62eeaaa62348712cff0b1d9b",
  RMX48: "62eeaaa62348712cff0b1d9c",

  RMF49: "62eeaaa62348712cff0b1d9d",
  RMH49: "62eeaaa62348712cff0b1d9e",
  RMK49: "62eeaaa62348712cff0b1d9f",
  RMN49: "62eeaaa62348712cff0b1da0",
  RMU49: "62eeaaa62348712cff0b1da1",
  RMX49: "62eeaaa62348712cff0b1da2",

  RMF50: "62eeaaa62348712cff0b1da3",
  RMH50: "62eeaaa62348712cff0b1da4",
  RMK50: "62eeaaa62348712cff0b1da5",
  RMN50: "62eeaaa62348712cff0b1da6",
  RMU50: "62eeaaa62348712cff0b1da7",
  RMX50: "62eeaaa62348712cff0b1da8",

  RMF51: "62eeaaa62348712cff0b1da9",
  RMH51: "62eeaaa62348712cff0b1daa",
  RMK51: "62eeaaa62348712cff0b1dab",
  RMN51: "62eeaaa62348712cff0b1dac",
  RMU51: "62eeaaa62348712cff0b1dad",
  RMX51: "62eeaaa62348712cff0b1dae",

  RMF52: "62eeaaa62348712cff0b1daf",
  RMH52: "62eeaaa62348712cff0b1db0",
  RMK52: "62eeaaa62348712cff0b1db1",
  RMN52: "62eeaaa62348712cff0b1db2",
  RMU52: "62eeaaa62348712cff0b1db3",
  RMX52: "62eeaaa62348712cff0b1db4",

  RMF53: "62eeaaa62348712cff0b1db5",
  RMH53: "62eeaaa62348712cff0b1db6",
  RMK53: "62eeaaa62348712cff0b1db7",
  RMN53: "62eeaaa62348712cff0b1db8",
  RMU53: "62eeaaa62348712cff0b1db9",
  RMX53: "62eeaaa62348712cff0b1dba",

  RMF54: "62eeaaa62348712cff0b1dbb",
  RMH54: "62eeaaa62348712cff0b1dbc",
  RMK54: "62eeaaa62348712cff0b1dbd",
  RMN54: "62eeaaa62348712cff0b1dbe",
  RMU54: "62eeaaa62348712cff0b1dbf",
  RMX54: "62eeaaa62348712cff0b1dc0",

  RMF55: "62eeaaa62348712cff0b1dc1",
  RMH55: "62eeaaa62348712cff0b1dc2",
  RMK55: "62eeaaa62348712cff0b1dc3",
  RMN55: "62eeaaa62348712cff0b1dc4",
  RMU55: "62eeaaa62348712cff0b1dc5",
  RMX55: "62eeaaa62348712cff0b1dc6",
};

export const VencimentosKCIds = {
  KCH22: "620ffbcd26e98fa2737f9dc8",
  KCK22: "620ffbcd26e98fa2737f9dc9",
  KCN22: "620ffbcd26e98fa2737f9dca",
  KCU22: "620ffbcd26e98fa2737f9dcb",
  KCZ22: "620ffbcd26e98fa2737f9dcc",

  KCH23: "620ffbcd26e98fa2737f9dcd",
  KCK23: "620ffbcd26e98fa2737f9dce",
  KCN23: "620ffbcd26e98fa2737f9dcf",
  KCU23: "620ffbcd26e98fa2737f9dd0",
  KCZ23: "620ffbcd26e98fa2737f9dd1",

  KCH24: "620ffbcd26e98fa2737f9dd2",
  KCK24: "620ffbcd26e98fa2737f9dd3",
  KCN24: "620ffbcd26e98fa2737f9dd4",
  KCU24: "620ffbcd26e98fa2737f9dd5",
  KCZ24: "620ffbcd26e98fa2737f9dd6",

  KCH25: "620ffbcd26e98fa2737f9dd7",
  KCK25: "620ffbcd26e98fa2737f9dd8",
  KCN25: "620ffbcd26e98fa2737f9dd9",
  KCU25: "620ffbcd26e98fa2737f9dda",
  KCZ25: "620ffbcd26e98fa2737f9ddb",

  KCH26: "620ffbcd26e98fa2737f9ddc",
  KCK26: "620ffbcd26e98fa2737f9ddd",
  KCN26: "620ffbcd26e98fa2737f9dde",
  KCU26: "620ffbcd26e98fa2737f9ddf",
  KCZ26: "620ffbcd26e98fa2737f9de0",

  KCH27: "620ffbcd26e98fa2737f9de1",
  KCK27: "620ffbcd26e98fa2737f9de2",
  KCN27: "620ffbcd26e98fa2737f9de3",
  KCU27: "620ffbcd26e98fa2737f9de4",
  KCZ27: "620ffbcd26e98fa2737f9de5",

  KCH28: "620ffbcd26e98fa2737f9de6",
  KCK28: "620ffbcd26e98fa2737f9de7",
  KCN28: "620ffbcd26e98fa2737f9de8",
  KCU28: "620ffbcd26e98fa2737f9de9",
  KCZ28: "620ffbcd26e98fa2737f9dea",

  KCH29: "620ffbcd26e98fa2737f9deb",
  KCK29: "620ffbcd26e98fa2737f9dec",
  KCN29: "620ffbcd26e98fa2737f9ded",
  KCU29: "620ffbcd26e98fa2737f9dee",
  KCZ29: "620ffbcd26e98fa2737f9def",

  KCH30: "620ffbcd26e98fa2737f9df0",
  KCK30: "620ffbcd26e98fa2737f9df1",
  KCN30: "620ffbcd26e98fa2737f9df2",
  KCU30: "620ffbcd26e98fa2737f9df3",
  KCZ30: "620ffbcd26e98fa2737f9df4",

  KCH31: "620ffbcd26e98fa2737f9df5",
  KCK31: "620ffbcd26e98fa2737f9df6",
  KCN31: "620ffbcd26e98fa2737f9df7",
  KCU31: "620ffbcd26e98fa2737f9df8",
  KCZ31: "620ffbcd26e98fa2737f9df9",

  KCH32: "620ffbcd26e98fa2737f9dfa",
  KCK32: "620ffbcd26e98fa2737f9dfb",
  KCN32: "620ffbcd26e98fa2737f9dfc",
  KCU32: "620ffbcd26e98fa2737f9dfd",
  KCZ32: "620ffbcd26e98fa2737f9dfe",

  KCH33: "620ffbcd26e98fa2737f9dff",
  KCK33: "620ffbcd26e98fa2737f9e00",
  KCN33: "620ffbcd26e98fa2737f9e01",
  KCU33: "620ffbcd26e98fa2737f9e02",
  KCZ33: "620ffbcd26e98fa2737f9e03",

  KCH34: "620ffbcd26e98fa2737f9e04",
  KCK34: "620ffbcd26e98fa2737f9e05",
  KCN34: "620ffbcd26e98fa2737f9e06",
  KCU34: "620ffbcd26e98fa2737f9e07",
  KCZ34: "620ffbcd26e98fa2737f9e08",

  KCH35: "620ffbcd26e98fa2737f9e09",
  KCK35: "620ffbcd26e98fa2737f9e0a",
  KCN35: "620ffbcd26e98fa2737f9e0b",
  KCU35: "620ffbcd26e98fa2737f9e0c",
  KCZ35: "620ffbcd26e98fa2737f9e0d",

  KCH36: "620ffbcd26e98fa2737f9e0e",
  KCK36: "620ffbcd26e98fa2737f9e0f",
  KCN36: "620ffbcd26e98fa2737f9e10",
  KCU36: "620ffbcd26e98fa2737f9e11",
  KCZ36: "620ffbcd26e98fa2737f9e12",

  KCH37: "620ffbcd26e98fa2737f9e13",
  KCK37: "620ffbcd26e98fa2737f9e14",
  KCN37: "620ffbcd26e98fa2737f9e15",
  KCU37: "620ffbcd26e98fa2737f9e16",
  KCZ37: "620ffbcd26e98fa2737f9e17",

  KCH38: "620ffbcd26e98fa2737f9e18",
  KCK38: "620ffbcd26e98fa2737f9e19",
  KCN38: "620ffbcd26e98fa2737f9e1a",
  KCU38: "620ffbcd26e98fa2737f9e1b",
  KCZ38: "620ffbcd26e98fa2737f9e1c",

  KCH39: "620ffbcd26e98fa2737f9e1d",
  KCK39: "620ffbcd26e98fa2737f9e1e",
  KCN39: "620ffbcd26e98fa2737f9e1f",
  KCU39: "620ffbcd26e98fa2737f9e20",
  KCZ39: "620ffbcd26e98fa2737f9e21",

  KCH40: "620ffbcd26e98fa2737f9e22",
  KCK40: "620ffbcd26e98fa2737f9e23",
  KCN40: "620ffbcd26e98fa2737f9e24",
  KCU40: "620ffbcd26e98fa2737f9e25",
  KCZ40: "620ffbcd26e98fa2737f9e26",

  KCH41: "620ffbcd26e98fa2737f9e27",
  KCK41: "620ffbcd26e98fa2737f9e28",
  KCN41: "620ffbcd26e98fa2737f9e29",
  KCU41: "620ffbcd26e98fa2737f9e2a",
  KCZ41: "620ffbcd26e98fa2737f9e2b",

  KCH42: "620ffbcd26e98fa2737f9e2c",
  KCK42: "620ffbcd26e98fa2737f9e2d",
  KCN42: "620ffbcd26e98fa2737f9e2e",
  KCU42: "620ffbcd26e98fa2737f9e2f",
  KCZ42: "620ffbcd26e98fa2737f9e30",

  KCH43: "620ffbcd26e98fa2737f9e31",
  KCK43: "620ffbcd26e98fa2737f9e32",
  KCN43: "620ffbcd26e98fa2737f9e33",
  KCU43: "620ffbcd26e98fa2737f9e34",
  KCZ43: "620ffbcd26e98fa2737f9e35",

  KCH44: "620ffbcd26e98fa2737f9e36",
  KCK44: "620ffbcd26e98fa2737f9e37",
  KCN44: "620ffbcd26e98fa2737f9e38",
  KCU44: "620ffbcd26e98fa2737f9e39",
  KCZ44: "620ffbcd26e98fa2737f9e3a",

  KCH45: "620ffbcd26e98fa2737f9e3b",
  KCK45: "620ffbcd26e98fa2737f9e3c",
  KCN45: "620ffbcd26e98fa2737f9e3d",
  KCU45: "620ffbcd26e98fa2737f9e3e",
  KCZ45: "620ffbcd26e98fa2737f9e3f",

  KCH46: "620ffbcd26e98fa2737f9e40",
  KCK46: "620ffbcd26e98fa2737f9e41",
  KCN46: "620ffbcd26e98fa2737f9e42",
  KCU46: "620ffbcd26e98fa2737f9e43",
  KCZ46: "620ffbcd26e98fa2737f9e44",

  KCH47: "620ffbcd26e98fa2737f9e45",
  KCK47: "620ffbcd26e98fa2737f9e46",
  KCN47: "620ffbcd26e98fa2737f9e47",
  KCU47: "620ffbcd26e98fa2737f9e48",
  KCZ47: "620ffbcd26e98fa2737f9e49",

  KCH48: "620ffbcd26e98fa2737f9e4a",
  KCK48: "620ffbcd26e98fa2737f9e4b",
  KCN48: "620ffbcd26e98fa2737f9e4c",
  KCU48: "620ffbcd26e98fa2737f9e4d",
  KCZ48: "620ffbcd26e98fa2737f9e4e",

  KCH49: "620ffbcd26e98fa2737f9e4f",
  KCK49: "620ffbcd26e98fa2737f9e50",
  KCN49: "620ffbcd26e98fa2737f9e51",
  KCU49: "620ffbcd26e98fa2737f9e52",
  KCZ49: "620ffbcd26e98fa2737f9e53",

  KCH50: "620ffbcd26e98fa2737f9e54",
  KCK50: "620ffbcd26e98fa2737f9e55",
  KCN50: "620ffbcd26e98fa2737f9e56",
  KCU50: "620ffbcd26e98fa2737f9e57",
  KCZ50: "620ffbcd26e98fa2737f9e58",

  KCH51: "620ffbcd26e98fa2737f9e59",
  KCK51: "620ffbcd26e98fa2737f9e5a",
  KCN51: "620ffbcd26e98fa2737f9e5b",
  KCU51: "620ffbcd26e98fa2737f9e5c",
  KCZ51: "620ffbcd26e98fa2737f9e5d",

  KCH52: "620ffbcd26e98fa2737f9e5e",
  KCK52: "620ffbcd26e98fa2737f9e5f",
  KCN52: "620ffbcd26e98fa2737f9e60",
  KCU52: "620ffbcd26e98fa2737f9e61",
  KCZ52: "620ffbcd26e98fa2737f9e62",

  KCH53: "620ffbcd26e98fa2737f9e63",
  KCK53: "620ffbcd26e98fa2737f9e64",
  KCN53: "620ffbcd26e98fa2737f9e65",
  KCU53: "620ffbcd26e98fa2737f9e66",
  KCZ53: "620ffbcd26e98fa2737f9e67",

  KCH54: "620ffbcd26e98fa2737f9e68",
  KCK54: "620ffbcd26e98fa2737f9e69",
  KCN54: "620ffbcd26e98fa2737f9e6a",
  KCU54: "620ffbcd26e98fa2737f9e6b",
  KCZ54: "620ffbcd26e98fa2737f9e6c",

  KCH55: "620ffbcd26e98fa2737f9e6d",
  KCK55: "620ffbcd26e98fa2737f9e6e",
  KCN55: "620ffbcd26e98fa2737f9e6f",
  KCU55: "620ffbcd26e98fa2737f9e70",
  KCZ55: "620ffbcd26e98fa2737f9e71",
};

let vencimentos = { ...VencimentoRcIds, ...VencimentosKCIds };
export default vencimentos;
