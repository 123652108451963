import React, { useEffect, useState } from "react";
import { Container, Paper, TableHead, Box } from "@material-ui/core";
import MainAnimation from "@components/MainAnimation";
import adminService from "@services/adminService";
import Browsers from "./Browsers";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TCLoading from "../TCLoading";

function AdminInfo() {
  const classes = useStyles();
  const [adminInfo, setAdminInfo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    adminService
      .getAdminInfo()
      .then((rAdminInfo) => setAdminInfo(rAdminInfo))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, []);

  return (
    <MainAnimation>
      <Container maxWidth="md" style={{ paddingTop: 20, display: "flex" }}>
        <Paper className={classes.paper}>
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: 600,
              }}
            >
              <TCLoading size={40} />
            </div>
          ) : (
            <>
              <InfoAdmin adminInfo={adminInfo} />
              <UsuariosAdmin adminInfo={adminInfo} />
            </>
          )}
        </Paper>
      </Container>
    </MainAnimation>
  );
}

export default AdminInfo;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "inherit",
  },
  body: {
    backgroundColor: "inherit",
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: 24,
    minHeight: 300,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  infoAdminDiv: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    marginRight: 24,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginBottom: 8,
    },
  },
}));

function UsuariosAdmin({ adminInfo }) {
  const classes = useStyles();

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ fontSize: 20 }}>
                Usuarios
              </StyledTableCell>
              <StyledTableCell align="center">Novos</StyledTableCell>
              <StyledTableCell align="center">Confirmed</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>Dia</StyledTableCell>

              <StyledTableCell align="center">
                {adminInfo?.usersOneDay}
              </StyledTableCell>
              <StyledTableCell align="center">
                {adminInfo?.usersOneDayConfirmed}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Semana</StyledTableCell>

              <StyledTableCell align="center">
                {adminInfo?.usersOneWeek}
              </StyledTableCell>
              <StyledTableCell align="center">
                {adminInfo?.usersOneWeekConfirmed}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Mes</StyledTableCell>

              <StyledTableCell align="center">
                {adminInfo?.usersOneMonth}
              </StyledTableCell>
              <StyledTableCell align="center">
                {adminInfo?.usersOneMonthConfirmed}
              </StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell>Total</StyledTableCell>

              <StyledTableCell align="center">
                {adminInfo?.usersTotal}
              </StyledTableCell>
              <StyledTableCell align="center">
                {adminInfo?.usersTotalConfirmed}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Browsers browsers={adminInfo?.browsers} />
    </div>
  );
}

function InfoAdmin({ adminInfo }) {
  const classes = useStyles();

  return (
    <Box className={classes.infoAdminDiv}>
      <TableContainer component={Paper} className={classes.table}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ fontSize: 20 }}>
                Informações
              </StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>Empresas</StyledTableCell>

              <StyledTableCell align="center">
                {adminInfo?.empresasTotal}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Convites</StyledTableCell>

              <StyledTableCell align="center">
                {adminInfo?.convitesTotal}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Convites completos</StyledTableCell>

              <StyledTableCell align="center">
                {adminInfo?.convitesCompleted}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Amizades </StyledTableCell>

              <StyledTableCell align="center">
                {adminInfo?.amizadesTotal}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Chats</StyledTableCell>

              <StyledTableCell align="center">
                {adminInfo?.chatsTotal}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Ofertas</StyledTableCell>

              <StyledTableCell align="center">
                {adminInfo?.ofertasTotal}
              </StyledTableCell>
            </TableRow>
          </TableBody>
          <TableRow>
            <StyledTableCell>Mensagens</StyledTableCell>

            <StyledTableCell align="center">
              {adminInfo?.mensagensTotal}
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Mensagens de interesse de ofertas</StyledTableCell>

            <StyledTableCell align="center">
              {adminInfo?.mensagensOfertaTotal}
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Posts no forum</StyledTableCell>

            <StyledTableCell align="center">
              {adminInfo?.postsTotal}
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Respostas a Posts</StyledTableCell>

            <StyledTableCell align="center">
              {adminInfo?.postsRepliesTotal}
            </StyledTableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </Box>
  );
}
