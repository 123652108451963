import api from "@services/api";

function getAmizadeStatus(pUserId) {
  return api.get(`/amizade/status/${pUserId}`);
}

function getAmizades() {
  return api.get(`/amizade`);
}
function criarPedidoAmizade(pUserId) {
  return api.post(`/amizade/${pUserId}`);
}

function deletarAmizade(pAmizadeId) {
  return api.delete(`/amizade/${pAmizadeId}`);
}
function deletarPedidoAmizade(pPedidoId) {
  return api.delete(`/amizade/pedido/${pPedidoId}`);
}
function aceitarPedidoAmizade(pPedidoId) {
  return api.put(`/amizade/pedido/${pPedidoId}`);
}

const amizadeService = {
  getAmizades,
  getAmizadeStatus,
  criarPedidoAmizade,
  deletarAmizade,
  deletarPedidoAmizade,
  aceitarPedidoAmizade,
};

export default amizadeService;
