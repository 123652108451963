import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import MainAnimation from "../components/MainAnimation";
import { useSelector } from "react-redux";
import { HourglassEmpty } from "@material-ui/icons";

export default function WaitingCompanyConfirm({ history }) {
  const user = useSelector((states) => states.authReducer.user);
  useEffect(() => {
    if (user?.empresa?.confirmed) {
      history.push("/feed");
    }
  }, [user?._id]);
  return (
    <MainAnimation>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 600,
          flexDirection: "column",
        }}
      >
        <HourglassEmpty style={{ fontSize: 50 }} />
        <Typography variant="h5" style={{ marginTop: 24, textAlign: "center" }}>
          Estamos revisando os detalhes da sua empresa.
        </Typography>

        <Typography
          variant="h6"
          style={{ marginBottom: 24, textAlign: "center" }}
        >
          Enviaremos um e-mail assim que sua empresa for aceita em nosso sistema.
        </Typography>
      </div>
    </MainAnimation>
  );
}
