module.exports = (pDate, onlyHours = false) => {
  if (!pDate) {
    return "";
  }
  const date = new Date(pDate);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  let hr = date.getHours();
  let min = date.getMinutes();

  if (hr < 10) {
    hr = "0" + hr;
  }
  if (min < 10) {
    min = "0" + min;
  }
  if (dt < 10) {
    dt = "0" + dt;
  }
  // if (month < 10) {
  //   month = "0" + month;
  // }

  if (onlyHours) {
    return `${hr}:${min}`;
  }
  return `${dt} ${MONTHS[month]} ${hr}:${min}`;
};

module.exports.isToday = (pDate) => {
  var today = new Date();
  const xDate = new Date(pDate);
  return today.toDateString() == xDate.toDateString();
};

const MONTHS = {
  1: "Jan",
  2: "Fev",
  3: "Mar",
  4: "Abr",
  5: "Mai",
  6: "Jun",
  7: "Jul",
  8: "Ago",
  9: "Set",
  10: "Out",
  11: "Nov",
  12: "Dez",
};
