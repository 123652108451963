import api from "@services/api";
import mixpanel from "@utils/mixpanel/mixpanel.js";
import { CALCULADORA_SALVA } from "../utils/mixpanel/mixpanelEvents";

function getDados() {
  return api.get("/calculadora");
}

function createUpdateDados(pData) {
  return api.post("/calculadora", pData).then((r) => {
    mixpanel.track(CALCULADORA_SALVA, pData);
    return r;
  });
}

const authService = {
  getDados,
  createUpdateDados,
};

export default authService;
