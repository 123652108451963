import Routes from "./routes";
import { Provider, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import store from "./redux/store";
import { createTheme, ThemeProvider, CssBaseline } from "@material-ui/core";
import themes from "@utils/themes";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "./components/ErrorBoundary";

function CustomThemeProvider({ children }) {
  const theme = useSelector((state) => state.authReducer.theme);

  return (
    <ThemeProvider theme={createTheme(themes[theme])}>
      <CssBaseline />
      <ToastContainer autoClose={2200} />
      {children}
    </ThemeProvider>
  );
}
function App({}) {
  return (
    <Provider store={store}>
      <CustomThemeProvider>
        <ErrorBoundary>
          <Routes />  
        </ErrorBoundary>
      </CustomThemeProvider>
    </Provider>
  );
}

export default App;

// https://react-move-docs.netlify.app/demos/node-group
