import React, { useState } from "react";
import {
  Container,
  Paper,
  Typography,
  Radio,
  TextField,
  IconButton,
  Box,
  Button,
} from "@material-ui/core";
import { useStyles } from "./Profile";
import MainAnimation from "../components/MainAnimation";
import showToast from "../components/Toast";
import feedbackService from "../services/feedbackService";
import TCButton from "../components/TCButton";
import { WhatsApp } from "@material-ui/icons";

const red = "#f5372a";

function Feedback({ history }) {
  const [feedback, setFeedback] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorTexto, setErrorTexto] = useState("");
  const classes = useStyles();

  function handleSubmit() {
    if (!feedback.texto) {
      return setErrorTexto(true);
    }

    setLoading(true);
    feedbackService
      .postFeedback(feedback)
      .then(() => {
        showToast.success("Feedback enviado com sucesso");
        setFeedback({});
      })
      .catch((e) => {
        showToast.error(e?.message);
      })
      .finally(() => setLoading(false));
  }

  let number = `5521976588989`;
  return (
    <MainAnimation>
      <Container maxWidth="md" style={{ paddingTop: 20, display: "flex" }}>
        <Paper className={classes.paper} style={{ opacity: 0.85 }}>
          <Typography variant="h6" style={{ marginBottom: 32 }}>
            Contato e Suporte.
          </Typography>
          <Typography style={{ marginRight: 12 }}>Ligue: {number[2]}{number[3]} {number.slice(4)}</Typography>
          <Typography style={{ margin: 14, marginLeft: 48 }}>ou</Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TCButton onClick={() => window.open(`https://wa.me/${number}`)} variant='outlined' color="outlined" >
              Entrar em contato por WhatsApp
              <div style={{ width: 12 }} />
              <WhatsApp /> </TCButton>
          </div>
        </Paper>
      </Container>
    </MainAnimation>
  );
}

export default Feedback;

function RadioButton({ label, name, feedback, setFeedback }) {
  function handleChangeTipo(e) {
    const copy = { ...feedback, tipo: e.target.checked && name };
    setFeedback(copy);
  }
  return (
    <div>
      <Radio
        color="primary"
        checked={feedback.tipo === name}
        onChange={handleChangeTipo}
      />
      <Typography variant="body">{label}</Typography>
    </div>
  );
}

function ErrorText({ children }) {
  return (
    <Typography variant="subtitle1" style={{ color: red }}>
      {children}
    </Typography>
  );
}
