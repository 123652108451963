import React, { useEffect, useState } from "react";
import { Container, Paper, Typography } from "@material-ui/core";
import MainAnimation from "@components/MainAnimation";
import TCLoading from "../TCLoading";
import { useSelector } from "react-redux";

import formatDate from "@utils/formatDate";
import { ImgCotacao } from "../Feed/CotacaoInfo";
function VencimentosPanel() {
  let loading = false;
  const [cotacao] = useSelector((states) => [states.cotacaoReducer]);
  console.log("cotacao", cotacao);
  return (
    <MainAnimation>
      <Container maxWidth="md" style={{ paddingTop: 20, display: "flex" }}>
        <Paper style={{ padding: 12 }}>
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: 600,
              }}
            >
              <TCLoading size={40} />
            </div>
          ) : (
            <div>
              <Typography variant="h4" style={{ marginBottom: 36 }}>
                Vencimentos
              </Typography>
              {(() => {
                const arabicaList = [];
                const robustaList = [];

                Object.keys(cotacao?.vencimentos)?.forEach((vencimentoId) => {
                  let vencimento = cotacao.vencimentos[vencimentoId];

                  // Check if isLondres and push to the respective list
                  if (vencimento.preSymbol === "RM") {
                    robustaList.push(vencimento);
                  } else {
                    arabicaList.push(vencimento);
                  }
                });

                return (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ flex: 1 }}>
                      <Typography variant="h5">Robusta</Typography>
                      {robustaList.map((vencimento, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 4,
                            padding: `2px 4px`,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: 4,
                              gap: 4,
                            }}
                          >
                            <ImgCotacao isLondres={true} />
                            <span>{vencimento["contractSymbol"]}</span>
                          </div>
                          <span style={{ fontWeight: "bold" }}>
                            {vencimento["lastPrice"]}
                          </span>
                          <span>{formatDate(vencimento["updatedAt"])}</span>
                        </div>
                      ))}
                    </div>

                    <div style={{ flex: 1, marginLeft: 12, minWidth: 300 }}>
                      <Typography variant="h5">Arabica</Typography>
                      {arabicaList.map((vencimento, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 4,
                            padding: `2px 4px`,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: 4,
                              gap: 4,
                            }}
                          >
                            <ImgCotacao isLondres={false} />
                            <span>{vencimento["contractSymbol"]}</span>
                          </div>
                          <span style={{ fontWeight: "bold" }}>
                            {vencimento["lastPrice"]}
                          </span>
                          <span>{formatDate(vencimento["updatedAt"])}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })()}
            </div>
          )}
        </Paper>
      </Container>
    </MainAnimation>
  );
}

export default VencimentosPanel;
