import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Paper, Box, makeStyles } from "@material-ui/core";

import authService from "../services/authService";
import EditProfile from "@components/Profile/EditProfile";
import { useParams } from "react-router";
import PhotoAndInfo from "../components/PhotoAndInfo";
import MainAnimation from "../components/MainAnimation";
import showToast from "@components/Toast";
import { setAuthInfo } from "@redux/authReducer";
import TCLoading from "../components/TCLoading";
export const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
    width: "100%",
  },
  center: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: 408,
  },
}));

function Profile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { userId } = useParams();
  const [user] = useSelector((states) => [states.authReducer.user]);

  function submitEditedUser(pEditedUser) {
    setLoading(true);

    return authService
      .editUser(pEditedUser)
      .then((rUser) => {
        showToast.success("Perfil Atualizado");
        dispatch(setAuthInfo(rUser));
      })
      .catch((e) => {
        showToast.error("Um erro ocorreu");
        console.log(e);
      })
      .finally(() => setLoading(false));
  }
  return (
    <MainAnimation>
      <Container maxWidth="md" style={{ paddingTop: 20, display: "flex" }}>
        <Paper className={classes.paper}>
          {loading ? (
            <Box className={classes.center}>
              <TCLoading size={40} />
            </Box>
          ) : !isEditing ? (
            <>
              <PhotoAndInfo
                imgSrc={user?.img}
                nome={user?.name}
                email={user?.email}
                telefone1={user?.telefone1}
                telefone2={user?.telefone2}
                isLoggedUser={userId === user?._id}
                isEditable={userId === user?._id}
                onClickToEdit={() => setIsEditing(true)}
              />
            </>
          ) : (
            <EditProfile
              onCloseEdit={() => setIsEditing(false)}
              user={user}
              submitEditedUser={submitEditedUser}
            />
          )}
        </Paper>
      </Container>
    </MainAnimation>
  );
}

export default Profile;
