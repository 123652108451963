import React, { useState } from "react";
import { IconButton, TextField, Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { phoneRegExpOld } from "@utils/phoneRegExp";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import adminService from "@services/adminService";
import showToast from "../Toast";
import TCButton from "../TCButton";

const schema = yup.object().shape({
  nomeFantasia: yup.string().required("nomeFantasia faltando"),
  telefone1: yup.string().matches(phoneRegExpOld, "Telefone invalido"),
  fakeUsuarioContato: yup.string().required("Insira o nome dono do telefone"),
});

export default function AddFakeCompany({ addCompanyToList, handleClose }) {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({ defaultValues: {}, resolver: yupResolver(schema) });
  function handleCreateFakeCompany(pCompany) {
    setLoading(true);
    adminService
      .createFakeCompanies(pCompany)
      .then((rCompany) => {
        addCompanyToList(rCompany);
        handleClose();
      })
      .catch((e) => showToast.error(e.message))
      .finally(() => setLoading(false));
  }

  return (
    <form
      onSubmit={handleSubmit(handleCreateFakeCompany)}
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <IconButton onClick={handleClose}>
        <ChevronLeft />
      </IconButton>
      <Typography variant="body1">Tipo de EmpresaAvatar</Typography>
      <TextField
        variant="outlined"
        size="small"
        error={errors["tipoEmpresa"]}
        helperText={errors["tipoEmpresa"]?.message}
        {...register("tipoEmpresa")}
      />

      <Typography variant="body1">Nome fantasia</Typography>
      <TextField
        variant="outlined"
        size="small"
        error={errors["nomeFantasia"]}
        helperText={errors["nomeFantasia"]?.message}
        {...register("nomeFantasia")}
      />

      <Typography variant="body1">Municipio</Typography>
      <TextField
        variant="outlined"
        size="small"
        error={errors["municipio"]}
        helperText={errors["municipio"]?.message}
        {...register("municipio")}
      />

      <Typography variant="body1">Estado</Typography>
      <TextField
        variant="outlined"
        size="small"
        error={errors["estado"]}
        helperText={errors["estado"]?.message}
        {...register("estado")}
      />
      <Typography variant="body1">Telefone</Typography>
      <TextField
        variant="outlined"
        size="small"
        error={errors["telefone1"]}
        helperText={errors["telefone1"]?.message}
        {...register("telefone1")}
      />
      <Typography variant="body1">Nome para contato</Typography>
      <TextField
        variant="outlined"
        size="small"
        error={errors["fakeUsuarioContato"]}
        helperText={errors["fakeUsuarioContato"]?.message}
        {...register("fakeUsuarioContato")}
      />

      <TCButton style={{ marginTop: 12 }} loading={loading}>
        Adicionar
      </TCButton>
    </form>
  );
}
